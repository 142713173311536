_ = require "underscore"
handlebars = require "handlebars/dist/handlebars"
allSettled = require "promise.allsettled"

js_yaml = require "js-yaml"
orb = require "orb"
clarity = require "orb/system/clarity_client"
clarus = require "orb/system/clarus_client"
credentials = require "../index.coffee"
helpers = require "./helpers"
publish_modal = require "./publish_modal.coffee"
publish_history = require "./publish_history.coffee"

requirements_table_src = require("!!raw-loader!./requirements_table.mustache").default
exam_type_modal_src = require("!!raw-loader!./exam_type_modal.mustache").default
editor_src = require("!!raw-loader!./credential_type_editor.mustache").default
publish_modal_src = require("!!raw-loader!./publish_modal.mustache").default
publish_history_table_partial = require("./publish_history_table.mustache")

allSettled.shim();  # Polyfill for browsers without Promise.allSettled

draft_preview_data = null
draft_credential_type_data = null
draft_editor = null
published_preview_data = null
published_credential_type_data = null
published_editor = null
credential_type = null
referenced_requirements = []

render = (options) ->
    handlebars.registerPartial "requirements_table", handlebars.compile requirements_table_src
    handlebars.registerPartial "exam_type_modal", handlebars.compile exam_type_modal_src
    handlebars.registerPartial "publish_modal", handlebars.compile publish_modal_src
    handlebars.registerPartial "publish_history_table", publish_history_table_partial
    editor = handlebars.compile editor_src
    el = $(options.render_target)
        .html editor

    # Hang jquery on the window so that embedded iframes can raise events
    if not window.$
        window.$ = $

    _setup_preview_toggle()
    credential_type = options.credential_type
    helpers.get_requirements_library()
    _setup_requirement_display_tooltips()
    _add_form_listeners()
    _setup_timeline_toggle()
    _wire_up_publishing_dialog()
    publish_modal.setup credential_type
    publish_history.setup credential_type
    _wire_up_exam_type_modal()
    _setup_requirement_editor()
    _setup_ace_height_adjustment()
    _render_initial_credential_forms().then () ->
        editors = []
        if draft_credential_type_data
            draft_editor = $($('#draft')[0].contentWindow.document).find('[name="configuration"]').prev('.ace_editor')[0].env.editor
            editors.push(draft_editor)
        if published_credential_type_data
            published_editor = $($('#published')[0].contentWindow.document).find('[name="configuration"]').prev('.ace_editor')[0].env.editor
            editors.push(published_editor)
        completers = helpers.get_ace_completers().then (completers) ->
            for editor in editors
                editor.completers.push.apply(editor.completers, completers)
            _update_ace_errors(editors)
            $('#published-tab .btn-group button:nth-child(1)').click()
            setTimeout (->
                    $('#draft-tab .btn-group button:nth-child(1)').click()
                    ), 1500

_setup_preview_toggle = () ->
    user_id = NextGen.metadata.user.uuid || 'no_uuid_found'
    storage_id = "credential_type_editor_preview_minimized_#{user_id}"

    # Check initial state from localStorage
    if localStorage.getItem(storage_id) == 'true'
        $('body').addClass('preview-minimized')
        $('.toggle-preview i').toggleClass('fa-compress fa-expand')

    $('.toggle-preview').click ->
        $('body').toggleClass('preview-minimized')
        $(@).find('i').toggleClass('fa-compress fa-expand')
        # Save choice
        localStorage.setItem(storage_id, $('body').hasClass('preview-minimized'))


_setup_ace_height_adjustment = () ->
    $('[data-bs-target="#published-tab"]').on 'shown.bs.tab', () ->
        ace_editors = $($('#published')[0].contentWindow.document).find('.ace_editor')
        ace_editors.each (_, el) ->
            editor_container = $(el)
            textarea_height = editor_container.next('textarea').height()

            if not textarea_height or textarea_height == editor_container.height()
                return

            editor_container.css('height', "#{textarea_height}px")
            editor = editor_container[0].env.editor
            editor.resize()
            update_attempts = 0
            # Sometimes an immediate resize doesn't work.
            # It's unlikely to need 2 seconds, so it seems like a safe buffer
            interval_id = setInterval (->
                editor.resize()
                update_attempts += 1
                if update_attempts > 10
                    clearInterval interval_id
            ), 200


_wire_up_publishing_dialog = () ->
    $('#publish-draft-v1').on "click", () ->
        $('#publish-modal-v1').modal 'show'
        _get_perspectives()

    $('#promote-toggle').bootstrapToggle
        onlabel: 'Yes'
        offlabel: 'No'
        offstyle: 'danger'
        onstyle: 'success'
        style: 'toggle-btn-md align-top'
        width: '5em'
        height: '2.5em'

    $('#publish-model').find('select').chosen()

    $('#publish-form').on 'change', 'select', () ->
        none_selected = _.all $(this).parents('form').find('select'), (selectfield) ->
            selectfield.value == 'None'
        $('#publish-reason').prop 'disabled', none_selected


    _make_job_promise = (element, publish_type, credential_type_uuid, publish_version, reason) ->
        publish_url = "/credentials/credential_types/#{credential_type_uuid}/publish"
        $.ajax
            method: 'POST'
            data:
                perspective_id: element[0].value
                publish_type: publish_type
                reason: reason
                publish_version: publish_version
            url: publish_url
            success: () ->
                element.parent('div').next('div').find('.fa-check').animate({height: 'show'})
            fail: () ->
                element.parent('div').next('div').find('.fa-times').animate({height: 'show'})


    $('#publish-form').on "submit", (e) ->
        e.preventDefault()
        $('#publish-submit').addClass('disabled').attr('disabled', true)
        $(this).find('select, textarea').attr 'disabled', true
        $('#promote-toggle').bootstrapToggle 'disable'

        clarity.get_credential_type(credential_type, true).then (draft_data) ->
            clarity.get_credential_type(credential_type, false, {expand: 'publish_history'}).then (credential_type_info) ->
                existing_publish_versions = credential_type_info.publish_history.length
                name = 'Version #{count} (created by V1 publishing tool)'.format
                    count: existing_publish_versions
                reason = $('textarea[name="reason"]').val()

                clarity.publish_credential_type(credential_type, draft_data, name, reason).then (publish_version) ->
                    promises = []
                    if $('#promote-toggle')[0].checked
                        promises.push(clarity.update_credential_type_by_publish_version(credential_type, publish_version['uuid'])
                            .fail () ->
                                $('#promote-toggle').closest('div.col-6').next('div').find('.fa-times').animate({height: 'show'})
                            .done () ->
                                $('#promote-toggle').closest('div.col-6').next('div').find('.fa-check').animate({height: 'show'})
                                $('#publish-submit').hide().next('button').hide().next('span').removeClass("d-none")
                        )

                    if not isNaN($('#existing-credentials-select')[0].value)
                        promises.push _make_job_promise $('#existing-credentials-select'), 'update_credentials', draft_data.uuid, publish_version['uuid'], reason

                    if not isNaN($('#existing-applications-select')[0].value)
                        promises.push _make_job_promise $('#existing-applications-select'), 'update_applications', draft_data.uuid, publish_version['uuid'], reason

                    if not isNaN($('#new-applications-select')[0].value)
                        promises.push _make_job_promise $('#new-applications-select'), 'create_applications', draft_data.uuid, publish_version['uuid'], reason

                    Promise.allSettled(promises).then () ->
                        $('#publish-submit').hide()


    $('#refresh-button').on 'click', () ->
        window.location = window.location.href

    $('#publish-modal-v1').on 'hidden.bs.modal', () ->
        $(this).find('textarea').val('').prop 'disabled', true
        $('#promote-toggle').bootstrapToggle 'enable'
        $('#promote-toggle').bootstrapToggle 'on'
        $(this).find('.fa').hide()
        $(this).find('select').attr 'disabled', false
        $('#publish-submit').removeClass('disabled').attr('disabled', false).show()


_update_ace_errors = (editors) ->
    invalid_requirements = helpers.get_invalid_requirements referenced_requirements
    for editor in editors
        for marker in Object.values editor.session.getMarkers()
            if marker.clazz == 'error-highlight'
                editor.session.removeMarker marker.id
        Range = $(editor.container)[0].ownerDocument.defaultView.ace.Range
        for invalid_requirement in invalid_requirements
            options =
                needle: invalid_requirement
                wholeWord: true
            editor.$search.set options
            ranges = editor.$search.findAll editor.session
            _.each(ranges, (range) ->
                new_range = new Range()
                new_range.start = editor.session.doc.createAnchor range.start
                new_range.end = editor.session.doc.createAnchor range.end
                new_range.id = editor.session.addMarker new_range, 'error-highlight', 'text')


_render_initial_credential_forms = () ->
    promise = $.Deferred()

    published_iframe = $('#published')[0]
    draft_iframe = $('#draft')[0]

    Promise.allSettled([
        _get_credential_form(credential_type, false, published_iframe)
        _get_credential_form(credential_type, true, draft_iframe)
    ]).then (results) ->
        if (_.every results, (result) -> return result.status=='rejected')
            $('#forbidden').removeClass 'd-none'
            # $('#mask').modal 'hide'
            # return promise
        else if (_.every results, (result) -> return result.status=='fulfilled')
            _render_form $('#draft')[0], results[1].value, credential_type, true
            _render_form $('#published')[0], results[0].value, credential_type, false
            # Give ace editor some buffer to adjust field heights.
            # If the text fields aren't visible when the editor is applied, then
            # it defaults to a single line to a single line
            return new Promise (resolve, reject) ->
                setTimeout (->
                    $('#bl-spinner').fadeOut 1000, () ->
                        $(this).remove()
                    $('li.draft a').click()
                    resolve()
                    ), 2500
        else if results[0].status == 'fulfilled'
            $('li.draft').addClass 'disabled'
            $('li.draft a').removeAttr('data-toggle')
            $('li.published a').click()
            _render_form($('#published')[0], results[0].value, credential_type, false)
        else if results[1].status == 'fulfilled'
            $('li.published').removeClass('active').addClass('disabled')
            $('li.published a').removeAttr 'data-toggle'
            $('li.draft a').click()
            _render_form($('#draft')[0], results[1].value, credential_type, true)

        $('#bl-spinner').fadeOut 1000, () ->
            $(this).remove()
        promise.resolve()


_add_form_listeners = () ->
    # These events are triggered by the dynamic forms in the iframes to let us
    # know when the data is stable and ready to be previewed
    $('body').on 'save-success form-loaded', (evt, source) ->
        if source.frameElement.id == 'published'
            _render_configuration_preview credential_type, false
        else if source.frameElement.id == 'draft'
            _render_configuration_preview credential_type, true
            $('.publish-draft.btn').removeClass('disabled').attr('disabled', false).attr('title', null)


_format_scorecard_data = (status, draft) ->
    scorecard = null

    if status == 'browse'
        return scorecard

    preview_data = if draft then draft_preview_data else published_preview_data
    preview_data = preview_data[status]
    scorecard = {}
    scorecard.credential_type_id = published_credential_type_data.uuid
    scorecard.requirements = preview_data.scorecard

    if status == 'initial'
        scorecard.type = "application"

        scorecard.application = {
            status: 'new'
            uuid: 'doesntmatter'
        }
    else
        scorecard.type = "renewal"
        scorecard.renewal = {
            renewal_start: preview_data.issued_on
            custom_data: {display_expiration: preview_data.expires_on}
        }
        scorecard.credential = {
            number: preview_data.number
            issued_on: preview_data.issued_on
            status: status,
            from_date: preview_data.date
        }

    scorecard


_render_card_preview = (credential_type_data, preview_data, card_div, status, draft) ->
    promise = $.Deferred()

    credentials.retrieve_card_templates().then (card, requirement) ->
        card_template = card
        requirement_template = requirement
        scorecard_data = _format_scorecard_data(status, draft)
        helpers.get_requirements_library().then (data) ->
            use_selected = true
            ct_data = Object.assign({}, credential_type_data)
            ct_data.__preview = true

            if status == 'browse'
                use_selected = false
                ct_data.requirements = preview_data['initial']['configured_requirements']

            types = credentials.process_credential_type_data(
                [ct_data], data,
                [scorecard_data], [], use_selected)

            options =
                partials:
                    "credential_display_requirement": requirement_template

            $(card_div).html(card_template(types[0], options))
            $(card_div).find('.credential-tooltip').tooltip
                html: true
                placement: 'left'

            promise.resolve()


_render_requirements_table = (requirements_table, requirements) ->
    requirements_table.innerHTML = ""
    if !requirements.length
        requirements_table.innerHTML = "<tr><td colspan=3 class='text-center'>No Records</td></tr>"
    else
        requirements.forEach (requirement) ->
            new_row = requirements_table.insertRow -1
            helpers.populate_requirement_row new_row, requirement

    $('.card-preview, .requirements-table').fadeIn 'slow'


_prep_card_preview = (status, draft) ->
    if draft
        credential_type_data = draft_credential_type_data
        preview_data = draft_preview_data
        requirements_table = $('#draft-tab').find('.requirements-table tbody')[0]
        requirements = _.keys(preview_data[status]['requirements'])
        card_div = $('#draft-tab').find('.card-preview')[0]
    else
        credential_type_data = published_credential_type_data
        preview_data = published_preview_data
        requirements_table = $('#published-tab').find('.requirements-table tbody')[0]
        requirements = _.keys(preview_data[status]['requirements'])
        card_div = $('#published-tab').find('.card-preview')[0]

    _render_card_preview credential_type_data, preview_data, card_div, status, draft
        .then () ->
            _render_requirements_table requirements_table, requirements

_setup_timeline_toggle = () ->
    $('body').on 'click', '.btn-group button', (evt) ->
        if $(this).hasClass 'btn-primary'
            return

        tab_pane = $(this).closest('.tab-pane')
        $(tab_pane).find('.card-preview, .requirements-table').fadeOut 0
        $(this).parent().children().removeClass 'btn-primary'
        $(this).parent().children().addClass 'btn-outline-secondary'
        $(this).parent().find('div').animate({height:'hide', width:'hide'})
        $(this).addClass 'btn-primary'
        $(this).removeClass 'btn-outline-secondary'
        $(this).find('div').animate({height:'show', width:'show'})
        status = this.dataset.key
        draft = if $(this).parents('#draft-tab').length then true else false

        $.when(_prep_card_preview(status, draft)).done () ->
            $(tab_pane).find('.card-preview, .requirements-table').fadeIn 'slow'

    return

_setup_requirement_display_tooltips = () ->
    # Some requirements library items will render as links
    # This prevents the link from using backbone navigation and instead triggers
    # a tooltip with the url
    $('body').on 'click', 'a', (e) ->
        e.preventDefault()

    $('.requirements-table').on 'shown.bs.tooltip', (evt) ->
        setTimeout(
            () -> $(evt.target).tooltip('hide')
            4000
        )

    $('body').tooltip
        selector: 'a[data-rel=tooltip]',
        trigger: 'click',
        html: true

    bootstrap.Tooltip.Default.allowList['time'] = ['datetime'];


_render_configuration_preview = (credential_type, draft) ->
    clarity.get_credential_type(credential_type, draft).then (data) ->
        data.renewal_description = data.custom_data and data.custom_data.renewal_description

        clarity.get_credential_type_preview(data).then (preview_data) ->
            sorted_statuses = _.sortBy(_.keys(preview_data), (status) ->
                return preview_data[status].date || '01-01-01')

            preview_data.browse = {
                'requirements': preview_data['initial']['requirements']
            }
            sorted_statuses.splice(0, 0, 'browse')

            if draft
                tab = $('#draft-tab')
                draft_credential_type_data = data
                draft_preview_data = preview_data
            else
                tab = $('#published-tab')
                published_credential_type_data = data
                published_preview_data = preview_data

            tab.find('.card-preview, .requirements-table').fadeOut 0
            button_group = tab.find('.timeline-buttons')
            requirements_table = tab.find('.requirements-table tbody')[0]
            card_preview = tab.find('.card-preview')[0]

            button_group[0].innerHTML = ''
            requirements_table.innerHTML = ''
            card_preview.innerHTML = ''

            configuration = js_yaml.load(data.configuration)
            if configuration.implies_intent
                referenced_requirements.push.apply(referenced_requirements, configuration.implies_intent)

            for status in sorted_statuses
                button_group.append(helpers.get_timeline_button(preview_data, status))
                referenced_requirements.push.apply(referenced_requirements,_.keys(preview_data[status]['requirements']))
            referenced_requirements = _.uniq referenced_requirements
            editor = if draft then draft_editor else published_editor
            if editor
                _update_ace_errors([editor])


_get_credential_form = (id, draft, iframe) ->
    url = "/credentials/credential_types/#{id}"
    if draft
        url = url + "/draft"
    $.ajax(
        method: 'GET'
        url: url
    )


_add_configurator = (iframe) ->
    # Add editor button to configuration field, load the configurator iframe
    # and enable communication between the yaml field and configurator

    enable_configurator = () ->
        $(iframe).contents().find('#cc-error').addClass('d-none');
        $('<iframe id="cc" frameBorder="0" style="border:1px solid lightgrey;border-radius:25px" class="d-none" src="/static/build/cc/index.html" height="100%" width="100%"></iframe>').appendTo(
            config_field.closest('.bl-form-group'));
        cc = $(iframe).contents().find('#cc');
        cc[0].onload = () =>
            cc.toggleClass('d-none').closest('.bl-form-group').children('div').toggleClass('d-none');
            $(iframe).contents().find('fieldset:not(:has([name=configuration]))').addClass('d-none')
            $(iframe).contents().find('.bl-form-group:not(:has([name=configuration]))').addClass('d-none')
            cc.contents().find('#back-to-yaml').on('click', () ->
               $(iframe).contents().find('#cc').toggleClass('d-none').closest('.bl-form-group').children('div').toggleClass('d-none');
               $(iframe).contents().find('#cc').remove();
               $(iframe).contents().find('fieldset:not(:has([name=configuration]))').removeClass('d-none')
               $(iframe).contents().find('.bl-form-group:not(:has([name=configuration]))').removeClass('d-none')
            );
            setTimeout (->
                    cc[0].contentWindow.postMessage({configuration: config_field.val()}, '*')
                    ), 150

    config_field = $(iframe).contents().find('[name=configuration]')

    label = config_field.closest('.bl-form-group').find('label')
    label.replaceWith(
        $('<div class="col-5">' +
            label[0].outerHTML +
            '<br><a style="margin:10px 0px 10px 0px;" id="tgl" class="btn btn-sm btn-primary" href="#">' +
            '<i class="fa-duotone fa-solid fa-window-restore"></i>&nbsp; Configurator</a>' +
            '<div id="cc-error" class="alert alert-danger d-none"></div></div>')
    );
    setInterval( () ->
        $(iframe).contents().find('#cc').css('height', $(iframe).contents().find('#cc').contents().find('body').height()+25)
        250);

    #Validate YAML configuration before loading editor
    $(iframe).contents().find('#tgl').on('click', () ->
        valid_for_configurator = true
        config_field.val().split('#').forEach (piece, index) ->
            if index != 0 and !piece.startsWith('/requirements/')
                valid_for_configurator = false
        if !valid_for_configurator
            error = """Configurator does not support advanced features such
                as comments or $ref references to non-requirements blocks.
                Please remove these and retry."""
            $(iframe).contents().find('#cc-error').html(error);
            $(iframe).contents().find('#cc-error').removeClass('d-none');
            return
        else if config_field.val().trim() == ""
            enable_configurator();
        else
            clarity.validate_configuration(config_field.val()).then (data) ->
                enable_configurator();
            .fail (error) ->
                if error.responseJSON
                    error = """
                        <span title='#{error.responseText}''>
                        Configurator cannot be initialized because of validation errors.
                        Hover to see errors</span>"""
                else
                    error = """
                        <span>Unknown error when validating configuration<br>
                            Status Code: #{error.status}
                        </span>
                    """
                $(iframe).contents().find('#cc-error').html(error);
                $(iframe).contents().find('#cc-error').removeClass('d-none');
    );
    $(iframe)[0].contentWindow.onmessage = (e) ->
        if e.data.configuration
            config_field.prev('div')[0].env.editor.setValue(e.data.configuration, -1);


_render_form = (iframe, data, credential_type, draft) ->
    iframe.contentWindow.document.open()
    iframe.contentWindow.document.write data
    iframe.contentWindow.document.close()
    url = "/credentials/credential_types/#{credential_type}"
    if draft
        url = url + "/draft"
        $('#save-draft').on 'click', () ->
            $(iframe).contents().find(':submit').click()
    $(iframe).contents().find('form').attr 'action', url
    style = document.createElement('style')
    style.innerHTML = '.error-highlight { border-bottom: 2px red dashed; position: absolute;}'
    $(iframe).contents().find('body').append(style)
    $(iframe).contents().find('[name="save_button"]').addClass 'd-none'
    $(iframe).contents().find('body').on "change cut paste keyup", "input, textarea, select", () ->
        if not draft
            return

        $('.publish-draft.btn').addClass('disabled').attr('disabled', true).attr('title', 'Please save before publishing')
        $('.add-exam-type-btn').attr('disabled', true).attr('title', 'Please save before creating new exam types.')
        $('.add-requirement-btn, .update-requirement-btn')
            .attr('disabled', true)
            .attr('title', 'Please save before add/update requirement.')
    if draft and 'BrightLink' in NextGen.metadata.user.groups and NextGen.metadata.environment != 'production'
        _add_configurator iframe

_get_perspectives = () ->
    $.ajax(
        method: 'GET'
        url:'/admin/webtop/perspective/search')
    .then (data) ->
        publishing_perspectives = _.sortBy (_.filter data.perspectives, (perspective) ->
            perspective._custom_data && perspective._custom_data.credential_publishing),
            (perspective) ->
                perspective.name

        for item in [['create_applications', '#new-applications-select'],
                     ['update_applications', '#existing-applications-select'],
                     ['update_credentials', '#existing-credentials-select']]
            $(item[1])[0].innerHTML = _.reduce (_.filter publishing_perspectives, (perspective) ->
                _.contains perspective._custom_data.credential_publishing, item[0]),
                    (value, perspective) ->
                        value + "<option value=#{perspective.id}>#{perspective.name}</option>"
                    , '<option>None</option>'


_wire_up_exam_type_modal = () ->
    $('body').on 'click', '.add-exam-type-btn', (evt) ->
        $("input#exam-type-slug, input#exam-type-abbr").val($(this).data('slug'))
        $('#add-exam-type-modal').modal 'show'

    $('#add-exam-type-form').on 'submit', (e) ->
        e.preventDefault()

        $('#add-exam-type-form .alert').remove()
        $('#add-exam-type-submit').addClass('disabled').prop('disabled')
        slug = document.getElementById('exam-type-slug').value
        name = document.getElementById('exam-type-name').value

        clarus.create_exam_type({
            abbr: document.getElementById('exam-type-abbr').value
            abbr_token: "exam_type:#{slug}:abbr"
            name: name
            name_token: "exam_type:#{slug}:name"
            slug: slug
            third_party_only: true
        }).done(() ->
              $('#add-exam-type-modal').modal 'hide'
              draft = $('.draft').find('.nav-link').hasClass('active')
              status = $('.timeline-buttons:visible').children().filter('.btn-primary')[0].dataset.key
              editor = if draft then draft_editor else published_editor

              refresh_requirements = helpers.get_requirements_library(refresh=true)
              refresh_exam_types = helpers.get_exam_types(refresh=true)
              set_lang = clarus.set_lang("RL:exam_result:#{slug}", name)
              $.when refresh_exam_types, refresh_requirements, set_lang
              .done () ->
                  _prep_card_preview status, draft
                  _update_ace_errors([editor])
        ).fail((jqXHR, textStatus, errorThrown) ->
            $('#add-exam-type-submit').removeClass('disabled').removeAttr('disabled')

            error_msg = jqXHR.responseJSON and jqXHR.responseJSON.errors or jqXHR.responseJSON.message
            error_msg = if $.type(error_msg) is 'object' then JSON.stringify(error_msg) else error_msg
            msg = "There is an error trying to create this exam type. Errors: #{error_msg}"
            $('#add-exam-type-form .modal-body').prepend(
                "<div class=\"alert alert-danger\">#{msg}</div>"
            )
        )

    $('#add-exam-type-modal').on 'hidden.bs.modal', () ->
        $('#add-exam-type-submit').removeClass('disabled').removeAttr('disabled')
        $("#add-exam-type-form").trigger('reset')
        $('#add-exam-type-form .alert').remove()


_setup_requirement_editor = () ->
    $('body').on 'requirement-form-submit', (e) ->
        if e.uuid
            requirement_api = clarity.update_credential_requirement e.uuid, e.name, e.hyperlink
            lang_api = null
        else
            requirement_api = clarity.create_credential_requirement e.slug, e.name, e.hyperlink
            lang_api = clarus.set_lang("RL:#{e.slug}", e.name)

        $.when(requirement_api, lang_api)
        .done () ->
            helpers.get_requirements_library(refresh=true)
            .then ->
                draft = $('.draft').find('.nav-link').hasClass('active')
                tab_selector = if draft then '#draft-tab' else '#published-tab'
                status = $("#{tab_selector} .timeline-buttons").children().filter('.btn-primary')[0].dataset.key
                _prep_card_preview status, draft


module.exports =
    "render": render
