var Handlebars = require("../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <tr>\n                <td><span class=\"fw-semibold\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"credential_type_abbr") : depth0), depth0))
    + "</span></td>\n                <td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"number") : depth0), depth0))
    + "</td>\n                <td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"issued_on") : depth0), depth0))
    + "</td>\n                <td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"display_expiration") : depth0), depth0))
    + "</td>\n                <td>\n                    <span class=\"badge rounded-pill bg-secondary-subtle text-secondary border border-secondary\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"display_status") : depth0), depth0))
    + "</span>\n                </td>\n                <td>\n"
    + ((stack1 = alias3.call(depth0,alias1((depth0 != null ? lookupProperty(depth0,"valid") : depth0), depth0),{"name":"valid","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":20},"end":{"line":32,"column":30}}})) != null ? stack1 : "")
    + ((stack1 = alias3.call(depth0,alias1((depth0 != null ? lookupProperty(depth0,"valid") : depth0), depth0),{"name":"valid","hash":{},"fn":container.noop,"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":33,"column":20},"end":{"line":35,"column":30}}})) != null ? stack1 : "")
    + "                </td>\n                <td>\n"
    + ((stack1 = alias3.call(depth0,alias1((depth0 != null ? lookupProperty(depth0,"valid") : depth0), depth0),{"name":"valid","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":20},"end":{"line":47,"column":30}}})) != null ? stack1 : "")
    + "                </td>\n            </tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "                    <i class=\"fa-solid fa-circle-check fa-lg text-success\"></i>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "                    <i class=\"fa-solid fa-circle-xmark fa-lg text-danger\"></i>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <button\n                            type=\"button\"\n                            class=\"btn btn-primary btn-sm generate-certificate\"\n                            data-bs-toggle=\"tooltip\"\n                            data-bs-title=\""
    + alias1(__default(require("../handlebars_helpers/lang.coffee")).call(depth0 != null ? depth0 : (container.nullContext || {}),"L000239","Download certificate",{"name":"lang","hash":{},"data":data,"loc":{"start":{"line":43,"column":43},"end":{"line":43,"column":84}}}))
    + "\"\n                            data-uuid=\""
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"uuid") : depth0), depth0))
    + "\">\n                            <i class=\"fa-solid fa-download\"></i>\n                        </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<style>\n.table-sm>:not(caption)>*>* {\n    padding: .25rem .25rem !important;\n}\n</style>\n<div class=\"table-responsive\">\n    <table id=\"credentials\" class=\"table table-hover align-middle small table-sm\">\n        <thead class=\"table-light\">\n            <tr class=\"text-nowrap\">\n                <th>"
    + alias2(__default(require("../handlebars_helpers/lang.coffee")).call(alias1,"L001196","Credential",{"name":"lang","hash":{},"data":data,"loc":{"start":{"line":10,"column":20},"end":{"line":10,"column":51}}}))
    + "</th>\n                <th>"
    + alias2(__default(require("../handlebars_helpers/lang.coffee")).call(alias1,"L001197","Credential #",{"name":"lang","hash":{},"data":data,"loc":{"start":{"line":11,"column":20},"end":{"line":11,"column":53}}}))
    + "</th>\n                <th>"
    + alias2(__default(require("../handlebars_helpers/lang.coffee")).call(alias1,"L001198","Effective Date",{"name":"lang","hash":{},"data":data,"loc":{"start":{"line":12,"column":20},"end":{"line":12,"column":55}}}))
    + "</th>\n                <th>"
    + alias2(__default(require("../handlebars_helpers/lang.coffee")).call(alias1,"L001199","Expiration Date",{"name":"lang","hash":{},"data":data,"loc":{"start":{"line":13,"column":20},"end":{"line":13,"column":56}}}))
    + "</th>\n                <th>"
    + alias2(__default(require("../handlebars_helpers/lang.coffee")).call(alias1,"L001200","Status",{"name":"lang","hash":{},"data":data,"loc":{"start":{"line":14,"column":20},"end":{"line":14,"column":47}}}))
    + "</th>\n                <th>"
    + alias2(__default(require("../handlebars_helpers/lang.coffee")).call(alias1,"L001230","Valid",{"name":"lang","hash":{},"data":data,"loc":{"start":{"line":15,"column":20},"end":{"line":15,"column":46}}}))
    + "</th>\n                <th>"
    + alias2(__default(require("../handlebars_helpers/lang.coffee")).call(alias1,"L001201","Actions",{"name":"lang","hash":{},"data":data,"loc":{"start":{"line":16,"column":20},"end":{"line":16,"column":48}}}))
    + "</th>\n            </tr>\n        </thead>\n        <tbody>\n"
    + ((stack1 = container.hooks.blockHelperMissing.call(depth0,container.lambda((depth0 != null ? lookupProperty(depth0,"credentials") : depth0), depth0),{"name":"credentials","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":12},"end":{"line":50,"column":28}}})) != null ? stack1 : "")
    + "        </tbody>\n    </table>\n</div>\n";
},"useData":true});