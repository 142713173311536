_token = null

_parseJwt = (token) ->
    base64Url = token.split('.')[1];
    base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    jsonPayload = decodeURIComponent(atob(base64).split('').map( (c) ->
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      ).join(''));

    return JSON.parse(jsonPayload);


get_token = () ->
    # Get and store cognito token for use with data api service.
    # Remove it before it expires to force fetching a new one.
    promise = $.Deferred()

    if _token
        promise.resolve(_token)
    else
        $.get("/api/v2/auth/token").then (data) ->
            exp = _parseJwt(data.access_token).exp
            now = parseInt((new Date()).getTime()/1000)
            if (exp - now) > 10
                setTimeout(
                    () ->
                        _token = null
                    , (exp - now - 5) * 1000
                 )
                _token = data
            promise.resolve(_token)

    promise


request = (access_token, url, parameters) ->
    $.ajax(
        type: 'POST'
        url: url
        crossDomain: true
        data: parameters || {}
        headers: {"Authorization": "Bearer " + access_token})


module.exports =
    get_token: get_token
    request: request