###
Dynamic Page CSV API Caller

This module allows converting values in a user selected csv file to be
translated and submitted as api calls into the Clarus system.

The module initialization can take up to four parameters:
1. columns(required): An array of columns each containing an array in the format:
   ["<Display Name>", "<reference>"]
   Example:
        var columns = [['Country Code', 'country_code'],
                       ['Region Code', 'region']];
2. instructions(required), which is a javascript object containing the
   following values:
    a. destination - The url to make the call against.  If a value from the
        csv needs to be used in the url, then wrap it's reference in braces.
        This can also be a reference to a javascript function if special
        handling is required.
    b. method - The request method that should be used to make the call.
        Ignored in the case of the destination being a function.
    c. data - This should be a javascript template of the data that will
        will be sent in the request body or function.  Column references
        may also be wrapped in braces in this template.
    d. name - (optional) This value will be displayed in the error
        reporting for rows with failed imports.
    e. success - (optional) This should be another instructions object that will
       be executed upon success of this call
    f. failure - (optional) This should be another instructions object that will
       be executed upon failure of this call
    g. extend_data - (optional) Allows adding data returned from a successful
       call to be made available as a reference to calls further down the chain.
       This mapping is similar to the column mapping above, but instead of
       display name, the first element should be a dotted path to the field
       in the returned data from the call or function.
        For example, the mapping [['uuid', 'candidate_uuid'],
                                  ['custom_data.special_number', 'sn']]
        will provide the values in the uuid and custom_data->special_number
        fields of this successful call to those further down the chain as
        candidate_uuid and sn
    Example:
        var instructions = {
            destination: '/api/v2/countries/code={country_code}',
            method: 'PUT',
            data: `
                {
                    "custom_data": {
                        "region": "{region}"
                    }
                }
            `,
            name: 'Updating Region'
        };
3. concurrent_requests(optional): integer value that determines the maximum
   number of concurrent requests to process
4. parse_options(optional): object of papaparse configuration parameters to
   overwrite the defaults.  Mainly provided as an option in the event a client
   requires a strange delimiter or other similar situation.


In addition to the values in the script, the following dom elements are
referenced and need to be included in the page:
    1.  #total-count - inner html of this element is set to the total number of
        rows in the selected csv
    2.  #processed-count - inner html of this element is updated with total
        number of rows processed
    3.  #success-count - inner html of this element is updated with number of
        successful imports
    4.  #error-count - inner html of this element is updated with number of
        errors encountered during import
    5.  #progress - progress bar.  The width of this element will be adjusted
        based on completion percentage
    6.  #completion-percentage - inner html of this element is updated with
        import progress
    7.  #file - basic file input field.
    8.  #error-container - should wrap the #errors element.  This will be shown
        when an error is encountered and cleared on successful parse or file
        removal
    9.  #errors - displays errors returned from csv parser
    10. #import-data-button - starts the import after a csv was successfully
        parsed
    11. #preview-table - standard table with empty thead and tbody.  Headers
        will be generated on page load and body will be filled when valid
        csv is selected.
    12. #import-controls - div containing controls that should be hidden until
        a successful csv selection.  The progress bar and start-import-button
        likely should live inside of this
###

_ = require "underscore"
asyncPool = require "tiny-async-pool"
require "bootstrap-fileinput"
require "bootstrap-fileinput/css/fileinput.css"
Cookies = require "js-cookie"
Papa = require "papaparse"

parsed_data = [];
total_errors = 0;
total_successful = 0;
total_processed = 0;


# Helper to convert normal functions to asynchronous
asyncify = (f) ->
  return () ->
    await return f.apply undefined, arguments;


# Import Methods
# These are used while the import is processing

update_progress = () ->
    selected_items = get_selected_row_count()
    $('#import-data-button').attr 'disabled', !selected_items

    $('#total-count')[0].innerHTML = selected_items
    $('#processed-count')[0].innerHTML = total_processed
    $('#success-count')[0].innerHTML = total_successful
    $('#error-count')[0].innerHTML = total_errors
    percent_complete = (parseInt(
        (total_processed/selected_items) *100) || 0)  + '%'
    $('#progress')[0].style.width = percent_complete
    $('#completion-percentage')[0].innerHTML = percent_complete
    $('#progress').toggleClass 'bg-danger', total_errors > 0 and total_errors == total_processed
    $('#progress').toggleClass 'bg-warning', total_errors > 0 and total_errors != total_processed
    if total_errors or total_processed == 0
        $('#progress').removeClass 'bg-success'


row_success = (initial_request, element) ->
   # Mark the row as successful and update progress
    if not initial_request
        return

    element.addClass 'table-success'
    setTimeout (->
        element.hide()
    ), 300

    total_processed++
    total_successful++
    update_progress()

row_error = (initial_request, element, error_text) ->
    # Mark the row as errored and update progress
    if not initial_request
        return

    element.addClass 'table-danger'
    element.attr 'title', error_text

    total_processed++
    total_errors++
    update_progress()


_get_headers = (destination) ->
    # Add additional headers needed for the request to succeed

    headers = {}
    if destination.toLowerCase().includes(
            BL.Config.get('system.api.clarity_api_host').toLowerCase())
        client_id = BL.Configuration.client_id
        token = Cookies.get("brighttrac_#{client_id}") or Cookies.get("tg_visit")
        headers['Authorization'] = "Bearer #{token}"
    return headers


make_request = (index, instructions, values, initial_request) ->
    ### Executes an ajax request or function using the configuration provided

    Parameters:
    index: The row number of the csv that is being processed.
    instructions: Javascript object controlling this unit of work (see module
        docstring for more details).
    values: The set of values that was used to generate this request.
    initial_request: Boolean representing whether this is the first request in
        a chain of requests(True) or a subsequent request.

    Returns a promise that will always resolve, otherwise the queue would stop
    processing on errors.
    ###

    promise = $.Deferred()

    element = $('#preview-row-{0}'.format index)

    if typeof instructions.destination == 'function'
        if instructions.destination.constructor.name == 'Function'
            instructions.destination = asyncify(instructions.destination)
        fn = instructions.destination
        params = instructions.data
    else
        fn = $.ajax
        params = {
            contentType: 'application/json'
            method: instructions.method
            headers: _get_headers instructions.destination
            url: instructions.destination
            data: JSON.stringify(instructions.data)
        }

    fn(params).then( (result) ->
            if instructions.success
                if instructions.extend_data
                    instructions.extend_data.forEach (mapping) ->
                        [source, reference] = mapping
                        values[reference] = _.get result, source

                chained_request(index, instructions.success, values).then (result) ->
                    if result
                        row_error initial_request, element, result
                    else
                        row_success initial_request, element
                    promise.resolve(result)
            else
                row_success initial_request, element
                promise.resolve()
        , (result) ->
            if instructions.failure
                chained_request(index, instructions.failure, values).then (result) ->
                    if result
                        row_error initial_request, element, result
                    else
                        row_success initial_request, element
                    promise.resolve(result)
            else
                error_text = if instructions.name then "Step #{instructions.name}: " else 'Error: '
                if result.status == 404
                    error_text = error_text + 'Not found (404)'
                else if result.responseJSON
                    if result.responseJSON.message
                        error_text = error_text + result.responseJSON.message
                    else if typeof result.responseJSON.errors == 'object'
                        errors = Object.entries(result.responseJSON.errors)
                        errors = errors.map( (error) ->
                            return "#{error[0]}: #{error[1].join(',')}")
                        errors = errors.join(',')
                        error_text = error_text + errors
                else if typeof result == 'string'
                    error_text = error_text + result
                else
                    error_text = error_text + 'Unknown'

                row_error initial_request, element, error_text
                promise.resolve(error_text)
        )
    promise


chained_request = (index, instructions, values) ->
    mi = Object.assign({}, instructions)  # copy instructions to prevent race conditions
    if typeof mi.destination == 'string'  # Ensure destination is not a function
        mi.destination = mi.destination.format values
    mi.data = JSON.parse(mi.data.format values) if mi.data or ''

    return make_request index, mi, values


prepare_request = (row, index, columns, instructions) ->
    ### Convert csv row into ready to execute request

    Returns a function that can be called to execute the instructions for this row

    ###

    return () ->
        mi = Object.assign({}, instructions)  # copy instructions to prevent race conditions
        values = {}
        columns.forEach (column, index) ->
            values[column[1]] = row[index]
            return

        if typeof mi.destination == 'string'  # Ensure destination is not a function
            mi.destination = mi.destination.format values
        mi.data = JSON.parse(mi.data.format values) if mi.data or ''

        return make_request index, mi, values, true


import_data = (columns, instructions, concurrent_requests, finished_callback) ->
    request_queue = []
    parsed_data.forEach (row, index) ->
        if $("#preview-row-#{index}>td:first-child>input")[0].checked
            request_queue.push prepare_request row, index, columns, instructions
        return
    asyncPool(concurrent_requests, request_queue, (request) -> return request() ).then ->
        finished_callback()
        return


# Parsing methods
# These are used when a file is selected and before import action is available

build_config = (parse_options, columns) ->
    # Merge defaults with overrides provided in parse_options in init

    config =   {
        delimiter: ','
        header: false
        dynamicTyping: false
        skipEmptyLines: true
        preview: 0
        step: false
        worker: false
        complete: on_parse_complete columns
        error: on_parse_error
        download: false
    }

    Object.assign({}, config, parse_options)


on_parse_complete = (columns) ->
    ###
    After papaparse has processed the rows do additional checking to ensure
    the number of columns is correct. If there are error(s), display them,
    otherwise fill in the preview table and enable the import controls.

    This function wraps the actual one called on parse complete in order to
    make the columns variable available.
    ###
    return  _on_parse_complete = (results) ->
        if results and results.errors
            if results.errors
              errorCount = results.errors.length
            if results.data and results.data.length > 0
              rowCount = results.data.length

        results.data.forEach (result, index) ->
            if result.length > columns.length
                results.errors.push
                    code: 'TooManyColumns'
                    message: 'Too many columns'
                    row: index + 1
            else if result.length < columns.length
                results.errors.push
                    code: 'TooFewColumns'
                    message: 'Too few columns'
                    row: index + 1
            return

        if results.errors.length
            message = ''
            results.errors.forEach (error) ->
                message += '{0} at row {1}<br/>'.format error.message, error.row
                return
            $('#errors')[0].innerHTML = message
            $('#error-container').removeClass 'd-none'
            return
        else
            table = $('#preview-table')[0]
            body = table.tBodies[0]
            results.data.forEach (result, index) ->
                row = body.insertRow(-1)
                row.id = 'preview-row-' + index
                cell = row.insertCell(-1)
                cell.innerHTML = "<input class='row-check-uncheck' type='checkbox' checked>"
                $(cell).addClass('text-center')
                result.forEach (item) ->
                    cell = row.insertCell(-1)
                    cell.innerHTML = item
                    return
                return

        parsed_data = results.data
        total_processed = 0
        total_successful = 0
        total_errors = 0
        update_progress()
        $('#import-data-button').attr 'disabled', false
        $('#import-controls').removeClass 'd-none'
        return

on_parse_error = (err, file) ->
    $('#errors')[0].innerHTML = err
    $('#error-container').removeClass 'd-none'
    return


get_selected_row_count = () ->
    return $('#preview-table>tbody>tr>td:first-child>input:checked').length


# Page init
init = (columns, instructions, concurrent_requests=6, parse_options={}) =>
    papa_config = build_config(parse_options, columns)

    table = $('#preview-table')[0]
    header_row = table.tHead.insertRow()
    cell = header_row.insertCell(-1)
    $(cell).addClass 'text-center d-none'
    cell.innerHTML = "<input id='check-uncheck-all' type='checkbox' checked>"
    columns.forEach (column) ->
        cell = header_row.insertCell(-1)
        cell.innerHTML = column[0]
        return

    $('#file').fileinput
        maxFileCount: 1
        showPreview: false
        showUpload: false
        showCancel: false
    .on 'filebatchselected', (event, files) ->
        parsed_data = []
        $('#import-controls').addClass 'd-none'
        $('#error-container').addClass 'd-none'
        $('#import-data-button').removeClass 'd-none'
        table.tBodies[0].innerHTML = ''
        $(table.tHead.rows[0].cells[0]).removeClass 'd-none'
        Papa.parse files[0], papa_config
    .on 'fileclear', (event) ->
        parsed_data = []
        $('#import-controls').addClass 'd-none'
        $('#error-container').addClass 'd-none'
        table.tBodies[0].innerHTML = ''
        table.tHead.rows[0].cells[0].children[0].checked = true
        $(table.tHead.rows[0].cells[0]).addClass 'd-none'

    $('#check-uncheck-all').click ->
        $(table.tBodies[0]).find('tr>td:first-child>input').prop(
            'checked', this.checked)
        update_progress()

    $('#preview-table>tbody').on 'click', '.row-check-uncheck', () ->
        update_progress()

    $('#import-data-button').click ->
        $('#import-data-button').addClass 'd-none'
        $('#progress').addClass 'active'
        $('#file').fileinput 'disable'
        $(table.tHead.rows[0].cells[0]).addClass 'd-none'
        $(table.tBodies[0]).find('tr>td:first-child').addClass 'd-none'
        $(table.tBodies[0]).find('tr>td:first-child>input:not(:checked)').closest('tr').addClass 'd-none'

        import_data(columns, instructions, concurrent_requests, () ->
            $('#progress').removeClass 'active'
            if not total_errors
                $('#progress').addClass 'bg-success'
            $('#file').fileinput 'enable'
            event = new CustomEvent('importer_finished', {
                detail: {
                    total_rows: parsed_data.length
                    errors: total_errors
                    successful: total_successful
                    selected: total_processed
                }
            })
            $('body')[0].dispatchEvent(event)
        )
        return

module.exports =
    init: init
