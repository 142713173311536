var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <img class=\"verification-photo\"\n            src=\"/applicant_document/get_document?id="
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"candidate") : depth0)) != null ? lookupProperty(stack1,"verification_item_photo") : stack1)) != null ? lookupProperty(stack1,"document_id") : stack1), depth0))
    + "\"\n            class=\"img-thumbnail\" alt=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"candidate") : depth0)) != null ? lookupProperty(stack1,"display_name") : stack1), depth0))
    + " "
    + alias2(__default(require("../../handlebars_helpers/lang.coffee")).call(depth0 != null ? depth0 : (container.nullContext || {}),"L001397","verification photo",{"name":"lang","hash":{},"data":data,"loc":{"start":{"line":8,"column":66},"end":{"line":8,"column":105}}}))
    + "\">\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"photo-verification-widget\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"candidate") : depth0)) != null ? lookupProperty(stack1,"verification_item_photo") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":9,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});