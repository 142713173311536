_ = require "underscore"
clarity = require "orb/system/clarity_client"
orb = require "orb"


# Different credential type editors are opened in different iframes, and thus
# have different instances of this cache
publish_cache = {}

perspective_cache = {
    credential: {}
    application: {}
}

# Tracks how many table updates are currently actively running.
# If non-zero, it is likely a network request is active, as found in the
# `maybe_fill_cache` function
active_updates = 0


cache_call = (call, promise, publish_uuid) ->
    call.then (publish_info) ->
        publish_cache[publish_uuid] = publish_info
        promise.resolve()

maybe_fill_cache = (publishes, credential_type) ->
    ### If there are publishes that we want information about, and they
    aren't already cached, this function will get information about them
    and add that information to the cache.

    This function returns a promise with no results. It simply indicates
    that all the required calls to clarity have completed.
    ###
    promises = []
    for utilized_publish_uuid in Object.keys publishes
        if not publish_cache[utilized_publish_uuid]?
            promise = $.Deferred()
            promises.push promise
            call = clarity.get_publish_version credential_type, utilized_publish_uuid, {expand: 'current_usages'}
            cache_call call, promise, utilized_publish_uuid
    Promise.all(promises)


selected_publish_groups = () ->
    ### Returns a dictionary of {publish_uuid: [group_type_x, group_type_y]
    For each selected cell in the publishing table, an entry is created for
    that cell's "type" (renewals, applications, etc) and that cell's publish version.
    ###
    group_types = [
        'renewals',
        'modified_renewals',
        'applications',
        'modified_applications',
    ]

    table = $('#publish-modal-v2 .publishing-table').DataTable()
    publishes = {}
    for cell in table.cells({selected: true})[0]
        utilized_publish_uuid = "#{table.row(cell.row).data().uuid}"
        # set the key's value as [] if it doesn't exist
        publishes[utilized_publish_uuid] ?= []

        # figure out which group this cell belongs to
        column_type = table.column(cell.column).dataSrc()
        if column_type.endsWith('_count')
            publishes[utilized_publish_uuid].push column_type.replace('_count', '')
    publishes


get_selected_publish_target_info = (credential_type) ->
    maybe_fill_cache selected_publish_groups(), credential_type
    .then () ->
        data = []
        for utilized_publish_uuid, groups of selected_publish_groups()
            for type in groups
                ###
                # In what scenario could we have a selected publish group that
                # is not in the publish cache?
                # * We have two publish target selection events
                # * the first event gets here first
                # * it will check for new selected groups
                # * those selected groups will be updated from the second event,
                #   but the second event has not yet added its result to the cache.
                #
                # An alternative approach is storing the selected publish groups at the
                # start of this function, before the async network request gets made.
                # The issue with that would be if the first event finishes second,
                # it would update the "selected population table" with out of date info.
                ###
                if publish_cache[utilized_publish_uuid]?
                    for i in publish_cache[utilized_publish_uuid][type]
                        uuid = if type.includes 'renewal' then i.credential_uuid else i.uuid
                        data.push {status: i.status, uuid: uuid, user_uuid: i.user_uuid, type: type}
        data


get_perspective_statuses = (credential_type, entity_type, uuids, view_name) ->
    # Gets and caches information about a list of {entity_type} UUIDs for a given credential type
    func = clarity["find_#{entity_type}s_for_credential_type"]

    # Replacing the type name for this credential for ease of integration
    # with the population/target info system
    type_name = entity_type.replace('credential', 'renewal')
    func(credential_type, uuids).then (data) ->
        formatted_data = data.map (i) ->
            {
                status: i.status
                uuid: i.uuid
                user_uuid: i.user_uuid
                type: type_name
            }
        perspective_cache[entity_type][view_name] = formatted_data
        formatted_data


targets_from_perspective = (credential_type, entity_type, view_name) ->
    # Gets a list of UUIDs for an entity type from a perspective.
    # Passes that list to a function which gets information about those
    # UUIDs, finally returning that information
    $.ajax(
        method: 'GET'
        url: "/filter/list/#{view_name}"
        data: {
            all: 1,
            filter_override: {
                fieldId: 'credential_type_uuid',
                operatorId: 'STRING_EQUAL',
                values: [{label: credential_type, value: credential_type}]
            }
        }
    ).then (data) ->
        uuids = _.pluck data.root, 'id'
        get_perspective_statuses credential_type, entity_type, uuids, view_name


get_publishing_targets_from_perspectives = (credential_type) ->
    types = ['credential', 'application']
    results = []

    for type in types
        view_name = $("#existing-#{type}s-select-v2").val()
        if view_name
            if perspective_cache[type][view_name]?
                promise = $.Deferred()
                results.push promise
                promise.resolve perspective_cache[type][view_name]
            else
                results.push targets_from_perspective(credential_type, type, view_name)
    Promise.all results


merge_publish_target_info = (selected, perspectives) ->
    if not selected and not perspectives
        return []
    if not selected
        return perspectives
    if not perspectives
        return selected

    credentials = [...selected.filter((i) -> i.type.includes 'renewal')]
    applications = [...selected.filter((i) -> i.type.includes 'application')]
    selected_credential_uuids = _.pluck credentials, 'uuid'
    selected_application_uuids = _.pluck applications, 'uuid'

    for perspective in perspectives
        if perspective[0]?.type.includes 'renewal'
            credentials.push ...perspective.filter (i) -> i.uuid not in selected_credential_uuids
        else if perspective[0]?.type.includes 'application'
            applications.push ...perspective.filter (i) -> i.uuid not in selected_application_uuids

    {credentials: credentials, applications: applications}

get_all_publish_target_info = (credential_type) ->
    get_selected_publish_target_info credential_type
    .then (target_info) ->
        get_publishing_targets_from_perspectives credential_type
        .then (info_from_perspectives) ->
            merge_publish_target_info target_info, info_from_perspectives


conclude_update = () ->
    active_updates -= 1
    active_updates == 0


update = (credential_type) ->
    ### Updates the publish targets table based off selected cells in
    the publish history table.

    Keeps track of how many updates are active, and maintains a visual indicator
    so that the user can see when the table is waiting on network requests.
    ###
    active_updates += 1

    $('#publish-targets-count').html '...'
    $('#publish-submit-v2').prop 'disabled', true

    get_all_publish_target_info credential_type
    .then (target_info) ->
        final_update = conclude_update()

        if final_update
            table_data = {Credentials: {}, Applications: {}}
            for target in target_info.credentials
                table_data['Credentials'][target.status] ?= []
                table_data['Credentials'][target.status].push target.uuid
            for target in target_info.applications
                table_data['Applications'][target.status] ?= []
                table_data['Applications'][target.status].push target.uuid

            target_table = $('#publish-targets-table').DataTable()
            formatted_table_data = []
            for type, type_data of table_data
                for status, uuids of type_data
                    row = {
                        target_type: type
                        status: status
                        count: uuids.length
                    }
                    formatted_table_data.push row

            target_table.clear()
            target_table.rows.add formatted_table_data
            target_table.draw()

            count_column = $('#publish-targets-table').DataTable().column('count:name')
            total_count = if formatted_table_data.length > 0 then count_column.data().reduce (a, b) -> a + b else 0
            $('#publish-targets-count').html total_count
            $('#publish-submit-v2').prop 'disabled', false
    .catch (err) ->
        conclude_update()
        orb.flash_error 'Error getting population data'

        # The "publish targets table" will be cleared by the deselection of
        # all these cells; thus, we don't have to explicitly do it here.
        $(this).find('select, input[type="text"], textarea').val('').trigger 'change'
        $('#publish-modal-v2 .publishing-table').DataTable().cells().deselect()


setup_table = () ->
    table = $('#publish-targets-table')
    table.DataTable {
        order: [[ 1, 'asc' ]]
        orderFixed: [[ 0, 'desc' ]]
        paging: false
        searching: false
        info: false
        autoWidth: false
        rowGroup: {
            dataSrc: 'target_type',
            className: 'table-row-group-header'
        }
        columns: [
            {
                data: 'target_type'
                name: 'internal_grouping'
                visible: false
            }
            {
                title: 'Status'
                data: 'status'
            }
            {
                title: 'Count'
                data: 'count'
                name: 'count'
            }
        ]
        language: {
            emptyTable: BL.Language.get('L001435', 'No population selected for publish')
        }
    }


publish_to_target = (url, publish_version, type, targets) ->
    selector = '#publish-targets-table .table-row-group-header th'
    group_header = $(selector).filter (_, el) -> $(this).text().toLowerCase().includes type
    group_header.html group_header.html() + '<span class="result-indicators fa" style="margin-left: 10px;"></span>'
    params = JSON.stringify {
        apps_or_credentials: targets
        publish_version: publish_version.uuid
        reason: publish_version.reason
        publish_type: "update_#{type}"
    }
    fetch url, {
        method: "POST"
        body: params
        headers: {"Content-Type": "application/json"}
    }
    .then (response) ->
        if not response.ok
            group_header.find('.fa').addClass('fa-times text-danger').animate({height: 'show'})
        else
            group_header.find('.fa').addClass('fa-check text-success').animate({height: 'show'})


publish_to_all_targets = (credential_type, publish_version) ->
    get_all_publish_target_info(credential_type).then (publish_targets) ->
        publish_url = "/credentials/credential_types/#{credential_type}/publish"
        for type, data of publish_targets
            if data.length
                targets = data.map (i) -> {user_uuid: i.user_uuid, uuid: i.uuid}
                publish_to_target publish_url, publish_version, type, targets


module.exports =
    'update': update
    'setup_table': setup_table
    'publish_to_all_targets': publish_to_all_targets
