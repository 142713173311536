### Helpers for Handlebars templates.

# lang: Allow language token usage

    Usage:

        {{lang "<token>" "<default text>"}}

    e.g.

        {{lang "L000177" "<strong>Default Heading.</strong>"}}



# lang_entity: Allow language token usage for object properties

    Usage:
        {{lang_entity "<entity_type>" "<slug>" "<property>" "<default text">}}

    e.g.
        {{lang_entity "credential_type" "cred_fas1" "name" "FAS-I"}}


# markdown: Render markdown

    Usage:
        {{markdown "<src>"}}


# locale_date: Render a time element that contains a localized date string

    Usage:
        {{locale_date "<ISO 8601 date>"}}

    e.g.
        {{locale_date issued_on}}

    Result:
        <time datetime="2019-07-06">07/06/2019</time>


# if_equal: render a block conditionally based on the equality of two elements

    Usage:
        {{#if_equal arg1 'string to compare against'}}
        {{/if_equal}}


# if_not_equal: render a block conditionally based on the inequality of two elements

    Usage:
        {{#if_not_equal arg1 'string to compare against'}}
        {{/if_not_equal}}


# date: format a date using date.js and moment.js
    Usage:
        {{date}} - Display current date
        {{date <format>}} - Display current date using moment.js format specification

    See https://github.com/helpers/helper-date for more information


# has_permission: return true if the current user has the permission otherwise false
    Usage:
        {{#if (has_permission 'message_threads_view')}}
         ...
        {{/if}}

# in_group: return true if the current user is in the provided group otherwise false
    Usage:
        {{#if (in_group 'Candidate Reviewers')}}
         ...
        {{/if}}

# get_config: returns the value of the specified configuration if available
    Usage:
        {{#if (get_config 'registration.allow_new_candidates')}}
         ...
        {{/if}}

# plugin_url: returns the path url to the location using the mounted plugin path
    Usage:
        {{plugin_url 'dynamic_views' '/pages/ce/marketplace'}}

# plugin_enabled: returns true if the plugin is detected as enabled otherwise false
    Usage:
        {{plugin_url 'dynamic_views'}}

#to_array: turns a list of strings into an array
    Usage:
        to_array "a" "b" "c"

#in_array: returns true if the value is in the specified array
    Usage:
        in_array "a" (to_array "a" "b" "c")

#any: returns true if any values are truthy
    Usage:
        any (has_permission "message_threads_reply") (has_permission "message_threads_view")

#all: returns true if all values are truthy
    Usage:
        all (has_permission "message_threads_reply") (has_permission "message_threads_view")

#has_bootstrap_5: returns true if bootstrap is detected on the window object otherwise false
    Usage:
        {{#if (has_bootstrap_5)}}
         ...
        {{/if}}

###

Handlebars = require("handlebars/dist/handlebars")
HandlebarsRuntime = require("handlebars/runtime")
lang_script_block = require("html-loader!./lang.html")
require "moment/min/locales"
date = require('helper-date')
markdown = require("markdown-it")({html: true})

# Inject the translation database and language support functions if not
# already loaded.
if not Boolean(window.BL)
    window.BL = {}
if not Boolean(window.BL.Language)
    $("body").append lang_script_block

render_token = (token, default_text) ->
    new Handlebars.SafeString(BL.Language.get token, default_text or '')

render_entity = (entity_type, identifier, property, default_text) ->
    new Handlebars.SafeString(
        BL.Language.get_entity entity_type, identifier, property, default_text or '')

render_markdown = (src) ->
	# Prevent failure when src is SafeString
    try
        markdown.render(src)
    catch
        markdown.render(src.string)

render_time_element = (date) ->
    Clarus.localization.get_time_element_markup date

check_equality = (arg1, arg2, options) ->
    if arg1 == arg2 then options.fn(this) else options.inverse(this)

check_inequality = (arg1, arg2, options) ->
   if arg1 != arg2 then options.fn(this) else options.inverse(this)

has_permission = (permission) ->
    try
        if permission in NextGen.metadata.user.permissions
            return true
    catch
        # will return false

    return false

in_group = (group) ->
    try
        if group in NextGen.metadata.user.groups
            return true
    catch
        # will return false

    return false

get_config = (config) ->
    if not Boolean(BL and BL.Config)
        return

    return BL.Config.get(config)

plugin_url = (plugin, path) ->
    try
        return NextGen.metadata.plugin_url plugin, path
    catch
        return

plugin_enabled = (plugin) ->
    NextGen.metadata.clarus_plugins.some (plugin_entry) ->
        plugin_entry[0] == plugin

to_array = () ->
    return Array.from(arguments).slice(0, -1)

in_array = (value, list) ->
    return list.indexOf(value) > -1

any = () ->
    return Array.prototype.slice.call(arguments, 0, -1).some(Boolean)

all = () ->
    return Array.prototype.slice.call(arguments, 0, -1).every(Boolean)

has_bootstrap_5 = () ->
    try
        return Boolean(window.bootstrap)
    catch
        return false

[Handlebars, HandlebarsRuntime].forEach (module) ->
    module.registerHelper 'lang', render_token
    module.registerHelper 'lang_entity', render_entity
    module.registerHelper 'markdown', render_markdown
    module.registerHelper 'locale_date', render_time_element
    module.registerHelper 'if_equal', check_equality
    module.registerHelper 'if_not_equal', check_inequality
    module.registerHelper 'date', date
    module.registerHelper 'has_permission', has_permission
    module.registerHelper 'in_group', in_group
    module.registerHelper 'get_config', get_config
    module.registerHelper 'plugin_url', plugin_url
    module.registerHelper 'plugin_enabled', plugin_enabled
    module.registerHelper 'to_array', to_array
    module.registerHelper 'in_array', in_array
    module.registerHelper 'any', any
    module.registerHelper 'all', all
    module.registerHelper 'has_bootstrap_5', has_bootstrap_5


module.exports =
    render_token: render_token
    render_entity: render_entity
    render_markdown: render_markdown
    render_time_element: render_time_element
    if_equal: check_equality
    if_not_equal: check_inequality
    date: date
    has_permission: has_permission
    in_group: in_group
    get_config: get_config
    plugin_url: plugin_url
    plugin_enabled: plugin_enabled
    to_array: to_array
    in_array: in_array
    any: any
    all: all
    has_bootstrap_5: has_bootstrap_5