### Clarus API client

This module provides an interface for interacting with Clarus as an API.

###


# TODO: at some point it will probably make sense to utilize Backbone models
# to organize and manage this interface.



# Functions for interacting with candidate records
candidate =
    by_uuid: (uuid) ->
        ### Fetch a candidate record by UUID

        Given a candidate UUID, fetch information about the candidate,
        including their Clarus ID, name, username, and organization.

        Returns a jQuery XHR.

        ###

        $.ajax
            url: "/applicant/get"
            method: "post"
            data:
                uuid: uuid

    add_note: (candidate_uuid, note) ->
        ### Add a note to a candidate's history

        Given a candidate UUID and note text as a string, add the note
        to the candidate's history record. The current user will be
        recorded as the user on the note.

        ###

        $.ajax
            url: "/applicant/add_note"
            method: "post"
            data:
                uuid: candidate_uuid
                note: note

photo_verification =
    update_status: (photo_id, photo_status) ->
        ### Update the status of a Photo verification item

        Given an id of a candidate's photo verification item
        and the name of a verification item status, update the 
        photo's status.
        ###

        $.ajax
            url: "/verification/update_verification_photo_status"
            method: "post"
            data:
                id: photo_id
                status_name: photo_status


create_exam_type = (data) ->
    $.post
        url: '/api/v2/exam_types/'
        dataType: 'json'
        contentType: 'application/json'
        data: JSON.stringify data

get_exam_types = () ->
    $.get(url: '/api/v2/exam_types')


set_lang = (token, value) ->
    $.post
        url: '/language/edit_message'
        data:
            token: token
            lang_code: 'en'
            value: value


module.exports =
    candidate: candidate
    photo_verification: photo_verification
    get_exam_types: get_exam_types
    create_exam_type: create_exam_type
    set_lang: set_lang
