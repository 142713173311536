apigClientFactory = require('./apigClient').default;
clarity = require "../system/clarity_client"


get_service_authorization = ->
    ### Get temporary AWS access keys

    Either retrieves temporary access keys from clarity service auth endpoint or
    returns the stored keys.  The stored keys will be used as long as the
    provided expiration has not passed.  After that, new access keys will be
    requested and stored.

    ###

    service_auth_store = sessionStorage.getItem 'clarity:service_authorization'

    new Promise( (resolve) ->
        if service_auth_store
            service_auth = JSON.parse service_auth_store
            auth_valid = service_auth and moment().isBefore service_auth.expiration
            if auth_valid
                resolve service_auth
                return

        clarity.get_service_authorization().then (result) ->
            sessionStorage.setItem 'clarity:service_authorization', JSON.stringify(result)
            resolve result
    )


execute = (url, path='', method='GET', data={}, defaultAcceptType='application/json', responseType='') ->
    ### Execute an API Gateway request

    Uses the temporary credentials returned from get_service_authorization along
    with the api gateway client factory to generate a client and send a signed
    request to an api gateway endpoint.
 
    ###

    get_service_authorization().then ( credentials ) ->
        config = $.extend({}, credentials, {
            invokeUrl: url ,
            region: 'us-east-1'
            defaultAcceptType: defaultAcceptType
        })

        client = apigClientFactory.newClient(config)
        return client.invokeApi({}, path, method, {}, data, responseType)


module.exports =
    execute: execute
