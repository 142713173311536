_ = require "underscore"

default_type = require "./default"


renderers =
    default:
        overview: default_type.render_overview
        actions: default_type.render_actions
        details: default_type.render_details

    application:
        overview: default_type.render_overview
        actions: default_type.render_actions
        details: require "./application/details.coffee"


register_renderers = (review_packet_type, actions_renderer="default",
                      details_renderer="default") ->

    ### Define new renderer handlers for a review packet type

    Given a review packet type, add the type to the list of available review
    packet renderers with the provided actions and details renderer functions.

    Both the actions_renderer and details_renderer arguments can be specified
    as strings. For each, if the argument is a string, it is assumed to be the
    name of an existing renderer, and the existing renderer function for that
    section (actions or details) will be used for the registration of this
    new type.

    Otherwise, the renderer arguments are assumed to be functions and will be
    used directly when rendering this review packet type.

    Examples:

    * a custom profile review packet with the default review packet workflow
      actions and a custom details section:

        custom_details = require "./details_renderer"

        register_renderers("ndeb_profile", "default", custom_details)

    * a custom application review packet with the “core” application workflow
      actions and the _default_ (not application) details renderer:

        register_renderers("simpler_application", "application", "default")

   * a custom application review packet with the “core” application workflow
      actions and a custom details renderer:

        my_cool_details_renderer = require "./cool_details"

        register_renderers("nclient_application", "application",
                           my_cool_details_renderer)

    ###

    if _.isString actions_renderer
        actions_renderer = renderers[actions_renderer].actions

    if _.isString details_renderer
        details_renderer = renderers[details_renderer].details

    renderers[review_packet_type] =
        overview: default_type.render_overview
        actions: actions_renderer
        details: details_renderer


get_renderers = (review_packet_type="default") ->
    ### Get the actions and details section renderers for a review packet

    Given a review packet type, return the actions section and details section
    renderers for that review packet. Each renderer is a function that accepts
    the review packet and optionally an options object; when called, the
    renderer redraws the appropriate section of the review packet.

    If the provided review packet type does not have a type-specific renderer,
    the default renderers will be returned instead. The default actions
    renderer provides a basic workflow allowing updates to the status of the
    review packet. See orb/review_packet/actions/actions.coffee for details.
    The default details renderer looks for a handlebars template for the
    review packet type defined in the options, e.g.

        s3://client-public-files.../ndeb/review_packets/profile/details.mustache

    for a hypothetical `profile` review packet type.

    Returns an object:

        {
            "actions": actions_renderer_fn,
            "details": details_renderer_fn
        }

    ###

    renderers[review_packet_type] or renderers.default


module.exports =
    "get_renderers": get_renderers
    "register_renderers": register_renderers
