# Credentials Dynamic Table configuration
#
# Also serves as an example for building other dynamic tables.

_ = require "underscore"
js_yaml = require "js-yaml"

clarity = require "orb/system/clarity_client"
clarus = require "orb/system/clarus_client"
orb = require "orb"
render_add_credential = require "./add_credential_action.mustache"
moment = require "moment"
ace_editor = require "orb/components/ace"
dynamic_table_core = require "../core"
timeline_table_tmpl = require "./timeline_table.mustache"
timeline_editor_tmpl = require "./timeline_editor.mustache"
require "select2"
require "select2/dist/css/select2.css"


# TODO: we probably should make a helper for this, but I'm not sure where
# it should live yet…
show_xhr_error = (xhr, summary="There was a problem with the request") ->
    detail = ""

    if xhr.responseJSON?.message
        detail = "<br/><br/>#{xhr.responseJSON.message}"

    orb.flash_error "#{summary}#{detail}"


# TODO: we probably should make a helper for this, but I'm not sure where
# it should live yet…
has_permission = (permission_name) ->
    user_permissions = NextGen.metadata.user.permissions or []

    permission_name in user_permissions


template_context =
    headings: [
        "Internal",
        "Credential Type",
        "Credential Number",
        "Issued On",
        "Expiration",
        "Status",
        "Valid Since",
        "Actions"
    ]

    before_table: """
       <style>
        /* Use opacity transitions from branding.css */
        tr .credential-action {
            opacity: 0;
            transition: opacity 0.3s ease;
        }

        tr:hover .credential-action {
            opacity: 0.4;
        }

        tr:hover .credential-action:hover {
            opacity: 1.0;
        }

        /* Use spacing utilities from BS5 */
        .nav-row {
            padding-bottom: 0.5rem;
        }

        #transition-schedule {
            padding-left: 0;
            padding-right: 0;
        }

        /* Use brand colors from branding.css */
        .data-group-internal {
            background-color: var(--brightlink-green) !important;
            font-weight: 600;
            color: white;
        }

        .data-group-public {
            background-color: var(--brightlink-blue) !important;
            font-weight: 600;
            color: white;
        }

        /* Table scroll styles */
        .dynamic-table tbody {
            display: block;
            overflow: auto;
            /* Dynamic height that leaves room for other elements */
            max-height: calc(100vh - 200px);
        }

        .dynamic-table thead, 
        .dynamic-table tbody tr, 
        .dynamic-table tfoot {
            display: table;
            width: 100%;
            table-layout: fixed;
        }
    </style>

    
    <div class="modal fade edit-credential-modal" tabindex="-1" aria-labelledby="edit-credential-modal-label" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <form>
                    <div class="modal-header">
                        <h4 class="modal-title float-start" id="edit-credential-modal-label">Edit Credential</h4>
                        <button type="button" class="btn-close float-end" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row nav-row">
                            <ul class="nav nav-tabs nav-justified">
                                <li id="details-nav" class="nav-item">
                                    <a class="nav-link active" href="#credential-data" data-bs-toggle="tab">
                                        <h5>Credential Data</h5>
                                    </a>
                                </li>
                                <li id="configuration-nav" class="nav-item">
                                    <a class="nav-link" href="#renewal-configuration" data-bs-toggle="tab">
                                        <h5>Renewal Configuration</h5>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="tab-content">
                            <div class="tab-pane active" id="credential-data">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group row">
                                            <label class="col-3 col-form-label">Credential Type:</label>
                                            <span class="credential-type col-9"></span>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-3 col-form-label">Credential Abbr:</label>
                                            <span class="credential-abbr col-9"></span>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-3 col-form-label">Credential number:</label>
                                            <span class="col-3">
                                                <input name="number" class="form-control" type="text" required>
                                            </span>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-3 col-form-label">Issued on:</label>
                                            <span class="col-3">
                                                <input name="issued_on" class="form-control datepicker" data-date-format="yyyy-mm-dd" data-date-autoclose="true" data-provide="datepicker" data-date-today-highlight="true" required>
                                            </span>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-3 col-form-label">Current renewal start date:</label>
                                            <span class="col-3">
                                                <input name="renewal_start" class="form-control datepicker" data-date-format="yyyy-mm-dd" data-date-autoclose="true" data-provide="datepicker" data-date-today-highlight="true" required>
                                            </span>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-3 col-form-label">Reason for change:</label>
                                            <span class="col-7">
                                                <textarea rows="3" name="reason" class="form-control" required></textarea>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane" id="renewal-configuration">
                                <div class="form-group row">
                                    <span class="col-12">
                                        <textarea rows="20" name="configuration" class="form-control" data-editor="yaml"></textarea>
                                    </span>
                                </div>
                            </div>
                            <br/>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-primary">Save changes</button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="modal fade view-timeline-modal" tabindex="-1" aria-labelledby="view-timeline-modal-label" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <form class="form-inline" id="edit-timeline-form" autocomplete="off">
                    <div class="modal-header">
                        <h4 class="modal-title" id="view-timeline-modal-label">Credential Timeline Transitions</h4>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body"></div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-primary timeline-update-btn">Update</button>
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="modal fade renew-credential-modal" tabindex="-1" aria-labelledby="renew-credential-modal-label" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <form autocomplete="off">
                    <div class="modal-header">
                        <h5 class="modal-title" id="renew-credential-modal-label">Renew Credential</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="renew-credential-message"></div>
                        <br/>
                        <div class="mb-3">
                            <label for="renewal_start" class="form-label">Renewal effective date:</label>
                            <input name="renewal_start" type="text" class="form-control datepicker" data-date-format="yyyy-mm-dd" data-date-autoclose="true" data-provide="datepicker" data-date-today-highlight="true" required>
                        </div>
                        <div class="mb-3">
                            <label for="reason" class="form-label">Reason:</label>
                            <textarea name="reason" class="form-control" required></textarea>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-primary">Save changes</button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="modal fade revoke-credential-modal" tabindex="-1" aria-labelledby="revoke-credential-modal-label" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <form autocomplete="off">
                    <div class="modal-header">
                        <h5 class="modal-title" id="revoke-credential-modal-label">Revoke Credential</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <p>
                            You are about to revoke this credential.
                            This action will put this credential in an invalid, non-renewable state.
                        </p>
                        <div class="revoke-credential-message"></div>
                        <br/>
                        <div class="mb-3">
                            <label for="status" class="form-label">Status:</label>
                            <select name="status" class="form-select revoke-credential-statuses" required></select>
                        </div>
                        <div class="mb-3">
                            <label for="reason" class="form-label">Reason:</label>
                            <textarea name="reason" class="form-control" required></textarea>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        <button type="submit" class="btn btn-primary">Revoke</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    """

# Prevent tab navigation from changing browser location
$("#app-content").on('click', '.nav-link', ((event) ->
    event.preventDefault()
    $(this).tab('show'))
)

_reload_table = (datatable, user_uuid) ->
    datatable.clear()
    clarity.get_credentials(user_uuid, 'display_expiration, display_status', true, true).done (credentials) ->
        datatable.rows.add(credentials)
        if datatable.column('internal:name').data().unique().length > 1
            datatable.rowGroup().enable().draw()
        else
            datatable.rowGroup().disable().draw()
    .fail (xhr) ->
        show_xhr_error xhr, "There was a problem fetching the candidate's \
                             credentials"

_show_actions = ->
    has_permission "clarity:user_credentials_edit" or
        has_permission "clarity:user_credentials_renew" or
        has_permission "clarity:user_credentials_revoke"


table_options =
    rowGroup: {
        dataSrc: 'credential_type_internal',
        startRender: (rows, group) ->
            if group
                label = 'Internal'
                class_name = 'data-group-internal'
            else
                label = 'Standard'
                class_name = 'data-group-public'

            column_count = rows.columns(':visible').count()

            return $("<tr class='#{class_name}'></tr>")
                .append("<td colspan='#{column_count}'>#{label}")
    }
    columns: [
        {
            data: "credential_type_internal"
            name: 'internal'
            visible: false
        }
        {
            data: "credential_type_abbr"
            render: (abbr, type, data, meta) ->
                "<abbr title='#{data.credential_type_name}'>#{abbr}</abbr>"
        }
        {data: "number"}
        {data: "issued_on"}
        {data: "display_expiration"}
        {data: "display_status"}
        {
            data: "valid_since"
            render: (valid_since, type, data, meta) ->
                if valid_since
                    result = "<span> #{valid_since} </span>"
                    if data.historic
                        result + " <i class='fa-regular fa-calendar text-success' title='Credential is historic'></i>"
                    else
                        result
                else
                    "<i class='text-danger fa-solid fa-xmark'></i>"
        }
        {
            sortable: false
            searchable: false
            visible: _show_actions
            render: (dontcare, dontcare2, credential, meta) ->
                actions = []
                
                if has_permission("clarity:user_credentials_edit")
                    actions.push """
                        <button class="btn btn-link btn-sm p-0 edit-credential credential-action" 
                                data-row="#{meta.row}" title="Edit credential">
                            <i class="fa-solid fa-pencil"></i>
                        </button>
                    """

                if not credential.terminal and not credential.shadows_credential_id
                    actions.push """
                        <button class="btn btn-link btn-sm p-0 ms-3 view-credential-timeline credential-action" 
                                data-row="#{meta.row}" title="View/Update Timelines">
                            <i class="fa-solid fa-clock"></i>
                        </button>
                    """

                if has_permission("clarity:user_credentials_renew") and not credential.historic
                    actions.push """
                        <button class="btn btn-link btn-sm p-0 ms-3 renew-credential credential-action" 
                                data-row="#{meta.row}" title="Renew credential">
                            <i class="fa-solid fa-rotate-right"></i>
                        </button>
                    """

                if has_permission("clarity:user_credentials_revoke")
                    actions.push """
                        <button class="btn btn-link btn-sm p-0 ms-3 revoke-credential credential-action" 
                                data-row="#{meta.row}" title="Revoke credential">
                            <i class="fa-solid fa-ban"></i>
                        </button>
                    """

                actions.join("")
        }
    ]
    # The fixed number must be the same index as our "internal" column
    orderFixed: [[ 0, "asc" ]]
    # Initially order by credential type abbreviation
    order: [[ 1, "asc" ]]
    paging: false
    searching: false
    info: false
    autoWidth: false
    language: {
        emptyTable: "<em>This candidate currently has no credentials</em>"
    }


_setup_modals = (datatable, user_uuid) ->
    _edit_modal datatable, user_uuid
    _credential_timeline_modal datatable, user_uuid
    _renew_modal datatable, user_uuid
    _revoke_modal datatable, user_uuid


_edit_modal = (datatable, user_uuid) ->
    modal = null
    row = null

    if not has_permission "clarity:user_credentials_edit"
        return

    can_edit_configuration = has_permission "clarity:credential_types_edit"
    if can_edit_configuration
        $('.nav-row').removeClass('hidden')

    $("tbody").on "click", ".edit-credential", ->
        $('a[href="#credential-data"]').click();
        row = $(this).data("row")
        credential_uuid = datatable.row(row).data().uuid;
        credential_request = clarity.get_credential(user_uuid, credential_uuid)
        renewal_request = clarity.get_credential_renewal(
            user_uuid, credential_uuid, 'timeline, config')
        $.when(credential_request, renewal_request)
            .done (credential, renewal) ->
                modal_el = $(".edit-credential-modal")
                modal = new bootstrap.Modal(modal_el[0]);
                modal.show();
                modal_el.find("textarea[name=reason]").val('')
                modal_el.find(".credential-type").text(credential[0].credential_type_name)
                modal_el.find(".credential-abbr").text(credential[0].credential_type_abbr)
                modal_el.find("input[name=number]").val(credential[0].number)
                modal_el.find("input[name=issued_on]").val(credential[0].issued_on)
                modal_el.find("input[name=renewal_start]").val(renewal[0].renewal_start)
                modal_el.find("textarea[name=configuration]").val(renewal[0].credential_type_configuration)
                return

    $('.modal').on 'click', '#configuration-nav', ->
        ace_editor.enable_ace()
            .then ->
                config = $(".edit-credential-modal").find("textarea[name=configuration]")
                ace.edit("ace-configuration").getSession().setValue(config.val())
                return

    # switch back to first tab before submission in order to show validation errors
    $(".edit-credential-modal form").on "click", "button[type='submit']", (e) ->
        $('a[href="#credential-data"]').click()

    $(".edit-credential-modal form").on "submit", (e) ->
        e.preventDefault()
        $("button[type='submit']").prop('disabled', true).toggleClass('disabled', true)
        form = this
        credential_type_name = datatable.row(row).data().credential_type_name
        credential_uuid = datatable.row(row).data().uuid

        credential_updates =
            number: form.number.value
            issued_on: form.issued_on.value

        renewal_updates =
            renewal_start: form.renewal_start.value
            credential_type_configuration: form.configuration.value

        clarity.update_credential user_uuid, credential_uuid, credential_updates
            .then (credential) ->
                clarity.update_credential_renewal user_uuid, credential_uuid, renewal_updates
                    .fail (xhr) ->
                        errors =
                            '<br><br>' +
                            '<pre>' +
                                JSON.stringify(xhr.responseJSON, undefined, 2) +
                            '</pre>'
                        xhr.responseJSON.message = errors
                    .then (renewal) ->
                        clarus.candidate.add_note user_uuid,
                            "Staff updated credential #{credential_type_name} | Reason: " + form.reason.value
            .done (credential) ->
                _reload_table(datatable, user_uuid)
                orb.flash_success "Credential #{credential.credential_type_name} \
                                           updated."
                bootstrap.Modal.getInstance($(".edit-credential-modal")[0]).hide();
                $("button[type='submit']").prop('disabled', false).toggleClass('disabled', false)
            .fail (xhr) ->
                show_xhr_error xhr, "There was a problem editing the credential"
                $("button[type='submit']").prop('disabled', false).toggleClass('disabled', false)


_credential_timeline_modal = (datatable, user_uuid) ->
    ###Set up modal for displaying and potentially editing credential timeline transition

    User is required to have "clarity:user_credentials_view" to
    view timeline/display_expiration date and "clarity:user_credentials_edit"
    to edit timeline/display_expiration date.
    ###

    modal = null
    row = null

    if not has_permission "clarity:user_credentials_view"
        return
        
    can_edit = has_permission "clarity:user_credentials_edit"
    if not can_edit
        $(".timeline-update-btn").hide()

    $("tbody").on "click", ".view-credential-timeline", ->
        ###Display credential timeline modal

        Fetch timeline data and transition configuration from credential's current
        renewal, and then load data to timeline table. If user has edit permission,
        then render the timeline editor as well.

        ###
        row = $(this).data("row")
        credential = datatable.row(row).data()

        renewal_request = clarity.get_credential_renewal user_uuid, credential.uuid, 'timeline, config'
        $.when(renewal_request)
            .done (renewal) ->
                modal_el = $(".view-timeline-modal")
                modal = new bootstrap.Modal(modal_el[0])
                modal.show()

                timeline_data = _process_timeline_data(
                    renewal.timeline,
                    renewal.credential_type_configuration
                )
                modal_el.find(".modal-body").html(
                    timeline_table_tmpl
                        timeline: timeline_data
                        credential: credential
                        renewal: renewal
                        can_edit: can_edit
                )

    $("body").on "show.bs.collapse", ".timeline-edit-collapse", (e)->
        ###Render timeline editor when user expands a row in timeline table

        If there is an expanded timeline entry already, close it and reset the editor.
        ###
        $("#timeline-error-msg").remove()
        $(this).html(timeline_editor_tmpl
            transition_name: e.target.dataset.transitionName
        )
        $(".timeline-edit-collapse.show").each ->
            $(this).collapse("hide")

    $("body").on "hide.bs.collapse", ".timeline-edit-collapse", (e)->
        _reset_timeline_table()
        $(this).html("")

    $("body").on "submit", "#edit-timeline-form", (e) ->
        e.preventDefault()
        _update_credential_timeline(false)

    $("body").on "change", ".timeline-field", (e)->
        _update_credential_timeline(true)

    _process_timeline_data = (timeline, configuration_yaml) ->
        ###Combine transition configuration into each timeline

        This is to add transition display name and time_period to each
        timeline entry.

        ###
        renewal_configuration = js_yaml.load(configuration_yaml)
        timeline.forEach (entry) ->
            entry.display_name = renewal_configuration.transitions[entry.status].display_name
            entry.time_period = renewal_configuration.transitions[entry.status].time_period

        timeline

    _update_credential_timeline = (preview) ->
        ###Preview or save credential timeline changes

        If preview the changes, gather the changes and issue a request to credential
        service to get the updated transition date and time period for each timeline
        entry. If there is an validation/server error, show the error message in
        timeline editor; otherwise update timeline table with new information.

        Otherwise, for the actual submission, issue a request to credential service
        to update credential renewal configuration and timelines, reload the
        datatable and close the timeline modal if successful; otherwise show
        error in timeline editor.

        ###

        $(".timeline-update-btn").prop('disabled', true).toggleClass('disabled', true)
        $("#timeline-error-msg").remove()

        credential_uuid = datatable.row(row).data().uuid
        credential_type_abbr = datatable.row(row).data().credential_type_abbr
        form_data = $("#edit-timeline-form").serializeArray()
        timeline_updates = _.object(_.map(form_data, _.values))
        timeline_updates.preview = preview

        if not timeline_updates.transition_name
            timeline_updates.transition_name = $("#edit-timeline-form input[name='transition_name']").val()

        if timeline_updates.transition_name
            timeline_updates.extended_days *= timeline_updates.multiplier
            timeline_updates.extended_months *= timeline_updates.multiplier

        clarity.update_credential_schedule user_uuid, credential_uuid, timeline_updates
            .done (data) ->
                $(".timeline-update-btn")
                    .prop('disabled', false)
                    .toggleClass('disabled', false)

                timeline_data = _process_timeline_data(
                    data.timeline,
                    data.credential_type_configuration
                )
                if preview
                    _update_timeline_table(timeline_data)
                else
                    $(".view-timeline-modal").modal("hide")
                    _reload_table(datatable, user_uuid)
                    orb.flash_success "Credential timeline updated."
# FIXME: If staff does not have 'applicants_edit' permission,
                    # this request is going to fail.
                    clarus.candidate.add_note user_uuid,
                        "Transition schedules for credential #{credential_type_abbr} updated | Reason: " + timeline_updates.reason
            .fail (xhr) ->
                msg = "There is a problem trying to process your request."
                if xhr.responseJSON
                    msg = "#{xhr.responseJSON.message or JSON.stringify(xhr.responseJSON)}"
                $(".timeline-edit-collapse.show").append(
                    "<p id='timeline-error-msg' class='text-danger'>#{msg}</p>"
                )
                $(".timeline-update-btn").prop('disabled', false).toggleClass('disabled', false)

    _update_timeline_table = (timeline) ->
        ###Update timeline table with new timeline data

        If new timeline entry has a different transition date and time period,
        update that row will new data and highlight that row with bs warninng class.

        ###
        timeline.forEach (entry) ->
            timeline_row = $("#timeline-#{entry.status}")
            timeline_cells = timeline_row.children()
            if timeline_cells[5].innerText != entry.transition_date
                timeline_cells[5].innerText = entry.transition_date
                timeline_cells[6].innerText = entry.time_period
                timeline_row.toggleClass("warning", true)

    _reset_timeline_table = () ->
        ###Reset timeline table with original timeline data

        Update any updated entry back to original data and remove the highlights
        on those rows.
        ###
        $("table#transition-table tr.warning").each () ->
            timeline_cells = $(this).children()
            timeline_cells[5].innerText = timeline_cells[5].dataset.original
            timeline_cells[6].innerText = timeline_cells[6].dataset.original
            $(this).toggleClass("warning", false)


_renew_modal = (datatable, user_uuid) ->
    modal = null
    row = null

    if not has_permission "clarity:user_credentials_renew"
        return

    $("tbody").on "click", ".renew-credential", ->
        row = $(this).data("row")
        credential = datatable.row(row).data()

        markup = """
            <dl class="dl-horizontal">
                <dt>Credential</dt>
                <dd>#{credential.credential_type_abbr}</dd>

                <dt>Credential number:</dt>
                <dd>#{credential.number}</dd>

                <dt>Status:</dt>
                <dd>#{credential.display_status}</dd>

                <dt>Expiration:</dt>
                <dd>#{credential.display_expiration}</dd>
            </dl>
        """

        modal_el = $(".renew-credential-modal");
        modal = new bootstrap.Modal(modal_el[0]);
        modal.show();
        modal_el.find(".modal-body .renew-credential-message").html(markup)

    $(".renew-credential-modal form").on "submit", (e) ->
        e.preventDefault()

        form = this
        credential_type_name = datatable.row(row).data().credential_type_name
        credential_uuid = datatable.row(row).data().uuid
        renewal_start = form.renewal_start.value
        reason = form.reason.value

        clarity.renew_credential user_uuid, credential_uuid, renewal_start
            .done (credential) ->
                clarus.candidate.add_note user_uuid,
                    "Staff renewed credential #{credential_type_name}, \
                     effective #{renewal_start}. Reason: #{reason}"

                _reload_table(datatable, user_uuid)

                orb.flash_success "Credential #{credential.credential_type_name} \
                                   renewed."
            .fail (xhr) ->
                show_xhr_error xhr, "There was a problem renewing the credential"

        modal = bootstrap.Modal.getInstance($(".renew-credential-modal")[0]);
        modal.hide();


_revoke_modal = (datatable, user_uuid) ->
    ###Set up revoke the credential modal###

    modal = null
    row = null

    if not has_permission "clarity:user_credentials_revoke"
        return

    $("tbody").on "click", ".revoke-credential", ->
        row = $(this).data("row")
        credential = datatable.row(row).data()

        markup = """
            <div class="row g-3">
                <div class="col-sm-6">
                    <label class="fw-bold">Credential</label>
                    <p class="mb-0">#{credential.credential_type_abbr}</p>
                </div>
                <div class="col-sm-6">
                    <label class="fw-bold">Credential number</label>
                    <p class="mb-0">#{credential.number}</p>
                </div>
                <div class="col-sm-6">
                    <label class="fw-bold">Status</label>
                    <p class="mb-0">#{credential.display_status}</p>
                </div>
                <div class="col-sm-6">
                    <label class="fw-bold">Expiration</label>
                    <p class="mb-0">#{credential.display_expiration}</p>
                </div>
            </div>
        """

        modal_el = $(".revoke-credential-modal")
        modal = new bootstrap.Modal(modal_el[0])
        modal.show();
        modal_el.find(".modal-body .revoke-credential-message").html(markup)
        select_options = ""
        for status in BL.Configuration['credentials.revoke_statuses'] or []
            select_options += "<option value=\"#{status}\">#{status}</option>"
        modal_el.find(".modal-body .revoke-credential-statuses").html(select_options)


    $(".revoke-credential-modal form").on "submit", (e) ->
        e.preventDefault()

        form = this
        credential_uuid = datatable.row(row).data().uuid
        credential_type_name = datatable.row(row).data().credential_type_name
        reason = form.reason.value
        status = form.status.value

        clarity.revoke_credential user_uuid, credential_uuid, status
            .done () ->
                clarus.candidate.add_note user_uuid,
                    "Staff revoked credential #{credential_type_name} \
                     (uuid: #{credential_uuid}). Reason: #{reason}"

                _reload_table(datatable, user_uuid)

                orb.flash_success "Credential #{credential_type_name} revoked."
            .fail (xhr) ->
                show_xhr_error xhr, "There was a problem revoking the credential"

        form.reset()
        modal = bootstrap.Modal.getInstance($(".revoke-credential-modal")[0]);
        modal.hide();


init = (datatable, options) ->
    user_uuid = options.args
    _reload_table datatable, user_uuid

    _setup_modals datatable, user_uuid

    if not has_permission "clarity:user_credentials_grant"
        return

    # FIXME: this makes a second call to get the user's credentials
    clarity.get_available_credential_types(user_uuid)
        .done (available_types) ->
            footer = $(render_add_credential(
                {
                    available_types: _.sortBy available_types, "name",
                    today: new moment().format("YYYY-MM-DD")
                }))
                .appendTo options.render_target

            $('[name="credential_type_id"]').select2
                dropdownParent: $('#myModal')
                placeholder: 'Select a credential type'
                allowClear: true

            modal = footer.find(".add-credential-modal")

            button = footer.find ".add-credential-action"
                .on "click", (e) ->
                    bs_modal = new bootstrap.Modal(modal[0]);
                    bs_modal.show();

            modal.find "form"
                .on "submit", (e) ->
                    form = this
                    field = form.credential_type_id
                    credential_type_id = field.value
                    credential_type_name = field.options[field.selectedIndex].text

                    clarus.candidate.add_note user_uuid,
                        "Staff initiating manual grant of credential \
                         #{credential_type_name}."

                    ignore_facts_before = form.ignore_facts_before.value
                    if ignore_facts_before
                        custom_data = {'ignore_facts_before': ignore_facts_before}

                    clarity.grant_credential(credential_type_id,
                                             user_uuid,
                                             "me",
                                             form.issued_on.value,
                                             form.effective_renewal_date.value,
                                             custom_data)
                        .done (credential) ->
                            _reload_table(datatable, user_uuid)

                            available_types = (type for type in available_types \
                                               when type.uuid != credential_type_id)

                            form.reset()

                            modal.find "option[value=#{credential_type_id}]"
                                .remove()

                            if available_types.length < 1
                                button.attr("disabled", "disabled")

                            note = "Granted #{credential.credential_type_name} \
                                    (#{credential.credential_type_abbr}) \
                                    credential number #{credential.number}, \
                                    issuance date of #{credential.issued_on}."

                            orb.flash_success note

                        .fail (xhr) ->
                            show_xhr_error xhr, "There was a problem granting the \
                                                 credential"

                    e.preventDefault()
                    bootstrap.Modal.getInstance(modal[0]).hide()

        .fail (xhr) ->
            show_xhr_error xhr, "There was a problem fetching the available \
                                 credentials"


get_config = (options) ->
    template_context: template_context
    table_options: table_options


module.exports =
    get_config: get_config
    init: init
