var Handlebars = require("../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <th>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"token") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":12,"column":23}}})) != null ? stack1 : "")
    + "            </th>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + container.escapeExpression(__default(require("../handlebars_helpers/lang.coffee")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"token") : depth0),(depth0 != null ? lookupProperty(depth0,"default_text") : depth0),{"name":"lang","hash":{},"data":data,"loc":{"start":{"line":9,"column":20},"end":{"line":9,"column":47}}}))
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "                    "
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"before_table") : depth0), depth0)) != null ? stack1 : "")
    + "\n\n<table class=\"table dynamic-table\">\n    <thead>\n        <tr>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"headings") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":14,"column":21}}})) != null ? stack1 : "")
    + "        </tr>\n    </thead>\n</table>\n\n"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"after_table") : depth0), depth0)) != null ? stack1 : "")
    + "\n";
},"useData":true});