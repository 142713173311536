var Handlebars = require("../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"header-logo\">\n    <a class=\"nav-link\" href=\"#\">\n        <img width=\"275px;\" src=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"agency_logo_path") : depth0), depth0))
    + "\"/>\n    </a>\n</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<nav class=\"navbar navbar-default\">\n    <div class=\"container-fluid\">\n        <div class=\"navbar-header\">\n            <button type=\"button\" class=\"navbar-toggle collapsed\"\n                    data-toggle=\"collapse\" data-target=\"#nav-menu\"\n                    aria-expanded=\"false\">\n                <span class=\"icon-bar\"></span>\n                <span class=\"icon-bar\"></span>\n                <span class=\"icon-bar\"></span>\n            </button>\n        </div>\n\n    <div class=\"collapse navbar-collapse\" id=\"#nav-menu\">\n        <ul class=\"nav navbar-nav\">\n            <li class=\"nav-link active\">\n                <a href=\"/app/\">New Candidates</a>\n            </li>\n\n            <li class=\"dropdown nav-link\">\n                <a role=\"button\"\n                   data-toggle=\"dropdown\" aria-haspopup=\"true\"\n                   aria-expanded=\"false\">\n                    "
    + alias2(__default(require("../handlebars_helpers/lang.coffee")).call(alias1,"C000007","Account",{"name":"lang","hash":{},"data":data,"loc":{"start":{"line":75,"column":20},"end":{"line":75,"column":48}}}))
    + "\n                    <span class=\"caret\"></span>\n                </a>\n\n                <ul class=\"dropdown-menu\" role=\"menu\"\n                    aria-labelledby=\"profile-dropdown-label\">\n                    <li role=\"presentation\" class=\"dropdown-header\">\n                        "
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"display_name") : depth0), depth0))
    + "\n                    </li>\n                    <li role=\"presentation\" class=\"divider\"></li>\n                    <li role=\"presentation\" class=\"nav-link\">\n                        <a role=\"menuitem\"\n                           data-bypass=\"true\"\n                           href=\"/logout\">"
    + alias2(__default(require("../handlebars_helpers/lang.coffee")).call(alias1,"C000009","Logout",{"name":"lang","hash":{},"data":data,"loc":{"start":{"line":88,"column":42},"end":{"line":88,"column":69}}}))
    + "</a>\n                    </li>\n                </ul>\n            </li>\n        </ul>\n    </div>\n  </div>\n</nav>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<style>\n    .header-logo {\n        background-color: rgb(248, 248, 248);\n        padding: 15px 30px;\n    }\n\n    #app-content,\n    #flash-alert {\n        margin: 0 15px;\n    }\n\n    .col-form-label {\n        text-align: right;\n        font-weight: 700;\n    }\n\n    .toggle-btn-md {\n        min-width: 3.7rem !important;\n        min-height: 2.15rem !important;\n    }\n\n    .toggle-btn-md .toggle-on, .toggle-btn-md .toggle-off {\n        margin-top: -0.1rem !important;\n    }\n\n    html {\n      font-size: 14px;\n    }\n\n    .btn.btn-default {\n        --bs-btn-color: #333;\n        --bs-btn-bg: #fff;\n        --bs-btn-border-color: #ccc;\n        --bs-btn-hover-border-color: #adadad;\n        --bs-btn-hover-bg: #e6e6e6;\n    }\n</style>\n\n\n"
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? lookupProperty(depth0,"show_header") : depth0), depth0),{"name":"show_header","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":0},"end":{"line":48,"column":16}}})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? lookupProperty(depth0,"show_top_nav") : depth0), depth0),{"name":"show_top_nav","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":0},"end":{"line":96,"column":17}}})) != null ? stack1 : "")
    + "\n\n<div id=\"flash-alert\"></div>\n\n\n<div id=\"app-content\"></div>\n\n<link href=\"https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css\" rel=\"stylesheet\" integrity=\"sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH\" crossorigin=\"anonymous\" />\n<script src=\"https://kit.fontawesome.com/210dfc1a59.js\" crossorigin=\"anonymous\"></script>\n<script src=\"https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js\" integrity=\"sha384-kenU1KFdBIe4zVF0s0G1M5b4hcpxyD9F7jL+jjXkk+Q2h455rYXK/7HAuoJl+0I4\" crossorigin=\"anonymous\"></script>\n\n<link href=\"https://cdn.jsdelivr.net/npm/bootstrap5-toggle@5.1.1/css/bootstrap5-toggle.min.css\" rel=\"stylesheet\">\n<script src=\"https://cdn.jsdelivr.net/npm/bootstrap5-toggle@5.1.1/js/bootstrap5-toggle.jquery.min.js\"></script>\n<link href=\"/static/css/branding.css\" rel=\"stylesheet\">\n";
},"useData":true});