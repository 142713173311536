template_cache = {}


_fetch = (template_type, template, client) ->
    key = [template_type, template].join("-")

    if key in template_cache
        template_cache[key]
    
    template_cache[key] = $.Deferred()

    client = client or NextGen.metadata.client_id.toLowerCase()
    domain = NextGen.metadata.s3_bucket_domain
    prefix = "https://s3.amazonaws.com/client-public-files#{domain}"

    if "." not in template
        template += ".mustache"

    url = "#{prefix}/#{client}/#{template_type}/#{template}"

    $.ajax
        url: url
        cache: false
        success: (data) ->
            template_cache[key].resolve data
        error: (err) ->
            template_cache[key].reject(err)

    template_cache[key]


fetch_navigation_template = (menu_type) ->
    ### Fetch a specific navigation template

    Returns a promise that will either be resolved by the current value cached
    in session storage or (on first load) the value retrieved from the s3
    bucket.  Even when returning the value from session storage, the s3 file
    is retrieved in the background and updated the session storage for use in
    the next render.
    ###

    promise = $.Deferred()

    key = "navigation:#{menu_type}"

    template = sessionStorage.getItem(key)

    _fetch("core_portal", "navigation/#{menu_type}.mustache")
    .done (result) ->
        template = result
        sessionStorage.setItem(key, template)
        if promise.state() == 'pending'
            promise.resolve(template)
    .fail () ->
        promise.reject()

    if template
        promise.resolve template

    promise



fetch_page_template = (page_name, require_authentication) ->
    ### Fetch the template for a dynamic page

    Given a page name, attempt to get the page template from the client public
    files custom directory. If that fails, attempt to get it from the 'default' directory

    If `require_authentication`, then the page will be requested from the 'pages' subdirectory;
    otherwise, it will be requested from 'anonymous_pages'.
    ###


    prefix = require_authentication and 'pages' or 'anonymous_pages'
    promise = $.Deferred()

    _fetch(prefix, page_name).then (result) ->
        promise.resolve result
    .fail () ->
        _fetch(prefix, page_name, 'default').then (result) ->
            promise.resolve result
        .fail () ->
            promise.reject()

    promise


fetch = (template_type, template) ->
    ### Fetch a template for a given review packet type.

    Given a template type ("credentials/default") and a template ("overview"),
    fetch the template and invoke promise callbacks with the template source.

    If the template name does not contain a ".", the assumed suffix ".mustache"
    will be appended to the request before fetching.

    Templates live in the client public files S3 bucket under the following
    scheme:

        <client>/<template_type>/<template>.mustache

    If template_type does not contain a "/" (e.g. its value is "application"),
    it is assumed to be for a review packet:

        <client>/review_packets/<template_type>/<template>.mustache

    The client public files bucket and the current client are determined via
    a call to Clarus. Four buckets are used:

        client-public-files-live.useclarus.com  (production environments)
        client-public-files.useclarus.com       (staging environments)
        client-public-files.clarus-qa.com       (qa environments)
        client-public-files.clarus-dev.com      (development environments)

    No template overriding is supported, so each client needs a copy of the
    templates for all the review packet types they use.

    Returns a promise object, which will be resolved with a single argument
    of the contents of the requested template. If fetching the template fails,
    the promise will be rejected (callers can use the promise's `.fail()`
    callback registration to check for this).

    ###

    if "/" not in template_type
        template_type = "review_packets/#{template_type}"

    _fetch template_type, template



module.exports =
    fetch: fetch
    fetch_page_template: fetch_page_template
    fetch_navigation_template: fetch_navigation_template
