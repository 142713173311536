var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"card border-0 bg-light-blue p-3\">\n    <div class=\"card-body\">\n        <div class=\"d-flex align-items-center mb-3\">\n            <div class=\"btn-group me-3\" role=\"group\" aria-label=\"Timeline Multiplier\">\n                <input type=\"radio\" class=\"btn-check\" name=\"multiplier\" id=\"extend-"
    + alias4(((helper = (helper = lookupProperty(helpers,"transition_name") || (depth0 != null ? lookupProperty(depth0,"transition_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"transition_name","hash":{},"data":data,"loc":{"start":{"line":5,"column":83},"end":{"line":5,"column":102}}}) : helper)))
    + "\" autocomplete=\"off\" checked value=\"1\">\n                <label class=\"btn btn-outline-primary btn-sm\" for=\"extend-"
    + alias4(((helper = (helper = lookupProperty(helpers,"transition_name") || (depth0 != null ? lookupProperty(depth0,"transition_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"transition_name","hash":{},"data":data,"loc":{"start":{"line":6,"column":74},"end":{"line":6,"column":93}}}) : helper)))
    + "\">Extend</label>\n\n                <input type=\"radio\" class=\"btn-check\" name=\"multiplier\" id=\"reduce-"
    + alias4(((helper = (helper = lookupProperty(helpers,"transition_name") || (depth0 != null ? lookupProperty(depth0,"transition_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"transition_name","hash":{},"data":data,"loc":{"start":{"line":8,"column":83},"end":{"line":8,"column":102}}}) : helper)))
    + "\" autocomplete=\"off\" value=\"-1\">\n                <label class=\"btn btn-outline-primary btn-sm\" for=\"reduce-"
    + alias4(((helper = (helper = lookupProperty(helpers,"transition_name") || (depth0 != null ? lookupProperty(depth0,"transition_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"transition_name","hash":{},"data":data,"loc":{"start":{"line":9,"column":74},"end":{"line":9,"column":93}}}) : helper)))
    + "\">Reduce</label>\n            </div>\n            <span class=\"me-2\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"status") || (depth0 != null ? lookupProperty(depth0,"status") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"status","hash":{},"data":data,"loc":{"start":{"line":11,"column":31},"end":{"line":11,"column":41}}}) : helper)))
    + " by</span>\n            <div class=\"input-group me-2\" style=\"max-width: 150px;\">\n                <input type=\"number\" name=\"extended_months\" class=\"form-control form-control-sm timeline-field\" value=\"0\" min=\"0\" step=\"1\">\n                <span class=\"input-group-text\">months</span>\n            </div>\n            <span class=\"me-2\">and</span>\n            <div class=\"input-group\" style=\"max-width: 150px;\">\n                <input type=\"number\" name=\"extended_days\" class=\"form-control form-control-sm timeline-field\" value=\"0\" min=\"0\" step=\"1\">\n                <span class=\"input-group-text\">days</span>\n            </div>\n        </div>\n\n        <div class=\"form-check mb-3\">\n            <input type=\"checkbox\" class=\"form-check-input\" name=\"cascade_changes\" id=\"cascadeChanges-"
    + alias4(((helper = (helper = lookupProperty(helpers,"transition_name") || (depth0 != null ? lookupProperty(depth0,"transition_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"transition_name","hash":{},"data":data,"loc":{"start":{"line":24,"column":102},"end":{"line":24,"column":121}}}) : helper)))
    + "\" checked>\n            <label class=\"form-check-label\" for=\"cascadeChanges-"
    + alias4(((helper = (helper = lookupProperty(helpers,"status") || (depth0 != null ? lookupProperty(depth0,"status") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"status","hash":{},"data":data,"loc":{"start":{"line":25,"column":64},"end":{"line":25,"column":74}}}) : helper)))
    + "\">\n                Adjust the following transitions by the same amount\n            </label>\n        </div>\n\n        <input type=\"hidden\" name=\"transition_name\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"transition_name") || (depth0 != null ? lookupProperty(depth0,"transition_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"transition_name","hash":{},"data":data,"loc":{"start":{"line":30,"column":59},"end":{"line":30,"column":78}}}) : helper)))
    + "\">\n    </div>\n</div>";
},"useData":true});