orb = require "orb"


template_context =
    headings: [
        "Review Packet Type",
        "Status",
        "Created",
        ""
    ]

    before_table: """
        <style>
            table.dataTable tbody td {
                vertical-align: middle;
            }
        </style>
    """

table_options =
    columns: [
        {data: "type"}
        {data: "status"}
        {data: "created"}
        {
            sortable: false
            searchable: false
            render: (dontcare, dontcare2, review_packet) ->
                "<button data-id='#{review_packet.id}' class='btn btn-primary btn-sm view-review-packet-btn'>View Details</button>"
        }
    ]
    order: [[ 2, "desc" ]]
    paging: false
    searching: false
    info: false
    autoWidth: false
    language: {
        emptyTable: "<em>This candidate currently has no review packets</em>"
    }


init = (datatable, options) ->
    candidate_id = options.args

    url = "#{NextGen.metadata.plugin_url("review_packets", "?entity_name=Applicant&entity_id=#{candidate_id}&for_list_view=true")}"

    $.ajax
        url: url
        success: (review_packets) ->
            datatable.rows.add(review_packets).draw()
        error: ->
            orb.flash_error "An error occurred when trying to fetch review packets."


get_config = (options) ->
    template_context: template_context
    table_options: table_options


module.exports =
    get_config: get_config
    init: init
