var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <option value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" data-requires-details=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"requires_additional_information") : depth0), depth0))
    + "\">\n                "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"description") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":24},"end":{"line":12,"column":68}}})) != null ? stack1 : "")
    + "\n            </option>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " ("
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"description") : depth0), depth0))
    + ")";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias2(__default(require("../../../handlebars_helpers/lang.coffee")).call(alias1,"L001385","",{"name":"lang","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":21}}}))
    + "\n\n<br/>\n\n<form id=\"special-accommodations-form\">\n    <div class=\"mb-3\">\n        <label for=\"accommodations-select\" class=\"form-label fw-bold\">"
    + alias2(__default(require("../../../handlebars_helpers/lang.coffee")).call(alias1,"L001225","Special accommodations",{"name":"lang","hash":{},"data":data,"loc":{"start":{"line":7,"column":70},"end":{"line":7,"column":113}}}))
    + "</label>\n        <select id=\"accommodations-select\" name=\"special-accommodations\" multiple=\"true\" size=\""
    + alias2(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"special_accommodations") : depth0)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + "\"\n            class=\"form-control\" required>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"special_accommodations") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":14,"column":17}}})) != null ? stack1 : "")
    + "        </select>\n    </div>\n    <div id=\"accommodations-details-div\">\n        <label class=\"form-label fw-bold\" for=\"accommodations-details\">\n            "
    + alias2(__default(require("../../../handlebars_helpers/lang.coffee")).call(alias1,"L001226","Additional details (required for some accommodations)",{"name":"lang","hash":{},"data":data,"loc":{"start":{"line":19,"column":12},"end":{"line":19,"column":86}}}))
    + "\n        </label>\n        <textarea id=\"accommodations-details\" rows=\"4\" class=\"form-control\"\n            name=\"special-accommodations-details\"\n            placeholder=\""
    + alias2(__default(require("../../../handlebars_helpers/lang.coffee")).call(alias1,"L001227","Add any details to be included as a note in this review packet",{"name":"lang","hash":{},"data":data,"loc":{"start":{"line":23,"column":25},"end":{"line":23,"column":108}}}))
    + "\"></textarea>\n    </div>\n</form>\n";
},"useData":true});