handlebars = require "handlebars/dist/handlebars"
templates = require "orb/templates"
dynamic_form = require "orb/dynamic_form"
require "orb/handlebars_helpers"


render_application = (review_packet, options={}) ->
    fetch_template = templates.fetch "application", "details"

    fetch_data = $.get
        url: review_packet.url() + "/application"
        dataType: "json"
        cache: false

    $.when(fetch_template, fetch_data).done (template, data_response) ->
        data = data_response[0]

        application_template = handlebars.compile(template)
        application_data =
            sections: dynamic_form.process_form(data)

        $("#rp-artifacts > .rp-section-container").html(
            application_template application_data
        )


module.exports = render_application
