orb = require "orb"
clarus = require "orb/system/clarus_client"

checklist_template = require "./checklist.mustache"


update_checklist_data = (review_packet, checklist_item) ->

    $('#rp-actions .action-btn').attr 'disabled', true
    $('#rp-checklist [type=checkbox]').bootstrapToggle 'disable'

    $.ajax
        url: review_packet.url() + "/checklist"
        method: "post"
        data:
            key: checklist_item.key
            state: checklist_item.state
        error: ->
            orb.flash_error BL.Language.get("L001374", "An error occurred when trying update the checklist.")
        complete: (jqXHR, textStatus) ->
            $('#rp-actions .action-btn').attr 'disabled', false
            $('#rp-checklist [type=checkbox]').bootstrapToggle 'enable'


handle_checklist_change = (target, review_packet) ->
    checklist_key = target.name

    checklist_item = {
        key: checklist_key,
        state: target.checked
    }

    update_checklist_data(review_packet, checklist_item)


generate_checklist = (review_packet) ->
    options = review_packet.get("options")
    configured_checklist = options.checklist?.checklist_items

    if configured_checklist
        checklist_items = []
        checked_items = review_packet.find_checked_items()

        for item in configured_checklist
            item = {
                key: item.key,
                label: item.label,
            }

            item.state = item.key in checked_items

            checklist_items.push(item)

        return checklist_items


render_checklist = (review_packet) ->
    if not review_packet.feature_visible "checklist"
        return

    checklist_items = generate_checklist(review_packet)

    $("#rp-checklist .rp-section-container").html(
      checklist_template(checklist_items: checklist_items))

    $(".rp-checklist-toggle").bootstrapToggle({
        onlabel: BL.Language.get('L001158', "Done"),
        offlabel: BL.Language.get('L001157', "To do"),
        onstyle: "success",
        offstyle: "secondary",
    }).on 'change', () ->
        handle_checklist_change(this, review_packet)


ChecklistException = (message) ->
   this.message = message
   this.name = "ChecklistException"


reset_checklist_validation = () ->
    ### Reset any checklist validation errors ###

    orb.hide_flash()
    $("#rp-checklist label").removeClass("text-danger fw-bold")


validate_configs = (all_items, required_items, action) ->
    ### Validate review packet checklist requirements

    Throws a `ChecklistException` if a required checklist item does
    not exist in the checklist configurations.

    ###

    invalid_items = required_items.filter (item) ->
        item != '*' and !all_items.includes(item)

    if not invalid_items.length
        return

    default_text = "The following checklist items are required for the " +
                   "\"{action_label}\" action, but are not found in the " +
                   "review packet configurations: {invalid_items}"

    message = BL.Language.get("L001271", default_text).format
        action_label: action.label,
        invalid_items: invalid_items.join(', ')

    throw new ChecklistException(message)


validate_items = (all_items, required_items, action, checklist) ->
    ### Validate review packet checklist items

    Throws a `ChecklistException` if a required checklist item is
    not marked complete.

    ###

    if "*" in required_items
        required_items = all_items

    $items = $(".rp-checklist-toggle:checked")
    completed_items = $items.get().map (item) -> item.name
    missing_items = required_items.filter (item) ->
        !completed_items.includes(item)

    if not missing_items.length
        return

    missing_items.map (item) ->
        $("[name=#{item}]").parents(".rp-checklist-item")
                           .find("label")
                           .addClass("text-danger fw-bold")

    missing_item_labels = checklist.filter (item) -> missing_items.includes(item.key)
        .map (item) -> item.label

    default_text = "The following checklist items must be marked complete " +
                   "before the \"{action_label}\" action: {missing_item_labels}"

    message = BL.Language.get("L001272", default_text).format
        action_label: action.label,
        missing_item_labels: missing_item_labels.join(', ')

    throw new ChecklistException(message)


validate_checklist = (review_packet, action_name) ->
    ### Validate review packet checklist items

    Validates that checklist items listed in an action's checklist
    requirements 1) are valid checklist items and 2) are marked
    complete.

    If the action's checklist requirements are invalid or the
    items are not complete, a `ChecklistException` is thrown
    with details about the error.

    ###

    reset_checklist_validation()

    action = review_packet.get("actions")[action_name]
    required_items = action.checklist_requirements

    if not required_items
        return

    checklist = review_packet.get("options").checklist?.checklist_items

    if checklist
        all_items = checklist.map (item) -> item.key
    else
        all_items = []

    validate_configs all_items, required_items, action
    validate_items all_items, required_items, action, checklist


module.exports =
    render_checklist: render_checklist
    validate_checklist: validate_checklist
