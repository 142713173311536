Backbone = require "backbone"
_ = require "underscore"

orb = require "orb"
render_photo_widget = require "./photo_verification_content.mustache"
clarus = require "orb/system/clarus_client"
confirm = require "orb/confirm_modal"

require "./photo_verification.css"


PhotoVerificationItem = Backbone.Model.extend
    ### Manage the photo verification item's state

    Updates the status of the verification photo using Clarus'
    `update_verification` endpoint and updates its attributes
    to be displayed by the photo verification widget view.

    ###

    defaults: 
        id: ""
        status: ""
        document_id: ""
        error_message: ""
        review_packet: null

    update_photo_status: (photo_status) ->
        photo = this
        photo_id = this.get "id"

        this.set "error_message", ""

        if photo_status != photo.get("status") 
            clarus.photo_verification.update_status photo_id, photo_status
                .done ->
                    photo.handle_status_update_success(photo_status)

                .fail ->
                    photo.handle_status_update_fail()

    handle_status_update_success: (photo_status) ->
        note = "The photo status was updated to {0}".format photo_status
        packet = this.get "review_packet"

        this.set "status", photo_status
        packet.add_note packet, note, is_internal=true
        orb.flash_success "The photo status was successfully updated to {0}".format photo_status

    handle_status_update_fail: ->
        this.set "error_message", "There was a problem updating the photo status."


PhotoVerificationWidget = Backbone.View.extend
    # Render content and handle events for the photo verification widget

    initialize: ->
        _.bindAll(this, "render", "accept", "reject")
        this.model.bind("change", this.render)
        this.render()

    events:
        "click .accept-photo-btn": "accept"
        "click .reject-photo-btn": "reject"

    render: ->
        this.$el.html(render_photo_widget(this.model.attributes))
        return this

    accept: ->
        this.model.update_photo_status "Accepted"

    reject: ->
        photo = this.model
        status = photo.attributes.status
        if status != "Rejected"
            title = BL.Language.get("L001216", "Reject photo")
            content = BL.Language.get("L001217", "Are you sure you want to reject this photo?")
            confirm_btn_label = BL.Language.get("L001218", "Reject photo")

            confirm
                title: "<b>{0}</b>".format(title)
                modal_selector: "span#confirm-modal"
                content: content
                confirm_btn_class: "btn-warning"
                confirm_btn_label: confirm_btn_label
                ok_callback: ->
                    photo.update_photo_status "Rejected"


_construct_widget = (photo_data, review_packet) ->
    photo = new PhotoVerificationItem()
    photo.set(photo_data)
    photo.set(review_packet: review_packet)

    widget = new PhotoVerificationWidget(
        model: photo
        el: $(".photo-verification-widget")
    )


render_photo_verification_widget = (review_packet) ->
    has_widget = $(".photo-verification-widget").length
    can_verify_photo = review_packet.can_verify_photo()
    photo_data = review_packet.attributes.candidate.verification_item_photo

    if has_widget && can_verify_photo && photo_data
        _construct_widget photo_data, review_packet


module.exports =
    render_photo_verification_widget: render_photo_verification_widget
