flash_template = require "./flash_body.mustache"


hide_popovers = ->
    #$(".btn-popover").popover("hide")
    # popover no longer available in bootstrap 5
    return


# Show an informative status message ("flash") to the user.
#
# Display a message to the user informing them of the result of some action
# or an error condition.
#
# If alertClass is specified, it should be one of the Bootstrap alert classes,
# such as "alert-info" (the default) or "alert-danger".
#
# hide_after specifies the time to show the alert in milliseconds, after which
# the alert is automatically hidden. To disable automatically hididng the
# alert, pass 0 or null for this value.
#
# If no flash-alert div has been installed, this function will insert a new
# container element at the start of the body.
#
flash = (message, alertClass="alert-info", hide_after=3000) ->
    hide_popovers()

    if not document.getElementById "flash-alert"
        $("<div id='flash-alert'></div>").prependTo "body"


    $("#flash-alert > div:first").removeClass((index, classes) ->
        matches = classes.match(/alert-[^ ]+/g) || []
        return matches.join(" ")
    )

    $("#flash-alert").replaceWith(flash_template(message: message))
    $("#flash-alert > div:first").addClass(["show", alertClass].join(" ")).slideDown()

    if hide_after
        setTimeout hide_flash, hide_after


# helper to flash an error message to the user
flash_error = (message, hide_after=0) ->
    if (message && typeof message != "string")
        message = message.message || message.msg || message.statusText
    else
        message = message || "There was a problem processing your request. " +
        "Please try again in a few moments or contact support for assistance."

    flash message, "alert-danger", hide_after


flash_info = (message, hide_after=3000) ->
    flash message, "alert-info", hide_after


flash_success = (message, hide_after=3000) ->
    flash message, "alert-success", hide_after


hide_flash = ->
    $("#flash-alert > div:first").removeClass("in").slideUp()


module.exports =
    flash: flash
    flash_error: flash_error
    flash_info: flash_info
    flash_success: flash_success
    hide_flash: hide_flash
