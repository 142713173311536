api_gateway_client = require('orb/aws/api_gateway_client')


render_to_pdf = (html, download_name=null) ->
    ### Render an HTML document to PDF.

    Given an HTML document, render the document as a PDF, store it as a
    Blob, and request that the user's browser download it. download_name
    is the preferred filename for the download. If download_name is not
    provided the PDF will open in the current browser window.

    Returns a Promise, resolved after invoking the download.

    ###

    # We have two PDF generation services: the new service lives in a
    # shared AWS account and authorizes via Bearer tokens generated by AWS
    # Cognito. The old service authorizes via temporary IAM credentials
    # generated by Clarity Credentials.
    #
    # This function will first try the new service by attempting to fetch a
    # Cognito token for the user from Clarus and passing the token to the
    # PDF generator in the shared services AWS account.
    #
    # If fetching the token fails, fall back to the IAM authenticated PDF
    # generator hosted in ClientOps, using temporary IAM credentials
    # provided by Clarity Credentials.

    _do_pdf_download = (result) ->
        new Promise (resolve) ->
            link = document.createElement("a")
            link.href = window.URL.createObjectURL(result.data)

            if download_name
                link.download = download_name

            # bypass the Backbone router on full NextGen pages
            link.setAttribute("data-bypass", "true")

            document.body.appendChild link
            link.click()
            document.body.removeChild link

            resolve()

    _cognito_render = (token_response) ->
        host = NextGen.metadata.services_host

        url = "https://#{host}.clarus-servic.es/services/pdfgen"

        fetch url,
            method: "POST"
            headers:
                Authorization: "Bearer #{token_response.access_token}"
                "Content-Type": "application/json"
                "Accept": "application/pdf"
            body: JSON.stringify({"html": html})
        .then (result) -> result.blob()
        .then (blob) ->
            _do_pdf_download
                data: blob

    # TODO: remove the fallback handler and the PDF service in ClientOps once
    # everyone has converted to the new endpoint.
    _fallback_render = ->
        production = NextGen.metadata.environment=="production"
        api_gw_url = if production then "https://services.clarus-live.com" else "https://services.clarus-stage.com"

        api_gateway_client.execute(
            url=api_gw_url
            path="/pdfgen"
            method="POST"
            data={html: html}
            defaultAcceptType="application/pdf"
            responseType="blob"
        ).then _do_pdf_download

    fetch("/api/v2/auth/token")
        .then (response) -> response.json()
        .then _cognito_render
        .catch _fallback_render


window.make_iframes_printable = () ->
    ### This function copies the content (sans js) of available iframes into
    newly created, temporary divs. It should be cleaned up by the
    `cleanup_printable_iframes` function.

    This is made to be a global function so that we can work around a small issue
    Firefox has with printing. The rendering is slightly off if we let
    window.onbeforeprint call this function. By making it globally available,
    the user can call this function before starting a print sequence.
    ###

    # By stopping early, we allow the option to bind this function to
    # window.onbeforeprint by default, while keeping the option open of calling
    # it manually in order to prevent timing based printing issues.
    if $('.printable-iframe').length > 0
        return

    make_printable = (iframe) ->
        children = $(iframe).contents().find('body').children(':not(script)').clone()
        printable = $('<div>', {class: 'printable-iframe'})
        # Adding a margin to form-groups fixes printing on Firefox.
        # Otherwise, printing the page could result in text from different form-groups being merged into the same row.
        children.find('.bl-form-group').css({'margin-top': '1px'})
        # Prevent clipping of floating input labels and their contents
        children.find('input').css({'margin-top': '15px'})

        $(printable).append(children)
        $(iframe).before(printable)
        $(iframe).hide()

    make_printable iframe for iframe in $('iframe')


cleanup_printable_iframes = () ->
    $('.printable-iframe').remove();
    $("iframe").show();


module.exports =
    render_to_pdf: render_to_pdf
    make_iframes_printable: window.make_iframes_printable
    cleanup_printable_iframes: cleanup_printable_iframes
