_ = require "underscore"
require "chosen-js"
require "chosen-js/chosen.css"

confirm = require "orb/confirm_modal"
orb = require "orb"
validate_checklist = require("../checklist/checklist").validate_checklist

actions_core = require "orb/review_packet/core"
actions_template = require "./actions_template.mustache"
reviewer_template = require "./reviewer_template.mustache"

default_actions = actions_core.default_actions


update_status = (review_packet, action, status, confirm_btn_class="btn-default") ->
    ### Update the status of a review packet after confirmation.

    Show the user a confirmation dialog, asking them to confirm a requested
    change of status on a review packet.

    If the change is confirmed, invokes

        review_packet.update_status status

    to set the new status on the review packet model.

    ###

    content = "Are you sure you want to #{action} this review packet?"

    confirm
        modal_id: "rp-#{action}-confirm-modal"
        content: content
        confirm_btn_class: "#{confirm_btn_class} status-change-confirm-btn"
        confirm_btn_label: action.charAt(0).toUpperCase() + action.slice(1)
        ok_callback: ->
            review_packet.update_status status


assign_reviewers = (review_packet) ->
    ### Assign reviewers to a review packet.

    Show a modal allowing users to assign reviewers to a review packet.
    The select is prepopulated with any existing reviewer selections, and
    the user can add, remove, or clear the list from the modal.

    On confirmation, calls `review_packet.set("reviewers", selections)`,
    which will notify any `change:reviewers` listeners on the review
    packet model.

    Fetching and saving review packet reviewers requires the ACL to
    grant the 'edit' review packet permission to the user.

    ###

    modal = confirm
        title: BL.Language.get("L001366", "Assign Reviewers")
        content: "<span class='reviewers-select'>" + BL.Language.get("L001367", "Loading reviewers…") + "</span>"
        confirm_btn_class: "btn-primary"
        confirm_btn_label: BL.Language.get("L001368", "Assign Reviewers")
        dismiss_btn_label: BL.Language.get("L001369", "Cancel")
        ok_callback: ->
            reviewers = modal.find("select").val()
            review_packet.set("reviewers", reviewers)

    $.ajax
        url: review_packet.url() + "/reviewers"
    .done (data) ->
        modal.find(".reviewers-select")
            .replaceWith reviewer_template(data.available_reviewers)

        modal.find("select").val(data.reviewers).chosen({width: "100%"})


do_render = (review_packet) ->
    ### Render the current set of available actions for review packet.

    The default workflow provides the ability to approve, deny, or withdraw
    review packets when they are pending_review. These actions simply set
    the review packet's status to approved, denied, or withdrawn, respectively;
    no change is propagated to the affected entities, although custom code is
    free to hook into the review packet status change events to effect other
    changes when a review packet is updated.

    options, if provided, may include

        actions {Object}            the action workflow. Defaults to
                                    default_actions defined above

        additional_actions {Object} action definitions to be grafted into the
                                    workflow. Keys present here override keys
                                    in the actions/default_actions

    Each action object should include:

        name {String}               the action's name, an ID used internally
        action {String|Function}    the target action (see below)
        label {String}              the label to show on the action's button
        label_token {String}        the language token to show on the action's
                                    button; if specified, `label` is used as the
                                    default text
        confirm_btn_class {String}  the CSS class used on the assent button in the
                                    confirmation modal for this action
        available_when              [list of] status(es) for which this action
            {String|Array}          should be shown

    The target action can be either a string or a function. If it is a string,
    it is assumed to be the target status to set the review packet to. The user
    is presented with a confirmation dialog; after confirmation, the review packet
    is updated to the given status.

    If the target action is a function, that function is invoked with the review
    packet model as its only argument. No confirmation is shown.

    ###

    actions = review_packet.get("actions")
    custom_action_functions = review_packet.get("custom_action_functions")

    valid_actions = (action for action in _.values actions \
                     when review_packet.action_visible action)

    $("#rp-actions").html(actions_template
        actions: valid_actions
    )

    $(".action-btn").on "click", ->
        action_name = $(this).data "name"
        action = actions[action_name].action

        try
            validate_checklist(review_packet, action_name)
        catch e
            orb.flash_error e.message
            return

        if action_name of custom_action_functions
            custom_action_functions[action_name](review_packet)
        else if _.isFunction action
            action(review_packet)
        else
            btn_class = actions[action_name].confirm_btn_class or "btn-default"
            update_status review_packet, action_name, action, btn_class


module.exports =
    render: do_render
    default_actions: default_actions
    assign_reviewers: assign_reviewers
