"""Hybrid Candidate dashboard

Augmented-reality hybrid (NextGen + Genshi) Candidate dashboard
For Great Justice!

* dynamic view: top section of the page shows candidate data from an
  S3-hosted Handlebars template

* active credentials: middle section shows credential cards for the
  candidate's active credentials (also S3-hosted)

* notification area + welcome text (from Genshi)

"""

_ = require "underscore"

clarity = require "orb/system/clarity_client"
templates = require "orb/templates"
credentials_helper = require "orb/credentials/index.coffee"


_fetch_data = (user_uuid) ->
    """Fetch the user's data for the S3 template.

    Grab the user's data to use as the context for the Handlebars template.
    The Clarus dynamic views plugin overrides `/dashboard` with a
    dual-view endpoint: specifying `Accept: application/json` nets you the
    data; other `Accept` values produce the standard HTML page.

    Only the user data call is required to succeed: if it fails, the returned
    promise will be rejected.

    Other retrieved information includes user-visible review packets,
    credential applications, and credentials. If any of these calls fails,
    the corresponding entries in the data will be set to null.

    Returns a promise object that, on success, will be resolved with the
    user's data:

        {
            "user": {...},
            "exam_applications": [...],
            "review_packets": [...],
            "credential_applications": [...],
            "credentials": [...],
            "credential_scorecards": [...],
        }

    """

    deferred = $.Deferred()
    data =
        review_packets: null
        credential_applications: null
        credentials: null
        credential_scorecards: null
        user_facts: null

    get_user_data = $.ajax(
        url: "/dashboard/data",
        headers: { Accept: "application/json" }
    ).done (user_data) ->
        $.extend(data, user_data)
    .fail (xhr, status, error) ->
        console.error("Could not fetch user's data!", status, error)
        deferred.rejectWith(xhr, status, error)

    # Fetch Review Packets that's associated with logged in candidate
    # from the API (view permission)
    user_id = NextGen.metadata.user.id.replace("#{NextGen.metadata.client_id}-", "")

    if NextGen.metadata.plugin_enabled('review_packets')
        get_review_packets = $.ajax(
            url: NextGen.metadata.plugin_url('review_packets'),
            data: {
                entity_name: 'Applicant',
                entity_id: user_id
            }
        ).done (review_packets) ->
            data.review_packets = review_packets

    if NextGen.metadata.plugin_enabled('credential_interface')
        get_credential_applications = clarity.get_applications(user_uuid)
            .done (credential_applications) ->
                data.credential_applications = credential_applications

        get_credentials =
            clarity.get_credentials(user_uuid, "display_status,display_expiration")
                .done (credentials) ->
                    data.credentials = credentials

        credential_requirements_request = clarity.get_credential_requirements()
        available_types_request = clarity.get_credential_types()
        facts_request = clarity.get_user_facts(user_uuid, ['requirement_slugs'])
        scorecard_request = clarity.get_scorecards(user_uuid, 'type')
        get_processed_credentials = $.when(
            credential_requirements_request,
            available_types_request,
            scorecard_request,
            facts_request,
        ).done (credential_requirements, credential_types, scorecards, facts) ->
            valid_facts = _.uniq _.flatten _.pluck facts[0], 'requirement_slugs'
            data.user_facts = facts[0]
            data.credential_scorecards = credentials_helper.process_credential_type_data(
                credential_types[0],
                credential_requirements[0],
                scorecards[0],
                valid_facts,
                selected=true,
            )

    $.when(
        get_user_data,
        get_review_packets,
        get_credential_applications,
        get_credentials,
        get_processed_credentials
    ).always ->
        deferred.resolve(data)

    deferred


render_dashboard = (template_source, data) ->
    try
        card = NextGen.components.handlebars.compile(template_source)

        data.toString = ->
            JSON.stringify this, null, 4

        $('#dynamic-dashboard-card').html(card(data))
    catch e
        console.error("Template error:", e)

    $(".credential-card").on "click", ".withdraw-btn", (e) ->
        target = $(e.currentTarget)
        target.prev().addClass('disabled')
        user = NextGen.metadata.user.uuid
        application_uuid = target.data("applicationUuid")
        updates = status: "withdrawn"

        clarity.update_application(user, application_uuid, updates)
            .done ->
                window.location = "/dashboard"
            .fail ->
                target.prev().removeClass('disabled')


show_dashboard = ->
    user_uuid = NextGen.metadata.user.uuid

    get_template = templates.fetch("dynamic_views/portal", "dashboard")
        .fail ->
            domain = NextGen.metadata.s3_bucket_domain
            client = BL.Configuration.client_id.toLowerCase()

            console.warn """
            No profile template found

            Add one in s3://client-public-files#{domain}/#{client}/dynamic_views/portal/dashboard.mustache

            Make sure to add public-read to the template’s ACL.
            """

    get_data = _fetch_data(user_uuid)

    $.when(get_template, get_data).done (template, data) ->
        NextGen.setup.update_metadata().then () ->
            data['_metadata'] = NextGen.metadata
            render_dashboard template, data
            if BL.Configuration['credentials.show_credential_scorecards_on_dashboard']
                NextGen.credentials.show_selected_credentials()


module.exports = show_dashboard
