

bool_icon_template = (value) ->
    color = if value then "success" else "danger"
    icon = if value then "ok" else "remove"

    "<i class='text-#{color} glyphicon glyphicon-#{icon}'></i>"


module.exports =
    bool_icon_template: bool_icon_template
