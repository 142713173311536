pht = require('./publish_history_table')
ace_editor = require('orb/components/ace')

show_version_details = (version_data) ->
    form = $('#publish-version-details')
    form.find('[data-name="name"]').text version_data.name
    form.find('[data-name="created"]').text pht.render_date version_data.created
    form.find('[data-name="user_name"]').text version_data.user_name
    form.find('[data-name="reason"]').text version_data.reason

    ace_editor.enable_ace()
        .then ->
            editor = ace.edit 'ace-publish-version-configuration'
            editor.setReadOnly true
            editor.setValue version_data.configuration, -1
            editor.clearSelection

version_selected = (event, table, type, indexes) ->
    selected_rows = table.rows( indexes ).data()
    selected_data = selected_rows[0]

    if selected_data.name == 'Legacy'
        return

    show_version_details selected_data
    $('#publish-version-details').removeClass 'd-none'

version_deselected = (event, table, type, indexes) ->
    $('#publish-version-details').addClass 'd-none'

setup = (credential_type) ->
    table = $('#history-tab .publishing-table')

    NextGen.components.clarity.get_credential_type(
        credential_type,
        false,
        {expand: 'publish_history'}
    ).then (credential_type_info) ->

        pht.init_publish_table table, credential_type_info.publish_history, {
            select: true,
            select_handler: version_selected,
            deselect_handler: version_deselected,
            scrollY: 'max(calc(100vh - 630px), 200px)',
        }

    # Without this, if a user resizes the editor frame while focused on
    # a different tab, and then goes to the history tab, the headers would
    # be visually offset from their columns.
    $('#history-tab-button').on 'shown.bs.tab', () ->
        table.DataTable().columns.adjust()


module.exports =
    "setup": setup
