var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <span class=\"text-success\">\n        ("
    + container.escapeExpression(__default(require("../../handlebars_helpers/lang.coffee")).call(depth0 != null ? depth0 : (container.nullContext || {}),"L001364","Internal",{"name":"lang","hash":{},"data":data,"loc":{"start":{"line":3,"column":9},"end":{"line":3,"column":38}}}))
    + ")\n    </span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "    <span class=\"text-warning\">\n        ("
    + container.escapeExpression(__default(require("../../handlebars_helpers/lang.coffee")).call(depth0 != null ? depth0 : (container.nullContext || {}),"L001365","Public",{"name":"lang","hash":{},"data":data,"loc":{"start":{"line":9,"column":9},"end":{"line":9,"column":36}}}))
    + ")\n    </span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = alias2.call(depth0,alias1((depth0 != null ? lookupProperty(depth0,"internal") : depth0), depth0),{"name":"internal","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":5,"column":13}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? lookupProperty(depth0,"show_public_label") : depth0), depth0),{"name":"show_public_label","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":0},"end":{"line":11,"column":22}}})) != null ? stack1 : "")
    + "\n"
    + container.escapeExpression(alias1((depth0 != null ? lookupProperty(depth0,"note") : depth0), depth0))
    + "\n";
},"useData":true});