require "bootstrap-datepicker"
require "bootstrap-datepicker/dist/css/bootstrap-datepicker3.css"
require "datatables.net-bs5"
require "datatables.net-rowgroup-bs5"
require "datatables.net-bs5/css/dataTables.bootstrap5.css"
table_template = require "./table_structure.mustache"


render = (options) ->
    type = require "./types/#{options.table_type}.coffee"
    type_config = type.get_config options

    el = $(options.render_target)
        .html table_template(type_config.template_context)
        .find ".dynamic-table"

    # Log datatables errors to console instead of alerting
    $.fn.dataTable.ext.errMode = 'throw';

    datatable = el.DataTable type_config.table_options

    type.init?(datatable, options)


module.exports =
    render: render
