_get_dynamic_form = (page_name, parameters, refresh_page) ->
    iframe = $('#dynamic_form_iframe')[0]
    form_name = "pages:#{page_name}".replace '/', ':'
    $.ajax(
        method: 'POST'
        url: '/dynamic_form/standalone_for_api'
        data:
            form_name: form_name
            entity_data: JSON.stringify parameters
            action: "/dynamic_form/translate?form_name=#{form_name}")
    .then (content) ->
        iframe.contentWindow.document.open()
        iframe.contentWindow.document.write content
        iframe.contentWindow.document.close()

        height = iframe.contentWindow.document.scrollingElement.scrollHeight
        $('#form').css('height', '').css('display', 'none').addClass('form-border')
        $('#options').removeClass('d-none')
        iframe.height = height

        iframe.onload = () ->
            iframe.contentWindow.$('#dynamic-form').on("save-success", (e, data) ->
                e.preventDefault()
                $('#close').click()
                delete(data['tg_flash'])
                parameters = data
                refresh_page(parameters)
                $(iframe.contentWindow.document).find('[type=submit]')[0].disabled = false
            )

            $(iframe.contentWindow.document).find('[type=submit]')[0].value = BL.Language.get("L001408", "Search")

            # make sure that the form iframe expands to display any additional content that shows
            lastHeight = height
            curHeight = 0
            setInterval( () ->
                curHeight = iframe.contentWindow.document.scrollingElement.scrollHeight
                if ( curHeight != lastHeight )
                  $(iframe).css('height', (lastHeight = curHeight) + 'px' );
              , 250);


load_form = (page_name, parameters, refresh_page) ->
    form = _get_dynamic_form page_name, parameters, refresh_page
    $('#menu').removeClass('d-none')

    $('#options').on('click', () ->
        $('#options').toggleClass('d-none')
        $('#close').toggleClass('d-none')
        $('#page-content').animate(
            {'opacity': '25%'}, 'fast', () ->
                $('#form').animate(
                    {'height': ['toggle', 'linear']}, 'fast'
                )
        )
    )

    $('#close').on('click', () ->
        $('#close').toggleClass('d-none')
        $('#options').toggleClass('d-none')
        $('#form').animate(
            {'height': ['toggle', 'linear']},
             'fast',
             ()-> $('#page-content').animate(
                {'opacity': '100%'}, 'fast')
        )
    )


module.exports = 
	load_form: load_form

