_ = require "underscore"
require "bootstrap-fileinput"
require "bootstrap-fileinput/css/fileinput.css"
require "datatables.net"
require "datatables.net-bs5"
require "datatables.net-bs5/css/dataTables.bootstrap5.css"

orb = require "orb"

documents_template = require "./documents.mustache"
document_type_selector = require "./document_type_selector.mustache"


documents_table = null


_render_template = (show_uploads=true, review_packet_id) ->
    $("#rp-documents .rp-section-container").html(
        documents_template(
            show_uploads: show_uploads,
            review_packet_id: review_packet_id
        )
    )


_render_table = (review_packet) ->
    options = review_packet.get("options")
    document_types = options.documents?.document_types

    columndefs = [
        {
            targets: 1
            data: "filename"
            render: (filename, type, data, meta) ->
                url = review_packet.url() + "/documents/#{data.key}"

                "<a href=\"#{url}\">#{filename}</a>"
        }
        {
            targets: 2
            data: "document_type"
            visible: document_types  # don't show this column if no document_types
            orderable: false
            render: (selected_value, type, data, meta) ->
                # don't render anything if document_types are disabled
                if !document_types
                    return ""

                document_type_options = for value, label of document_types
                    value: value
                    label: label
                    selected: value == selected_value

                document_type_selector
                    key: data.key
                    no_selection: not selected_value
                    document_types: document_type_options
        }
    ]

    documents_table = $("#rp-documents-table").DataTable({
        columns: [
            {data: "uploaded_at", className: "dt-body-left dt-head-left"}
            {data: "filename"}
        ]
        columnDefs: columndefs
        order: [[ 0, "desc" ]]
        paging: false
        searching: false
        info: false
        autoWidth: false
        language: {
            emptyTable: BL.Language.get('L001405', 'There are no documents yet on this review packet.')
            loadingRecords: BL.Language.get("L001308", "Loading...")
        }
    })

    documents = review_packet.get("_custom_data")?.files or []
    documents_table.rows.add(documents).draw()

    if document_types
        $("#rp-documents-table tbody").on "change", "select.document-type", ->
            control = $(this)
            key = control.attr("name")

            $.ajax
                url: review_packet.url() + "/documents/#{key}"
                method: "patch"
                contentType: "application/json"
                data: JSON.stringify
                    document_type: control.val()

                # TODO: handle errors


_render_fileinput = (review_packet_url, show_uploads=true) ->
    if not show_uploads
        return

    upload_control = $("#rp-documents-file")

    upload_control.fileinput
        uploadUrl: review_packet_url + "/documents"
        uploadAsync: false
        allowedPreviewTypes: null
        layoutTemplates:
            actions: ""
            preview: '<div class="file-preview {class}"> \
                         {close} \
                         <div class="{dropClass}"> \
                         <div class="clearfix"></div> \
                         <div class="file-preview-status text-center text-success"></div> \
                         <div class="kv-fileinput-error"></div> \
                         </div> \
                     </div>'
        showClose: false
        showRemove: false
        showUpload: false
        # TODO: allow this to be set in the rp type configuration
        # allowedFileExtensions: ["pdf"]

    upload_control.on "filebatchselected", (event, files) ->
        $(this).fileinput("upload")

    upload_control.on "filebatchuploadsuccess", (event, data) ->
        documents_table.rows.add(data.response.data).draw()
        $(this).fileinput("reset").fileinput("clear")


_remove_upload_control = ->
    $("#rp-documents-file").fileinput("destroy")
    $(".rp-documents-upload-control").remove()


render_documents = (review_packet) ->
    ### Render the document section if enabled.

    Render the document section of the review packet. If the review packet type
    is configured to have documents disabled (see above), this function does
    nothing.

    Repeated calls to this function for the same review packet will not
    rerender the documents, as the documents data table is responsible for managing
    its own data. However if the review packet's status is changed such that the
    upload controls should not be shown, calling this function will trigger the
    removal of the upload control if it is visible.

    If navigation to a different review packet has occurred, the documents
    table will be destroyed and rerendered.

    ###

    if not review_packet.feature_visible "documents"
        return

    previous_rp_id = $("#rp-documents-table").data("rp-id")
    is_different_rp = previous_rp_id != review_packet.id

    show_uploads = review_packet.any_valid_action() or
        review_packet.any_actions_available_in_lifecycle()

    if documents_table and is_different_rp
        documents_table.destroy()

    if not documents_table or is_different_rp
        _render_template show_uploads, review_packet.id
        _render_table review_packet
        _render_fileinput review_packet.url(), show_uploads
    else if not show_uploads
        _remove_upload_control()


module.exports =
    render_documents: render_documents
