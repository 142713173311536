var Handlebars = require("../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"alert alert-danger security-alert mb-4\" role=\"alert\">\n    <div class=\"d-flex\">\n        <div class=\"flex-shrink-0\">\n            <img src=\"/blcore/static/icons/48x48/trainee/Signal attention.png\"\n                 class=\"img-fluid\"\n                 alt=\"Warning\" />\n        </div>\n        <div class=\"flex-grow-1 ms-3\">\n            <h4 class=\"mt-0 fw-bold\">Insecure Review Packet Type</h4>\n\n            <p>This review packet type does not have a permission model\n            defined. This presents a security hazard as it could allow\n            users to take unauthorized actions, including viewing\n            other people's review packets or approving their own.</p>\n\n            <p>Contact the BrightLink Success Team if you need help resolving\n            this message.</p>\n\n"
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? lookupProperty(depth0,"allow_snoozing") : depth0), depth0),{"name":"allow_snoozing","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":12},"end":{"line":30,"column":31}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? lookupProperty(depth0,"production_environment") : depth0), depth0),{"name":"production_environment","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":12},"end":{"line":34,"column":39}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p>Snooze this warning for all review packets and all users for:</p>\n\n"
    + ((stack1 = container.hooks.blockHelperMissing.call(depth0,container.lambda((depth0 != null ? lookupProperty(depth0,"snooze_options") : depth0), depth0),{"name":"snooze_options","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":12},"end":{"line":29,"column":31}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <button class=\"btn btn-outline-danger btn-sm btn-snooze me-2\" data-snooze-time=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"0") : depth0), depth0))
    + "\">\n                <i class=\"fas fa-hourglass-end me-1\"></i> "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"1") : depth0), depth0))
    + "\n            </button>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "            <p class=\"text-muted\"><small><em>(Cannot snooze this warning in production mode.)</em></small></p>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "                            <a class=\"btn btn-outline-primary\" href='"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "'>\n                                <i class=\"fas fa-chevron-left\"></i>\n                            </a>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "                            <a class=\"btn btn-outline-primary\" href='"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "'>\n                                <i class=\"fas fa-chevron-right\"></i>\n                            </a>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"text-muted\">\n                            <small class=\"d-block text-center\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"current_packet") : depth0), depth0))
    + " / "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"total_packets") : depth0), depth0))
    + "</small>\n                        </div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "        <div id=\"rp-checklist\" class=\"card mb-4\">\n            <div class=\"card-header border-0 bl-rp-section-header text-white\">\n                <h3 class=\"h5 mb-0 fw-bold\">\n                    <i class=\"fas fa-tasks me-2\"></i>"
    + container.escapeExpression(__default(require("../handlebars_helpers/lang.coffee")).call(depth0 != null ? depth0 : (container.nullContext || {}),"T004","Checklist",{"name":"lang","hash":{},"data":data,"loc":{"start":{"line":74,"column":53},"end":{"line":74,"column":80}}}))
    + "\n                </h3>\n            </div>\n            <div class=\"card-body rp-section-container\"></div>\n        </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"card-header border-0 bl-rp-section-header text-white\">\n                <h3 class=\"h5 mb-0 fw-bold\">\n                    <i class=\"fas fa-file-alt me-2\"></i>"
    + container.escapeExpression(__default(require("../handlebars_helpers/lang.coffee")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"artifact_header_label_token") : depth0),(depth0 != null ? lookupProperty(depth0,"artifact_header_label") : depth0),{"name":"lang","hash":{},"data":data,"loc":{"start":{"line":94,"column":56},"end":{"line":94,"column":114}}}))
    + "\n                </h3>\n            </div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "        <div id=\"rp-documents\" class=\"card mb-4\">\n            <div class=\"card-header border-0 bl-rp-section-header text-white\">\n                <h3 class=\"h5 mb-0 fw-bold\">\n                    <i class=\"fas fa-folder-open me-2\"></i>"
    + container.escapeExpression(__default(require("../handlebars_helpers/lang.coffee")).call(depth0 != null ? depth0 : (container.nullContext || {}),"T002","Documents",{"name":"lang","hash":{},"data":data,"loc":{"start":{"line":105,"column":59},"end":{"line":105,"column":86}}}))
    + "\n                </h3>\n            </div>\n            <div class=\"card-body rp-section-container\"></div>\n        </div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    return "        <div id=\"rp-notes\" class=\"card mb-4\">\n            <div class=\"card-header border-0 bl-rp-section-header text-white\">\n                <h3 class=\"h5 mb-0 fw-bold\">\n                    <i class=\"fas fa-comments me-2\"></i>"
    + container.escapeExpression(__default(require("../handlebars_helpers/lang.coffee")).call(depth0 != null ? depth0 : (container.nullContext || {}),"T003","Notes",{"name":"lang","hash":{},"data":data,"loc":{"start":{"line":116,"column":56},"end":{"line":116,"column":79}}}))
    + "\n                </h3>\n            </div>\n            <div class=\"card-body rp-section-container\">\n                <div id=\"rp-notes-history\" class=\"mb-3\"></div>\n                <div id=\"rp-note-editor\"></div>\n            </div>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.hooks.blockHelperMissing, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"flash-alert\"></div>\n\n"
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? lookupProperty(depth0,"show_security_warning") : depth0), depth0),{"name":"show_security_warning","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":0},"end":{"line":38,"column":26}}})) != null ? stack1 : "")
    + "\n<div class=\"rp-box card border-0 shadow-none\">\n    <div class=\"card-body\">\n        <div class=\"d-flex justify-content-between align-items-center mb-4\">\n            <h1 class=\"title h3 fw-bold my-2 text-dark\">"
    + alias4(__default(require("../handlebars_helpers/lang.coffee")).call(alias3,(depth0 != null ? lookupProperty(depth0,"review_packet_header_label_token") : depth0),(depth0 != null ? lookupProperty(depth0,"review_packet_header_label") : depth0),{"name":"lang","hash":{},"data":data,"loc":{"start":{"line":43,"column":56},"end":{"line":43,"column":124}}}))
    + "</h1>\n\n            <div class=\"page-nav d-flex flex-column align-items-end\">\n                <div class=\"d-flex flex-column align-items-center\">\n                    <div class=\"btn-group mb-2\">\n"
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? lookupProperty(depth0,"previous_packet") : depth0), depth0),{"name":"previous_packet","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":24},"end":{"line":52,"column":44}}})) != null ? stack1 : "")
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? lookupProperty(depth0,"next_packet") : depth0), depth0),{"name":"next_packet","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":24},"end":{"line":57,"column":40}}})) != null ? stack1 : "")
    + "                    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"total_packets") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":20},"end":{"line":63,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n        </div>\n\n        <div id=\"rp-actions\" class=\"mb-4\"></div>\n\n"
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? lookupProperty(depth0,"checklist_enabled") : depth0), depth0),{"name":"checklist_enabled","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":8},"end":{"line":79,"column":30}}})) != null ? stack1 : "")
    + "\n        <div id=\"rp-overview\" class=\"card mb-4\">\n            <div class=\"card-header border-0 bl-rp-section-header text-white\">\n                <h3 class=\"h5 mb-0 fw-bold\">\n                    <i class=\"fas fa-info-circle me-2\"></i>"
    + alias4(__default(require("../handlebars_helpers/lang.coffee")).call(alias3,"T001","Overview",{"name":"lang","hash":{},"data":data,"loc":{"start":{"line":84,"column":59},"end":{"line":84,"column":85}}}))
    + "\n                </h3>\n            </div>\n            <div class=\"card-body rp-section-container\"></div>\n        </div>\n\n        <div id=\"rp-artifacts\" class=\"card mb-4\">\n"
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? lookupProperty(depth0,"show_artifact_header") : depth0), depth0),{"name":"show_artifact_header","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":91,"column":12},"end":{"line":97,"column":37}}})) != null ? stack1 : "")
    + "            <div class=\"card-body rp-section-container\"></div>\n        </div>\n\n"
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? lookupProperty(depth0,"documents_enabled") : depth0), depth0),{"name":"documents_enabled","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":101,"column":8},"end":{"line":110,"column":30}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? lookupProperty(depth0,"notes_enabled") : depth0), depth0),{"name":"notes_enabled","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":112,"column":8},"end":{"line":124,"column":26}}})) != null ? stack1 : "")
    + "\n        <span id=\"confirm-modal\"></span>\n    </div>\n</div>\n";
},"useData":true});