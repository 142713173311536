process_form = (sections) ->
    ### Process application form sections data.

    If a field is of type combo, ajax_chosen, or radio, then set field value
    to value of selected option.
    Otherwise, if a field is a checkbox field, then turn values to Yes/No based
    on field value.

    TODO: This function currently does not support repeatable elements. Figure
    out how to render repeatable elements when time comes.

    ###

    for section in sections
        for field in section.fields
            if not field.value
                field.value = 'Not Answered'
            else if field.type in ['combo', 'ajax_chosen', 'radio']
                for option, value of field.options
                    if option == field.value
                        field.value = value
            else if field.type == "checkbox"
                field.value = if field.value then 'Yes' else 'No'

    sections


module.exports =
    process_form: process_form
