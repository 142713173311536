_ = require "underscore"
Backbone = require "backbone"
handlebars = require "handlebars/dist/handlebars"
markdown = require("markdown-it")({html: true})
moment = require "moment"
require "moment/min/locales"

clarity = require "orb/system/clarity_client"
get_selected_credential_types = require("./core").get_selected_credential_types
get_valid_applications = require("./core").get_valid_applications
layout = require "./layout.mustache"
paginate = require("./core").paginate
render_to_pdf = require("orb/system/services").render_to_pdf
require "./credential_browser.css"
templates = require "orb/templates"
loading = require "../portal/loading_dots.mustache"

#
# Bypass handlebars-loader for the credential card templates. We'll resolve
# partials manually; we don't want handlebars-loader to do that here.
#

browser_src = require("!!raw-loader!./credential_browser.mustache").default
modal_src = require("!!raw-loader!./credential_card_modal.mustache").default

default_credential_card_src = require("!!raw-loader!./credential_card.mustache").default
default_display_requirement_src = require("!!raw-loader!./credential_display_requirement.mustache").default

render_browser = handlebars.compile browser_src
default_credential_card = handlebars.compile default_credential_card_src
default_display_requirement = handlebars.compile default_display_requirement_src

PAGESIZE = BL.Configuration['credentials.catalog.page_size']


retrieve_card_templates = () ->
    promise = $.Deferred()

    card_partial = default_credential_card
    requirement_partial = default_display_requirement

    card_template_request =
        templates
            .fetch("credentials/default", "credential_card")
            .done (src) ->
                card_partial = handlebars.compile(src)

    requirement_template_request =
        templates
            .fetch("credentials/default", "credential_display_requirement")
            .done (src) ->
                requirement_partial = handlebars.compile(src)

    Promise.allSettled([card_template_request, requirement_template_request])
    .finally ->
        promise.resolve(card_partial, requirement_partial)
    return promise


_render_browser_with_overrides = ($el, context) ->
    ### Render the credential browser template into the provided HTML target.

    Given an HTML target (jQuery object) and context data for the template,
    render the credential browser template into the page.

    Before rendering, try to fetch override templates for the credential_card
    and credential_display_requirement partials, falling back to the
    corresponding template source in this module for either or both templates.

    Returns a jQuery Deferred object that will be resolved when rendering
    is complete.

    ###

    promise = $.Deferred()

    _do_render = (card_partial, requirement_partial) ->
        options =
            partials:
                "credential_card": card_partial
                "credential_display_requirement": requirement_partial

        $el.html(render_browser(context, options))

        promise.resolve()


    retrieve_card_templates().then (card_template, requirement_template) ->
        _do_render(card_template, requirement_template)

    promise

render_card_modal = ->
    if not $('#credential-card-modal').length
        modal = handlebars.compile modal_src
        $('body').append modal()

    $('#credential-card-modal').find('#credential-card-modal-body').html(loading)
    bootstrap.Modal.getOrCreateInstance(document.getElementById('credential-card-modal')).show()


CredentialBrowser = Backbone.View.extend
    ### Browse component for a candidate's available credentials.

    The credential browser presents a view of a candidate's available
    credentials. Each credential is presented in a card that includes
    the credential's name and description interpreted as Markdown

    The browser supports pagination, which is hidden if unnecessary for
    the number of results. Credentials can be searched by name.

    ###

    events:
        "click .credential-card .apply-button": "create_application"
        "click .pagination .next": "next_page"
        "click .pagination .previous": "previous_page"
        "click .credential-card .withdraw-button": "withdraw_application"

    initialize: (attributes, options) ->
        this.$search =
            $(".credential-search input").on "keyup", this.handle_keyup.bind(this)

        this.selected = options and options.selected or false

        if !!options?.initial_query
            this.$search.val(options.initial_query)

        bootstrap.Tooltip.Default.allowList['time'] = ['datetime'];

    set_types: (credential_types) ->
        ### Set the list of all credential types available to be shown by
        this browser.

        Filtering may reduce the credential types actually shown.

        ###

        this.types = credential_types
        this.do_search()

    do_search: ->
        ### Search the available credentials using the user's search query.

        Retrieve the user's search query from the search box and filter the
        list of available credential types to those whose name matches the
        specified query. An empty search query is interpreted to indicate
        "show all".

        After filtering, `show_cards` is called to update the display with
        the filtered list of credential types to show.

        The search is case insensitive & employs Unicode case folding.

        Spaces in the query are interpreted as gaps of arbitrary length and
        composition.

        ###

        this._keyup_timer_id = null

        q = this.$search.val()

        if !!q
            q = new RegExp(q.replace(" ", ".*"), "iu")

            types = _.filter this.types, (type) -> q.test type.name
        else
            types = this.types

        this.show_cards types

    show_cards: (credential_types) ->
        ### Show cards for the given credential types in the browser.

        Given an array of credential type objects, paginate the array, go
        to the first page, and render credential cards for that page.

        ###

        this.pages = paginate credential_types, PAGESIZE
        this.pagenum = 0
        this.render()

    handle_keyup: (e) ->
        ### Search box keyup handler.

        Automatically execute a search using the query in the search box
        when the user presses Enter or 500 ms have elapsed since the last
        character was typed.

        ###

        if this._keyup_timer_id
            clearTimeout this._keyup_timer_id

        if e.keyCode == 13
            this.do_search()
        else
            this._keyup_timer_id = setTimeout this.do_search.bind(this), 500

    create_application: (e) ->
        target = $(e.currentTarget)
        target.addClass('disabled')
        user = NextGen.metadata.user.uuid
        credential_type = target.data("credentialType")

        clarity.create_application(user, credential_type)
            .done ->
                window.location = "/dashboard"
            .fail ->
                target.removeClass('disabled')

    withdraw_application: (e) ->
        target = $(e.currentTarget)
        target.prev().addClass('disabled')
        user = NextGen.metadata.user.uuid
        application_uuid = target.data("applicationUuid")
        updates = status: "withdrawn"

        clarity.update_application(user, application_uuid, updates).done(->
            window.location = "/dashboard").fail ->
            target.prev().removeClass('disabled')

    next_page: ->
        if this.pagenum < this.pages.length - 1
            this.pagenum++
            this.render()

    previous_page: ->
        if this.pagenum > 0
            this.pagenum--
            this.render()

    render: ->
        pagenum = this.pagenum
        if this.selected
            context =
                cards: this.types
        else
            context =
                cards: this.pages[pagenum]
                pagenum: pagenum
                first_page: pagenum == 0
                last_page: pagenum == (this.pages.length - 1)
                show_pagination: this.pages.length > 1
                num_pages: this.pages.length

        _render_browser_with_overrides this.$el, context
            .done ->
                tooltips = document.querySelectorAll(".credential-tooltip")
                tooltipList = [...tooltips].map((element) ->
                    new bootstrap.Tooltip(element, {
                        html: true
                        placement: 'left'
                        container: element
                    }))


process_credential_type_data = (types, credential_requirements=[], scorecards=[], facts=[], selected=false) ->
    ### Massage available credential types for display

    Given a list of credential types,

    * sort the list by name
    * transform each type's description to HTML via Markdown (in place)
    * expand each credential type requirement into type.display_requirements

    Each type gains a `display_requirements` list of requirement status objects:

        satisfied           whether the user has completed the requirement
        hyperlink           the link from the requirement definition (set to
                            null if the requirement is satisfied)
        icon                an icon ("check", "dashboard") to show for the
                            requirement

    @param {Array} types    the credential types; should include the credential
                            type description and list of requirements (e.g.
                            `exam_result:ACS`)

    @param {Array} credential_requirements
                            the credential requirement types defined in Clarity;
                            should include the slug, name, and hyperlink.

    @param {Array} scorecard
                            list of user's active scorecards

    @param {Array} facts
                            list of user's recorded facts

    @param boolean selected
                            determines whether only selected (true) or
                            unselected (false) card are rendered

    @return {Array}         list of transformed credential types for display

    ###

    scorecard_credential_type_ids = _.pluck scorecards, "credential_type_id"

    if selected
        types = _.filter types, (type) -> type.uuid in scorecard_credential_type_ids

    types = _.sortBy types, "name"
    credential_requirements = _.indexBy credential_requirements, "slug"

    for type in types
        type.display_requirements = []

        scorecard_for_type = _.find scorecards, (scorecard) -> scorecard and scorecard.credential_type_id is type.uuid
        if scorecard_for_type
            # Use the stamped out scorecard to show progress for selected credentials
            type = _generate_scorecard_credential_card_data type, scorecard_for_type, credential_requirements
        else
            type = _generate_generic_credential_card_data type, facts, credential_requirements
        type['_metadata'] = NextGen.metadata
    types


_generate_generic_credential_card_data = (type, facts, credential_requirements) ->
    ### Update the type with data from user facts and credential requirements

        Helper for process_credential_type_data
    ###

    type.display_requirements = _annotate_group type.requirements, credential_requirements

    type


_annotate_group = (requirements_group, credential_requirements) ->
    display_details = credential_requirements[requirements_group.group_slug or requirements_group.slug] or {}
    result =
        required: 'all'
        satisfied: false

    result = _.extend result, display_details, requirements_group
    result.requirements = []

    result.is_group = requirements_group.hasOwnProperty 'requirements'
    if result.is_group
        result.group_slug = result.group_slug or null
        if result.show == 'never' or result.show == 'when_complete' and !result.satisfied
            return

    if result.satisfied
        result.icon = "check"
        result.hyperlink = null

    for requirement in requirements_group.requirements or []
        if _.isString requirement # used when browsing
            exclude_requirement = ['show=never', 'show=when_complete'].some (e) ->
                    requirement.replace(/\s/g, '').includes e
            if exclude_requirement or not requirement
                continue

            slug = requirement.split(" ", 1)[0]
            requirement =
                slug: slug
                name: slug

            requirement_details = credential_requirements[slug] or {}

            requirement = _.extend requirement, requirement_details

            result.requirements.push requirement
        else if !!requirement.group_slug
            result.requirements.push(_annotate_group requirement, credential_requirements)
        else  # used with scorecards
            requirement_details = credential_requirements[requirement.slug] or {}
            requirement = _.extend {name: requirement.slug}, requirement, requirement_details

            if requirement.show == 'never' or requirement.show == 'when_complete' and !requirement.satisfied
                continue
            if requirement.satisfied
                requirement.icon = "check"
                result.hyperlink = null
            if requirement.progress
                # Javascript does not have "floats and ints", just "numbers".
                # parseFloat will not add decimals to a number that did not already have them.
                earned = parseFloat requirement.progress[0]
                required = parseFloat requirement.progress[1]
                remaining = required - earned
                completion = earned * 100 / required
                if isNaN earned or isNaN required
                    # Fallback in case requirement progress is not a number. Not expected to occur.
                    earned = requirement.progress[0]
                    required = requirement.progress[1]
                else
                    if not Number.isInteger(earned)
                        earned = parseFloat earned.toFixed(2)
                    if not Number.isInteger(remaining)
                        remaining = parseFloat remaining.toFixed(2)
                    if not Number.isInteger(completion)
                        completion = parseFloat completion.toFixed(2)

                requirement.progress =
                    earned: earned
                    required: required
                    remaining: remaining
                    percent_complete: completion
                    percent_remaining: 100 - completion
            else if requirement.slug.startsWith('ce:')
                requirement.limiter = true

            result.requirements.push requirement

    result


_generate_scorecard_credential_card_data = (type, scorecard, credential_requirements) ->
    ### Update the type with data from the candidate scorecard

        Helper for process_credential_type_data
    ###

    if scorecard.type == 'renewal'
        # In most cases the from_date will be undefined, which moment defaults to today.
        # Included here to support previewing a future state
        from_date = scorecard.credential.from_date
        type.credential_number = scorecard.credential.number
        type.renewal_uuid = scorecard.renewal.uuid
        type.issued_on = scorecard.credential.issued_on
        type.expires_on = scorecard.renewal.custom_data.display_expiration

        if type.expires_on
            # Set moment locale based on user's language
            user_language = NextGen.metadata.user.language_code
            moment.locale(user_language)
            type.time_remaining = moment(type.expires_on).from(from_date, true)
            if moment(type.expires_on).isBefore(moment(from_date))
                type.time_remaining = '-'.concat(type.time_remaining)
        type.in_review = scorecard.renewal.status == 'submitted'
        type.renewal_custom_data = scorecard.renewal.custom_data
        type.shadow = scorecard.shadow
        type.permanent = scorecard.permanent
    else
        type.in_progress = true
        type.application_status = scorecard.application.status
        type.application_uuid = scorecard.application.uuid
        type.application_url = NextGen.metadata.plugin_url(
            "credential_interface",
            "/applications/#{type.application_uuid}"
        )
        type.application_custom_data = scorecard.application.custom_data

    type.display_requirements = _annotate_group scorecard.requirements, credential_requirements

    type.satisfied = scorecard.requirements['satisfied']
    type.renewable = type.satisfied && scorecard.credential && scorecard.credential.renewable

    if scorecard.credential
        type.status = scorecard.credential.status

    type


_retrieve_credential_info_and_show_cards = (browser, selected=false, credential_type_uuid=null) ->
    ### Retrieve and process credential type data and show credential card

    FIXME: This function does not have a coherent functionality split here.
    We want to separate out the part about get/process data and display
    credential card. One of the challenge here is they are happening inside
    a callback function, which binds them in the same place.

    ###

    user = window.NextGen.metadata.user.uuid
    credential_requirements_request = clarity.get_credential_requirements()
    available_types_request = clarity.get_credential_types()
    facts_request = clarity.get_user_facts(user, ['requirement_slugs'])
    scorecard_request = clarity.get_scorecards(user, 'type')
    user_credentials_request = clarity.get_credentials(user, 'display_expiration', true)

    $.when(credential_requirements_request, available_types_request, scorecard_request, facts_request, user_credentials_request)
        .done (credential_requirements, credential_types, scorecards, facts, credentials) ->
            valid_facts = _.uniq _.flatten _.pluck facts[0], 'requirement_slugs'

            if credential_type_uuid
                credential_types[0] = credential_types[0].filter((credential_type) ->
                    credential_type.uuid == credential_type_uuid
                )

            if not selected
                # Generate fake scorecards for shadowed and never-expiring credentials
                credentials = credentials[0]
                credential_types[0].forEach (credential_type) ->
                    matching_credential = credentials.find (credential) ->
                        return credential.credential_type_uuid == credential_type.uuid

                    if matching_credential and (matching_credential.shadows_credential_id or
                                                matching_credential.display_expiration == '-')
                        permanent = matching_credential.display_expiration == '-'

                        fake_scorecard =
                            type: 'renewal'
                            credential: matching_credential
                            credential_type_id: credential_type.uuid
                            renewal:
                                uuid: matching_credential.current_renewal_uuid
                                custom_data:
                                    display_expiration: !permanent and matching_credential.display_expiration or null
                            shadow: !!matching_credential.shadows_credential_id
                            permanent: permanent
                            requirements: {}
                        scorecards[0].push fake_scorecard

            browser.set_types process_credential_type_data(
                credential_types[0],
                credential_requirements[0],
                scorecards[0],
                valid_facts,
                selected,
            )


_get_initial_query = ->
    query = /[?&]q=([^&#?]+)/.exec(window.location)

    query? and query[1]


show_credential_browser = ->
    ### Show candidate's available credentials browser

    Available meaning all credential types that are allowed to the user
    from the credentials system.

    ###

    $("content").replaceWith(layout())

    browser = new CredentialBrowser({ el: $(".credential-browser") },
                                    { initial_query: _get_initial_query() })
    _retrieve_credential_info_and_show_cards(browser)


show_selected_credentials = ->
    ### Show candidate's current credential cards

    'current' means all credential types for which the user has an active
    scorecard

    ###

    browser = new CredentialBrowser(
        { el: $(".credential-cards") },
        { selected: true }
    )
    _retrieve_credential_info_and_show_cards(browser, selected=true)


show_single_credential_card = (credential_type_uuid, target) ->
    ### Show single credential card

    `target` is a dom identifier of a target for the card.  If not
    specified, a modal will be created and the card placed inside of it

    ###

    if !target
        render_card_modal()
        target = "#credential-card-modal-body"
    browser = new CredentialBrowser({ el: $(target) })
    _retrieve_credential_info_and_show_cards(
        browser, selected=false, credential_type_uuid=credential_type_uuid)


render_credential_certificate = (credential_type_uuid,
                                 candidate, credential, full_name="",
                                 credential_date="",
                                 credential_number="",
                                 credential_type_name="",
                                 credential_type_abbr="") ->
    ### Render a printable representation of a credential.

    Given a Credential Type (UUID) & a credential holder's fullname,
    credential date, and credential number, generate a printable
    representation of the credential.

    The template assets are housed in S3 and referenced via temporary
    URLs generated in Clarity.  The compiled template is sent to our pdf
    generation service and the returned pdf is saved.

    ###

    clarity.get_credential_type_assets(credential_type_uuid)
        .then (data) ->
            $.get(
                url: data.template
                cache: false
            ).then (template) ->
                template = handlebars.compile template

                html = template
                    background: data.background
                    full_name: full_name
                    credential_date: credential_date
                    credential_number: credential_number
                    credential_type_name: credential_type_name
                    credential_type_abbr: credential_type_abbr
                    candidate: candidate
                    credential: credential

                render_to_pdf html, credential_type_abbr + credential_number


show_my_credentials = (candidate_data, selector) ->
    my_credentials = require "./my_credentials"

    my_credentials candidate_data, selector


module.exports =
    show_credential_browser: show_credential_browser
    show_my_credentials: show_my_credentials
    show_selected_credentials: show_selected_credentials
    show_single_credential_card: show_single_credential_card
    render_credential_certificate: render_credential_certificate
    retrieve_card_templates: retrieve_card_templates
    process_credential_type_data: process_credential_type_data
