var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <input\n                        name=\"display_expiration\"\n                        class=\"form-control\"\n                        value=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"credential") : depth0)) != null ? lookupProperty(stack1,"display_expiration") : stack1), depth0))
    + "\"\n                        data-provide=\"datepicker\"\n                        data-date-format=\"yyyy-mm-dd\"\n                        data-date-autoclose=\"true\"\n                        data-date-today-highlight=\"true\"\n                        required>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span class=\"form-control-plaintext\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"credential") : depth0)) != null ? lookupProperty(stack1,"display_expiration") : stack1), depth0))
    + "</span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"row mb-3\">\n                <label class=\"col-sm-3 col-form-label\">Reasons for change:</label>\n                <div class=\"col-sm-6\">\n                    <textarea rows=\"2\" class=\"form-control\" name=\"reason\" required></textarea>\n                </div>\n            </div>\n";
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"table-responsive\">\n                <table id=\"transition-table\" class=\"table\">\n                    <thead>\n                        <tr>\n                            <th style=\"width: 50px\"></th>\n                            <th>Display Name</th>\n                            <th>Status</th>\n                            <th>Renewable</th>\n                            <th>Valid</th>\n                            <th>Transition Date</th>\n                            <th>Time Period</th>\n                        </tr>\n                    </thead>\n                    <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"timeline") : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":20},"end":{"line":109,"column":29}}})) != null ? stack1 : "")
    + "                    </tbody>\n                </table>\n            </div>\n";
},"8":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <tr id=\"timeline-"
    + alias4(((helper = (helper = lookupProperty(helpers,"status") || (depth0 != null ? lookupProperty(depth0,"status") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"status","hash":{},"data":data,"loc":{"start":{"line":61,"column":41},"end":{"line":61,"column":51}}}) : helper)))
    + "\">\n                            <td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"can_edit") : depths[1]),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":32},"end":{"line":75,"column":39}}})) != null ? stack1 : "")
    + "                            </td>\n                            <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"display_name") || (depth0 != null ? lookupProperty(depth0,"display_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"display_name","hash":{},"data":data,"loc":{"start":{"line":77,"column":32},"end":{"line":77,"column":48}}}) : helper)))
    + "</td>\n                            <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"status") || (depth0 != null ? lookupProperty(depth0,"status") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"status","hash":{},"data":data,"loc":{"start":{"line":78,"column":32},"end":{"line":78,"column":42}}}) : helper)))
    + "</td>\n                            <td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"renewable") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.program(14, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":80,"column":32},"end":{"line":84,"column":39}}})) != null ? stack1 : "")
    + "                            </td>\n                            <td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"valid") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.program(14, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":87,"column":32},"end":{"line":91,"column":39}}})) != null ? stack1 : "")
    + "                            </td>\n                            <td data-original=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"transition_date") || (depth0 != null ? lookupProperty(depth0,"transition_date") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"transition_date","hash":{},"data":data,"loc":{"start":{"line":93,"column":47},"end":{"line":93,"column":66}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"transition_date") || (depth0 != null ? lookupProperty(depth0,"transition_date") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"transition_date","hash":{},"data":data,"loc":{"start":{"line":93,"column":68},"end":{"line":93,"column":87}}}) : helper)))
    + "</td>\n                            <td data-original=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"time_period") || (depth0 != null ? lookupProperty(depth0,"time_period") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"time_period","hash":{},"data":data,"loc":{"start":{"line":94,"column":47},"end":{"line":94,"column":62}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"time_period") || (depth0 != null ? lookupProperty(depth0,"time_period") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"time_period","hash":{},"data":data,"loc":{"start":{"line":94,"column":64},"end":{"line":94,"column":79}}}) : helper)))
    + "</td>\n                        </tr>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"can_edit") : depths[1]),{"name":"if","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":96,"column":24},"end":{"line":108,"column":31}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"processed") : depth0),{"name":"unless","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":64,"column":36},"end":{"line":74,"column":47}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        <button type=\"button\" \n                                                class=\"btn btn-link btn-sm credential-action\" \n                                                title=\"Update timeline\"\n                                                data-bs-toggle=\"collapse\" \n                                                data-bs-target=\"#collapse-"
    + alias4(((helper = (helper = lookupProperty(helpers,"status") || (depth0 != null ? lookupProperty(depth0,"status") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"status","hash":{},"data":data,"loc":{"start":{"line":69,"column":74},"end":{"line":69,"column":84}}}) : helper)))
    + "\"\n                                                aria-expanded=\"false\"\n                                                aria-controls=\"collapse-"
    + alias4(((helper = (helper = lookupProperty(helpers,"status") || (depth0 != null ? lookupProperty(depth0,"status") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"status","hash":{},"data":data,"loc":{"start":{"line":71,"column":72},"end":{"line":71,"column":82}}}) : helper)))
    + "\">\n                                            <i class=\"fa-solid fa-pencil\"></i>\n                                        </button>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "                                    <i class=\"fa-solid fa-check text-success\"></i>\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "                                    <i class=\"fa-solid fa-xmark text-danger\"></i>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"processed") : depth0),{"name":"unless","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":97,"column":28},"end":{"line":107,"column":39}}})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <tr class=\"timeline-edit-row\">\n                                    <td colspan=\"7\" class=\"hidden-row p-0\">\n                                        <div class=\"collapse timeline-edit-collapse\"\n                                         id=\"collapse-"
    + alias4(((helper = (helper = lookupProperty(helpers,"status") || (depth0 != null ? lookupProperty(depth0,"status") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"status","hash":{},"data":data,"loc":{"start":{"line":101,"column":54},"end":{"line":101,"column":64}}}) : helper)))
    + "\" \n                                         data-transition-name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"status") || (depth0 != null ? lookupProperty(depth0,"status") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"status","hash":{},"data":data,"loc":{"start":{"line":102,"column":63},"end":{"line":102,"column":73}}}) : helper)))
    + "\"\n                                         data-bs-parent=\"#transition-table\">\n                                        </div>\n                                    </td>\n                                </tr>\n";
},"19":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"alert alert-info\">\n                This credential does not have any scheduled transitions.\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<style>\n.hidden-row {\n    padding: 0 !important;\n}\n</style>\n\n<div class=\"card border-0 mb-4\">\n    <div class=\"card-body\">\n        <div class=\"row mb-3\">\n            <label class=\"col-sm-3 col-form-label\">Credential type:</label>\n            <div class=\"col-sm-6\">\n                <span class=\"form-control-plaintext\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"credential") : depth0)) != null ? lookupProperty(stack1,"credential_type_name") : stack1), depth0))
    + "</span>\n            </div>\n        </div>\n\n        <div class=\"row mb-3\">\n            <label class=\"col-sm-3 col-form-label\">Display expiration date:</label>\n            <div class=\"col-sm-6\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"can_edit") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":19,"column":16},"end":{"line":31,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"can_edit") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":8},"end":{"line":42,"column":15}}})) != null ? stack1 : "")
    + "\n        <h4 class=\"my-4\">Transition Schedule</h4>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"timeline") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.program(19, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":45,"column":8},"end":{"line":117,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</div>";
},"useData":true,"useDepths":true});