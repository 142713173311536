_ = require 'underscore'
helpers = require './helpers'
require 'datatables.net-select-bs5'

render_date = (raw_date) ->
    if raw_date
        # E.g., 2023-06-16, 6:35 pm
        fmt = BL.Language.get('L001429', 'YYYY-MM-DD, h:mm a')
        moment(raw_date).format(fmt)
    else
        BL.Language.get('L001433', '[Unknown]')


default_columns = (using_select=false) ->
    [
        {
            title: '#'
            data: 'publish_number'
        }
        {
                title: 'Name'
                data: 'name'
        }
        {
            title: 'Time'
            data: 'created'
            render: (created, type, data, meta) ->
                render_date created
        }
        {
            title: 'By'
            data: 'user_name'
        }
        {
            title: 'Apps'
            data: 'applications_count'
            createdCell: (td, cell_data, row_data, row, col) ->
                if cell_data > 0 and using_select
                    $(td).addClass('selectable')
        }
        {
            title: 'Edited'
            data: 'modified_applications_count'
            createdCell: (td, cell_data, row_data, row, col) ->
                if cell_data > 0 and using_select
                    $(td).addClass('selectable')
        }
        {
            title: 'Credentials'
            data: 'renewals_count'
            createdCell: (td, cell_data, row_data, row, col) ->
                if cell_data > 0 and using_select
                    $(td).addClass('selectable')
        }
        {
            title: 'Edited'
            data: 'modified_renewals_count'
            createdCell: (td, cell_data, row_data, row, col) ->
                if cell_data > 0 and using_select
                    $(td).addClass('selectable')
        }
    ]


map_user_info = (table_data) ->
    # Takes a list of objects containing user_uuids
    # Returns a map of {user_uuid:Clarus user_name} for each unique user_uuid supplied
    promise = $.Deferred()
    mapping = {}
    mapping[row.user_uuid] = helpers.get_user_info(row.user_uuid) \
                              for row in table_data when \
                              (not (row.user_uuid of mapping)) and row.user_uuid

    entries = Object.entries mapping
    keys = entries.map (i) -> i[0]
    values = entries.map (i) -> i[1]

    user_map = {}
    Promise.all(values).then (values) ->
        for index, key of keys
            user_map[key] = values[index].user_name ? BL.Language.get('L001434', '[Unknown]')
        promise.resolve user_map
    promise


init_publish_table = (table, starting_data, options={}, columns=null) ->
    columns ?= default_columns(options.select_target == 'cell')

    for row in starting_data
        row.user_name = BL.Language.get('L001430', '[Loading]')

    table.DataTable(_.extend {
        data: starting_data
        columns: columns
        order: [[ 0, 'desc' ]]
        paging: false
        searching: false
        info: false
        scrollY: '300px'
        scrollCollapse: true,
        language: {
            emptyTable: BL.Language.get('L001431', 'No publish history found for this credential type')
            loadingRecords: BL.Language.get('L001432', 'Loading...')
        }
    }, options)

    if options.select
        # support either single/multi selection
        table.DataTable().select.style(options.select_style || 'single')

        if options.select_handler
            table.DataTable().on('select', options.select_handler)

        if options.deselect_handler
            table.DataTable().on('deselect', options.deselect_handler)

    if options.select_target == 'cell'
        # allow selecting specific cells
        table.DataTable().select.items('cell')
        # only allow selecting specified cells
        .select.selector('td.selectable')

    map_user_info(starting_data).then (user_map) ->
        for index, row of starting_data
            row.user_name = user_map[row.user_uuid] ? BL.Language.get 'L001434', '[Unknown]'
            table.DataTable().row(index).invalidate()

module.exports =
    'init_publish_table': init_publish_table
    'default_columns': default_columns
    'render_date': render_date
