template_context =
    headings: [
        "Review status",
        "Candidate",
        "User name",
        "Date created",
        ""
    ]


table_options =
    ajax:
        url: "#{NextGen.metadata.plugin_url('review_packets', '?my_assignments=true&for_list_view=true')}"
        dataSrc: ""
    columns: [
        {data: "status"}
        {data: "candidate.display_name"}
        {data: "candidate.user_name"}
        {data: "created"}
        {
            sortable: false
            searchable: false
            render: (dontcare, dontcare2, review_packet, meta) ->
                "<a href='/app/review_packets/#{review_packet.id}' class='btn btn-default'>Begin review</a>"
        }
    ]
    order: [[ 0, "desc" ]]
    paging: false
    searching: false
    info: false
    autoWidth: false
    language: {
        emptyTable: "<em>There are no candidates for you to review at this time</em>"
    }


get_config = (options) ->
    template_context: template_context
    table_options: table_options


module.exports =
    get_config: get_config
