# Complex action handlers exposed to `renderer.coffee` review packet
# configurations dynamically loaded from S3


base_actions = require "./actions/actions"
application_actions = require "./types/application/actions"


module.exports =
    assign_reviewers: base_actions.assign_reviewers
    application: application_actions
