_ = require "underscore"
Backbone = require "backbone"
handlebars = require "handlebars/dist/handlebars"
markdown = require("markdown-it")({html: true})

clarity = require "../system/clarity_client"
flash_message = require "./flash_message"
templates = require "../templates"


module.exports =
    _: _
    Backbone: Backbone
    clarity: clarity
    flash: flash_message.flash
    flash_error: flash_message.flash_error
    flash_info: flash_message.flash_info
    flash_success: flash_message.flash_success
    handlebars: handlebars
    hide_flash: flash_message.hide_flash
    markdown: markdown
    templates: templates
