_ = require "underscore"
data_helpers = require "orb/components/data_helpers"


_default_metadata =
    plugin_enabled: (plugin_name) ->
        plugin = _.findWhere(NextGen.metadata.clarus_plugins, {0: plugin_name})
        !!plugin


    plugin_url: (plugin_name, path="") ->
        ### Calculate plugin-relative URLs.

        Given a plugin name — which is the entry point name that points
        to the plugin's code distribution — resolve URLs relative to the
        plugin's installed location within the URL space. `path`, if
        provided, should be a URL fragment, starting with '/', of the
        desired endpoint *within* the plugin-relative domain. For
        example, if the 'student_portal' plugin is mounted at
        '/programs', then

            plugin_url('student_portal', '/static')

        produces '/programs/static'.

        @param  {String} plugin_name the name of the plugin, i.e. its
                                     entry point handle from setup.py

        @param  {String} [path=""]   the URL fragment to resolve

        @return {String}             the resolved URL

        @throws {ReferenceError}     if a requested plugin is not defined in
                                     `NextGen.metadata.clarus_plugins`

        ###

        plugin = _.findWhere(NextGen.metadata.clarus_plugins, {0: plugin_name})

        try
            "#{plugin[1]}#{path}"
        catch e
            plugins = JSON.stringify(NextGen.metadata?.clarus_plugins)

            throw new ReferenceError "Plugin '#{plugin_name}' requested but \
                                      not defined; value of \
                                      NextGen.metadata.clarus_plugins: \
                                      #{plugins}"


retrieve_additional_metadata = () ->
    ### Load additional metadata from a data service transform

        If enabled via configuration, will load data from the
        additional_information transform for the client into the
        system metadata provided to all dynamic views and pages.
        The data is cached for 5 minutes before it will be retrieved
        again.

    ###

    if not BL.Configuration['client.use_additional_metadata_transform']
        delete(window.additional_metadata_promise)
        return

    host = NextGen.metadata.services_host
    client = NextGen.metadata.client_id.toLowerCase()
    url = "https://#{host}.clarus-servic.es/data/#{client}/transforms/additional_metadata"

    user = NextGen.metadata.user.id or 'anonymous'
    data = sessionStorage.getItem("#{user}_additional_metadata")
    if data
        data = JSON.parse data
        if (Date.now() < data['exp'])
            NextGen.metadata['additional_metadata'] = data
            delete(window.additional_metadata_promise)
            return

    params = {user_role: NextGen.metadata.user.role or 'anonymous'}
    data_helpers.get_token().then( (data) ->
        data_helpers.request(data['access_token'], url, params).then(
            (data) ->
                data = data['data']
                NextGen.metadata['additional_metadata'] = data
                data['exp'] = Date.now() + 300000
                sessionStorage.setItem("#{user}_additional_metadata",
                                       JSON.stringify(data))
                delete(window.additional_metadata_promise)
            ,
            (err) ->
                NextGen.metadata['additional_metadata'] = {}
                data = {'exp': Date.now() + 300000}
                sessionStorage.setItem("#{user}_additional_metadata",
                                       JSON.stringify(data))
                delete(window.additional_metadata_promise)
        )
    )


update_metadata = (metadata) ->
    ### Create or update NextGen.metadata

    Given an Object of system metadata, update the system metadata
    registry at `window.NextGen.metadata` with the supplied values.

    ###

    if not window.NextGen
        window.NextGen = {}

    window.NextGen.metadata = _.extend(
        {},
        window.NextGen.metadata or _default_metadata,
        metadata or {}
    )

    if !window.additional_metadata_promise
        window.additional_metadata_promise = retrieve_additional_metadata()

    await window.additional_metadata_promise
    

module.exports =
    update_metadata: update_metadata
