var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                <input id=\"private-note-toggle\" class=\"form-check-input\" name=\"internal\" type=\"checkbox\" checked >\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n    <hr/>\n    <form id=\"rp-note-editor\" class=\"form-horizontal g-3\" method=\"POST\" action=\""
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"submit_url") : depth0), depth0))
    + "\">\n        <label for=\"note\" class=\"form-label fw-bold\">"
    + alias1(__default(require("../../handlebars_helpers/lang.coffee")).call(alias2,"L001361","Add Note",{"name":"lang","hash":{},"data":data,"loc":{"start":{"line":4,"column":53},"end":{"line":4,"column":82}}}))
    + "</label>\n        <textarea class=\"form-control required\" name=\"note\" placeholder=\""
    + alias1(__default(require("../../handlebars_helpers/lang.coffee")).call(alias2,"L001362","Add information...",{"name":"lang","hash":{},"data":data,"loc":{"start":{"line":5,"column":73},"end":{"line":5,"column":112}}}))
    + "\"></textarea>\n        <br/>\n\n        <!-- FIXME PSINAB-62: Fix the width of the bootstrap switch on wide screens. -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depth0 != null ? lookupProperty(depth0,"can_create_internal_note") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":11,"column":15}}})) != null ? stack1 : "")
    + "\n        <button type=\"submit\" class=\"btn btn-default\">"
    + alias1(__default(require("../../handlebars_helpers/lang.coffee")).call(alias2,"L001363","Add Note",{"name":"lang","hash":{},"data":data,"loc":{"start":{"line":13,"column":54},"end":{"line":13,"column":83}}}))
    + "</button>\n    </form>\n</div>\n";
},"useData":true});