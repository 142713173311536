### NextGen portal dispatcher

Portal dispatcher for NextGen UIs, responsible for determining the correct
interface to load for a given user's current role and establishing the
structural components of the UI.

###


dashboard = require "./dashboard"
dynamic_table = require "orb/dynamic_table"
templates = require "orb/templates"

structure_template = require "./structure.mustache"
pages = require "./pages"

require "select2"
require "select2/dist/css/select2.css"

### User portal definitions.

Each portal defines the options and functionality specific to that portal.
Current options:

    landing_page        a function accepting an options argument that should
                        render the landing page for the user. The function
                        should render its content into `options.render_target`.
                        If omitted, the default renderer simply clears the
                        render target.

    show_header         Boolean indicating whether to show the top header
                        (default true)

    show_top_nav        Boolean indicating whether to show the top navigation
                        (default true)

If the current role does not have a portal definition, the default is an
empty landing page with no header or top navigation.

###

# TODO (COREBT-12064): Currently hardcoded for review packet
# reviewers & “everyone else”.

# TODO: extend nav (make portal S3 configurable?)

portals =
    null:
        show_header: false
        show_top_nav: false

    review_packet_reviewers:
        landing_page: (options) ->
            dynamic_table.render
                table_type: "reviews_by_reviewer"
                render_target: options.render_target


_get_portal = (user_role=null) ->
    user_role = user_role or NextGen.metadata.user.role

    if not portals[user_role]?
        user_role = null

    portals[user_role]


render_portal = (options) ->
    ### Render the NextGen portal structure.

    ###
    render_target = options?.render_target or "#app-content-container"
    portal = _get_portal()


    # show header/top nav default _true_ unless explicitly set to false
    show_header = not (portal.show_header is false or options.show_header is false)
    show_top_nav = not (portal.show_top_nav is false or options.show_top_nav is false)

    $(render_target).html structure_template
        agency_logo_path: BL.Configuration.agency_logo_path
        display_name: NextGen.metadata.user.name
        show_header: show_header
        show_top_nav: show_top_nav


render_landing_page = (options) ->
    ### Render the landing page for the user's current role.

    If the current role does not have a portal definition, the default is an
    empty landing page with no header or top navigation.

    ###

    default_landing_page = (options) ->
        $(options.render_target).html("")

    landing_page = _get_portal().landing_page or default_landing_page

    landing_page options

render_app_summary = (data) ->
    ### Render application summary on application.

    If no template is found it uses the fallback defined here
    ###
    template = "{{lang 'L000541' 'You are applying for the '}} <strong>{{exam_type.name}}</strong>"
    templates.fetch("dynamic_views/portal", "application_summary").then (new_template) ->
        template = new_template
    .always () ->
        NextGen.setup.update_metadata().then () ->
            data['_metadata'] = NextGen.metadata
            compiled_template = NextGen.components.handlebars.compile(
                template)
            $('#application-summary').html(compiled_template(data)).removeClass('d-none');


module.exports =
    render: render_portal
    render_dashboard: dashboard
    render_landing_page: render_landing_page
    render_page: pages.render_page
    init_csv_uploader: pages.initialize_csv_uploader
    render_app_summary: render_app_summary
    $: $
