Cookies = require "js-cookie"
available_credential_types = require("./clarity_core").available_credential_types


clarity_host = null
clarity_url = null
client_id = null


_init = ->
    if clarity_host
        return

    clarity_host = BL.Configuration["system.api.clarity_api_host"]
    clarity_url = "https://#{clarity_host}/api/v1"

    client_id = BL.Configuration.client_id


_request = (target, method, data) ->
    _init()

    url = "#{clarity_url}#{target}"
    token = Cookies.get("brighttrac_#{client_id}") or Cookies.get("tg_visit")

    $.ajax
        method: method
        url: url
        traditional: true
        headers:
            Accept: "application/json"
            "Content-Type": "application/json"
            Authorization: "Bearer #{token}"
        data: data


_get = (target, data=null) ->
    _request target, "get", data


_post = (target, data) ->
    _request target, "post", JSON.stringify data


_put = (target, data) ->
    _request target, "put", JSON.stringify data


_delete = (target, data) ->
    _request target, "delete", JSON.stringify data


get_service_authorization = ->
    _get "/auth/services"


get_credential_type_assets = (credential_type_id) ->
    _get "/credential_types/#{credential_type_id}/assets"


create_application = (user_uuid, credential_type_slug) ->
    _post "/users/#{user_uuid}/applications", credential_type_slug: credential_type_slug


get_applications = (user_uuid) ->
    _get "/users/#{user_uuid}/applications"


get_credentials = (user_uuid, expand=null, include_historic=false, include_terminal=false, type_expand=null) ->
    _get "/users/#{user_uuid}/credentials",
        expand: expand
        include_historic: include_historic
        include_terminal: include_terminal
        type_expand: type_expand

get_credential = (user_uuid, credential_uuid) ->
    _get "/users/#{user_uuid}/credentials/#{credential_uuid}"

get_credential_assets_link = (user_uuid, credential_uuid) ->
    _get "/users/#{user_uuid}/credentials/#{credential_uuid}/assets"

get_credential_renewal = (user_uuid, credential_uuid, expand=null) ->
    _get "/users/#{user_uuid}/credentials/#{credential_uuid}/renewals/current",
        expand: expand

get_credential_types = ->
    _get "/credential_types", expand: "description, requirements, renewal_description"

get_credential_type = (credential_type, draft=false, data=null) ->
    target = "/credential_types/#{credential_type}"
    if draft
        target = "#{target}/draft"
    _get target, data

get_credential_type_preview = (data) ->
    target = "/credential_types/preview"
    _post target, data

update_credential_type = (credential_type, data, draft=false) ->
    target = "/credential_types/#{credential_type}"
    if draft
        target = "#{target}/draft"
    _put target, data

update_credential_type_by_publish_version = (credential_type, publish_version) ->
    target = "/credential_types/#{credential_type}"
    _put target, 
        publish_version: publish_version

publish_credential_type = (credential_type, configuration, name, reason) ->
    target = "/credential_types/#{credential_type}/publish_versions"
    _post target,
        credential_type_configuration: configuration
        name: name
        reason: reason


get_publish_version = (credential_type_id, publish_version_uuid, data) ->
    target = "/credential_types/#{credential_type_id}/publish_versions/#{publish_version_uuid}"
    _get target, data


get_available_credential_types = (user_uuid) ->
    ### Get a list of credential types available for given user

    A credential type is available to user if user does not have a valid
    credential of that type.

    Returns a list of consisting of credential type objects.

    ###

    promise = $.Deferred()
    type_request = _get "/credential_types", expand: "description,requirements"
    user_credentials = get_credentials user_uuid

    $.when(type_request, user_credentials)
        .done (type_response, credentials_response) ->
            types = type_response[0]
            credentials = credentials_response[0]

            promise.resolve(available_credential_types types, credentials)
        .fail (xhr) ->
            promise.rejectWith xhr, arguments

    promise


get_scorecards = (user_uuid, expand, active="true") ->
    ### Get a user's credential progress scorecards.

    Set expand to `'type'` to also retrieve the application or renewal the
    scorecard is attached to.

    By default only returns active scorecards. Set active = `'all'` to
    get the user's full scorecard history.

    ###

    _get "/users/#{user_uuid}/scorecards",
        expand: expand
        active: active


get_user_facts = (user_uuid, expand) ->
    _get "/users/#{user_uuid}/facts", expand: expand


get_credential_requirements = ->
    _get "/credential_requirements", expand: "name,description,hyperlink"


create_credential_requirement = (slug, name, hyperlink)->
    _post "/credential_requirements",
        slug: slug
        name: name
        hyperlink: hyperlink

update_credential_requirement = (uuid, name, hyperlink) ->
    _put "/credential_requirements/#{uuid}",
        name: name
        hyperlink: hyperlink


grant_credential = (credential_type_id, user_uuid, granting_user_id, issued_on, effective_renewal_date, custom_data) ->
    _post "/credential_types/#{credential_type_id}/credentials",
        user_uuid: user_uuid
        granting_user_id: granting_user_id or null
        issued_on: issued_on or null
        effective_renewal_date: effective_renewal_date or null
        custom_data: custom_data or {}


update_application = (user_uuid, application_uuid, updates) ->
    _put "/users/#{user_uuid}/applications/#{application_uuid}", updates


update_credential = (user_uuid, credential_uuid, updates) ->
    _put "/users/#{user_uuid}/credentials/#{credential_uuid}", updates


update_credential_renewal = (user_uuid, credential_uuid, updates) ->
    _put "/users/#{user_uuid}/credentials/#{credential_uuid}/renewals/current", updates


renew_credential = (user_uuid, credential_uuid, renewal_start=null) ->
    _post "/users/#{user_uuid}/credentials/#{credential_uuid}/renewals",
        renewal_start: renewal_start

revoke_credential = (user_uuid, credential_uuid, status) ->
    _delete "/users/#{user_uuid}/credentials/#{credential_uuid}",
        status: status

update_credential_schedule = (user_uuid, credential_uuid, updates) ->
    _put "/users/#{user_uuid}/credentials/#{credential_uuid}/schedules", updates

find_credentials_for_credential_type = (credential_type_uuid, credential_uuids) ->
    _post "/credential_types/#{credential_type_uuid}/find_credentials",
        credential_uuids: credential_uuids

find_applications_for_credential_type = (credential_type_uuid, application_uuids) ->
    _post "/credential_types/#{credential_type_uuid}/find_applications",
        application_uuids: application_uuids

validate_configuration = (configuration) ->
    _post "/credential_types/validate_configuration",
        {"configuration": configuration}

module.exports =
    get_credential_type_assets: get_credential_type_assets
    create_application: create_application
    find_credentials_for_credential_type: find_credentials_for_credential_type
    find_applications_for_credential_type: find_applications_for_credential_type
    get_applications: get_applications
    get_credential_types: get_credential_types
    get_credential_type: get_credential_type
    get_credential_type_preview: get_credential_type_preview
    get_available_credential_types: get_available_credential_types
    get_credentials: get_credentials
    get_credential: get_credential
    get_credential_assets_link: get_credential_assets_link
    get_credential_renewal: get_credential_renewal
    get_scorecards: get_scorecards
    get_user_facts: get_user_facts
    get_credential_requirements: get_credential_requirements
    get_publish_version: get_publish_version
    create_credential_requirement: create_credential_requirement
    update_credential_requirement: update_credential_requirement
    grant_credential: grant_credential
    update_application: update_application
    update_credential: update_credential
    update_credential_renewal: update_credential_renewal
    update_credential_type: update_credential_type
    publish_credential_type: publish_credential_type
    renew_credential: renew_credential
    revoke_credential: revoke_credential
    get_service_authorization: get_service_authorization
    update_credential_schedule: update_credential_schedule
    update_credential_type_by_publish_version: update_credential_type_by_publish_version
    validate_configuration: validate_configuration
