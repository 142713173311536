_ = require "underscore"
js_yaml = require "js-yaml"
require "./review_packet.css"

# In-browser CoffeeScript compiler, used for evaluating S3-based type renderer
# definitions.
#
# Because of a packaging bug, we can't just say `require "coffee-script"`
# here; see
#
#    https://github.com/jashkenas/coffeescript/issues/4276
#    https://github.com/jashkenas/coffeescript/pull/4501
#
# The CoffeeScript 2 beta fixes this, so when we upgrade we can update this
# require declaration and remove the extraneous package.
coffeescript = require "coffee-script_compiler"


orb = require "orb"

box_template = require "./box_structure.mustache"


checklist = require "./checklist/checklist"
checklist_section = checklist.render_checklist

documents = require "./documents/documents"
documents_section = documents.render_documents

notes = require "./notes/notes"
notes_section = notes.render_notes
reload_note_table = notes.reload_note_table

_renderers = require "./types/get_renderers"
additional_action_handlers = require "./action_handlers"
get_renderers = _renderers.get_renderers
register_renderers = _renderers.register_renderers
templates = require "orb/templates"

core = require "./core"
CoreReviewPacket = core.ReviewPacket

services = require "orb/system/services"
make_iframes_printable = services.make_iframes_printable
cleanup_printable_iframes = services.cleanup_printable_iframes

ONE_HOUR = 1 * 60 * 60

_snooze_options = [
    [ONE_HOUR, "1 hour"]
    [4 * ONE_HOUR, "4 hours"]
    [24 * ONE_HOUR, "24 hours"]
]

_packet_ids = null

# TODO COREBT-13522: Remove default actions when existing clients have retired
# old renderer.coffee syntax.
default_application_actions =
    approve: core.default_actions.approve
    deny: core.default_actions.deny
    withdraw: core.default_actions.withdraw

    approve_accommodations:
        name: "approve_accommodations"
        action: additional_action_handlers.application.approve_accommodations
        label: "Review Accommodations"
        label_token: ""
        available_when: "pending_special_accommodations"

    deny_accommodations:
        name: "deny_accommodations"
        action: additional_action_handlers.application.deny_accommodations
        label: "Deny Accommodation Request"
        label_token: ""
        available_when: "pending_special_accommodations"


ReviewPacket = CoreReviewPacket.extend
    ### ReviewPacket imperative shell.
    ###

    initialize: ->
        # super()
        CoreReviewPacket.prototype.initialize.apply this, arguments
        this.on "change:reviewers", this._save_reviewers

        # Only bind generic_data updates after initial fetch
        this.once 'sync', =>
            this.on "change:generic_data", this._update_data

        this.on "change:status", (dontcare, status) ->
            display_status =
                status[0].toUpperCase() + status[1..-1].replace("_", " ")

            this.set("display_status", display_status)

        this.on "change:type", (dontcare, type) ->
            if type = "application"
                this.set("_default_actions", default_application_actions)
        window.onbeforeprint = make_iframes_printable
        window.onafterprint = cleanup_printable_iframes

    _update_data: (review_packet, data) ->
        if typeof data != "object"
            console.error 'Review packet generic data must be an "object" type (key/value pairs)'
            return
        $.ajax
            url: this.url() + "/data"
            method: 'put'
            headers: 'content-type': 'application/json'
            data: JSON.stringify data
            success: () ->
                orb.flash_success BL.Language.get('L001439', 'Generic data updated')
            error: (err) -> 
                msg = BL.Language.get('L001440', 'Failed to update review packet generic data')
                orb.flash_error msg
                console.error msg


    _save_reviewers: (review_packet, reviewers) ->
        $.ajax
            url: this.url() + "/reviewers"
            method: 'put'
            headers:
                'content-type': 'application/json'
            data: JSON.stringify
                reviewers: reviewers
        .done ->
            orb.flash_success BL.Language.get("L001376", "Reviewers assigned")

            reload_note_table()

    update_status: (new_status, clear_reviewers=false) ->
        ### Update the review packet's status & persist the change.

        Set's this ReviewPacket's status to the given status, using
        the save machinery with `wait=true` to keep the local model's status
        from changing until a successful server response is received.

        Flashes an error message if the save was unsuccessful.

        ###

        data =
            status: new_status
            clear_reviewers: clear_reviewers

        options =
            patch: true
            wait: true
            error: (model, response, options) ->
                orb.flash_error BL.Language.get("L001377", "There was an error when trying to update the status.")
            complete: (jqXHR, textStatus) ->
                $('#rp-actions .action-btn').attr 'disabled', false
                $('#rp-checklist [type=checkbox]').bootstrapToggle 'enable'
        $('#rp-actions .action-btn').attr 'disabled', true
        $('#rp-checklist [type=checkbox]').bootstrapToggle 'disable'

        this.save data, options

    add_note: (review_packet, note, is_internal) ->
        ### Add a note to the review packet

        Adds the provided note to the review packet and reloads the notes table.
        Pass `is_internal` as true if an internal note is desired, otherwise
        the note will be added as a public note.

        Flashes an error message if the request to add a note was unsuccessful.

        ###

        $.ajax
            url: this.url() + "/notes"
            method: "post"
            data:
                note: note
                internal: is_internal
            success: ->
                notes.reload_note_table()
            error: ->
                orb.flash_error BL.Language.get("L001378", "An error occurred when trying add a note.")


_wire_security_alert_snooze_buttons = (review_packet) ->
    $(".security-alert").on "click", ".btn-snooze", ->
        $(this).parents(".security-alert").addClass("fade").slideUp()

        $.ajax
            url: review_packet.urlRoot() + "/snooze"
            method: "put"
            data:
                snooze_for: $(this).data("snoozeTime")


_rewire_link_behavior = () ->
    """ Bypass backbone link handling

    This override will force links with a _blank target to bypass the backbone
    routing machinery and either open in a new tab or (if using the webtop) open
    a new ext window.

    """

    on_webtop = window.location != window.parent.location and window.parent.Ext
    $('body').on 'click', 'a[target=_blank]', (event) ->
        event.preventDefault()
        if on_webtop
            detail = url: $(this).attr('href')
            event = new CustomEvent('embeddedNavigationEvent', detail: detail)
            window.parent.document.dispatchEvent event
        else
            window.open $(this).attr('href'), '_blank'
    return


_update_dynamic_form_iframes = (review_packet) ->
    """ Process content for iframes configured to render dynamic forms

    This will attempt to fetch the read only version of the dynamic form
    configured on the iframe element populated with data from the review
    packet attribute that is defined on the iframe element.

    Specified property data attributed can specify a `.` separated string that
    allows deep property fetching. e.g. verification_surveys.surveys.0

    """

    $('iframe').filter('[data-dynamic-form]').each (index, iframe) =>
        iframe.onload = () =>
            iframe.height = iframe.contentWindow.document.body.scrollHeight + 'px'
        try
            form = iframe.dataset.dynamicForm
            property_list = iframe.dataset.property.split "."
            value = _.property(property_list) review_packet.attributes

            # Attach candidate key/data to all non-candidate based forms
            # so profile fields will display correctly
            if not _.isEqual(property_list, ['candidate']) and review_packet.attributes['candidate']
                value['candidate'] = review_packet.attributes['candidate']
            entity_data = JSON.stringify(value)
        catch error
            console.log error

        $.ajax(
            type: 'POST'
            url: '/dynamic_form/read_only'
            data:
                form_name: form
                entity_data: entity_data
        ).then (data) ->
            if iframe.contentWindow
                iframe.contentWindow.document.open()
                iframe.contentWindow.document.write data
                iframe.contentWindow.document.close()

    if !$('body').hasClass('tab-listener')
        $('body').on 'shown.bs.tab', (e) ->
            $('.tab-pane.active iframe').filter('[data-dynamic-form]').each (index, iframe) =>
                iframe.height = iframe.contentWindow.document.body.scrollHeight + 'px'
        $('body').addClass 'tab-listener'

    return

render_box = (review_packet) ->
    options = review_packet.get("options")

    render_target = options.render_target or "#review-packet-container"

    allow_snoozing = review_packet.allow_snoozing()

    $(render_target).html box_template
        review_packet_header_label: options.review_packet_header_label
        review_packet_header_label_token: options.review_packet_header_label_token
        artifact_header_label: options.artifact_header_label
        artifact_header_label_token: options.artifact_header_label_token
        show_artifact_header: !!BL.Language.get(options.artifact_header_label_token, options.artifact_header_label)
        documents_enabled: review_packet.feature_visible "documents"
        notes_enabled: review_packet.feature_visible "notes"
        checklist_enabled: options.checklist && review_packet.feature_visible "checklist"
        production_environment: review_packet.production_environment()
        show_security_warning: review_packet.show_security_warning()
        allow_snoozing: allow_snoozing
        snooze_options: _snooze_options
        previous_packet: options.previous_packet
        next_packet: options.next_packet
        current_packet: options.current_packet
        total_packets: options.total_packets

    if allow_snoozing
        _wire_security_alert_snooze_buttons review_packet

    renderers = get_renderers(review_packet.get("type"))
    overview_section = renderers.overview
    actions_section = renderers.actions
    details_section = renderers.details

    render_sections = (review_packet) ->
        $.when(
            actions_section review_packet,
            checklist_section review_packet,
            overview_section review_packet,
            documents_section review_packet
            notes_section review_packet
            details_section review_packet)
        .then () ->
            _update_dynamic_form_iframes review_packet

    render_sections review_packet

    review_packet.on "change:status", ->
        orb.flash_success BL.Language.get("L001379", "Review packet status was updated successfully.")

        # Refetch the packet to ensure any changes effected by server side
        # processing — allowed_actions updates from the server security
        # model, modifications to the review packet or associated entities
        # by server event listeners, etc. — are correctly reflected here.
        review_packet.fetch({cache: false}).done ->
            render_sections review_packet


get_nav_options = (packet_id) ->
    nav_options = {}
    if sessionStorage.getItem('review_packets:review_packet_nav_list')
        _packet_ids = JSON.parse(sessionStorage.getItem('review_packets:review_packet_nav_list'))
        sessionStorage.removeItem('review_packets:review_packet_nav_list');

    if _packet_ids != null
        index = _.findIndex(_packet_ids, (item) ->
            Number(packet_id) == item
        )
        if index != -1
            pathname = $(location).attr('pathname') + $(location).attr('hash')
            href = pathname.substring(0, pathname.lastIndexOf('/') + 1)
            if index != 0
                nav_options['previous_packet'] = href + _packet_ids[index - 1]
            if index != _packet_ids.length-1
                nav_options['next_packet'] = href + _packet_ids[index + 1]
            nav_options['current_packet'] = index + 1
            nav_options['total_packets'] = _packet_ids.length
    return nav_options


render_review_packet = (options) ->
    ### Render a review packet with the provided ID

    Given a review packet ID (as options.id), render the packet, including any
    type-specific customizations (see README.rst for full details).

    If a type-specific acl.yaml is defined for this review packet on S3, the
    definition is loaded, evaluated, and the corresponding options
    override the options passed to this function. Otherwise, if a type-specific
    renderer is defined for this review packet on S3,
    the definition is loaded, evaluated, and the corresponding options
    override the options passed to this function. If a renderer definition
    exists on S3 but has syntactic or other errors, a message is shown to the
    user and rendering is aborted.

    If no type-specific renderer is defined for this review packet, the core
    rendering machinery will be invoked, taking into account imperatively
    registered renderers and render callbacks passed via `options`.

    Session storage is used to communicate navigation options to the renderer
    in order to bypass querystring limitations.  The caller will set the
    session storage key 'review_packets:review_packet_nav_list' to a
    stringified list of review packet ids.  These will be parsed back into a
    list, the session key cleared, and the list assigned to a global variable
    immediately before rendering the packet.  The packet id and the list of
    packets will be used to render the next and previous buttons and the
    current location in the list.  The packet list will persist until the
    browser is refreshed or another list is provided.

    ###

    review_packet = new ReviewPacket
        id: options.id

    review_packet.fetch({cache: false})
        .done ->
            acl_yaml = null
            renderer_options = null
            templates.fetch(review_packet.get("type"), "renderer.coffee")
                .done (renderer_src) ->
                    # These local variables establish the context for the
                    # eval call below: each variable defined here is available
                    # for use by the `renderer.coffee` script; see:
                    #
                    # https://www.bennadel.com/blog/1926-exploring-javascript-s-eval-capabilities-and-closure-scoping.htm
                    #
                    confirm = require "orb/confirm_modal"
                    form = require "orb/dynamic_form"
                    action_handlers = additional_action_handlers
                    orb = orb
                    module = {}

                    try
                        eval(coffeescript.compile(renderer_src, {bare: true}))
                    catch e
                        message = "Invalid renderer definition, packet cannot \
                                   be rendered.<br/><br/>Original error \
                                   was: #{e}"
                        orb.flash_error message, null
                        return
                    get_options = module.exports.get_options or module.exports
                    renderer_options = get_options()

                .always ->
                    templates.fetch(review_packet.get("type"), "acl.yaml")
                        .done (acl_src) ->
                            try
                                acl_yaml = js_yaml.load(acl_src)
                            catch e
                                message = "Invalid acl yaml definition, packet cannot \
                                           be rendered.<br/><br/>Original error \
                                           was: #{e}"
                                orb.flash_error message, null
                                return
                        .always ->
                            # TODO COREBT-13522: This is to prevent actions in
                            # additional_actions override button label of the
                            # same action defined in acl.yaml. We should be able
                            # to move this bit after converting all existing
                            # clients to explicit permission spelling.
                            if acl_yaml and renderer_options
                                delete renderer_options.additional_actions

                            options = _.extend(
                                {},
                                options,
                                renderer_options,
                                acl_yaml,
                                get_nav_options(options.id)
                            )

                            review_packet.set "options", options
                            render_box review_packet
                            _rewire_link_behavior()
        .fail ->
            # probably an authorization failure
            orb.flash_error BL.Language.get("L001380", "Could not load requested review packet.")
            $(".rp-box").remove()


module.exports =
    "render": render_review_packet
    "register_renderers": register_renderers
