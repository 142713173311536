# The core NextGen library, available as window.NextGen on pages that load
# /static/build/NextGen.js

_ = require "underscore"
Backbone = require "backbone"


metadata_setup = require "./system/metadata"
dynamic_table = require "./dynamic_table"
review_packet = require "./review_packet/review_packet"
extra_handlers = require "./extra_handlers"
components = require "./components"
credentials = require "./credentials"
credential_type_editor = require "./credentials/editor"
provider_user_manager = require "./providers/provider_users/manager"
portal = require "./portal"
merge = require "./merge"
api_gateway_client = require('orb/aws/api_gateway_client')
system = require('orb/system')
loadjs = require('loadjs')

# If this is not a hybrid page, make jquery accessible on the window so that
# bootstrap can attach to it.
if not window.jQuery
    window.jQuery = $;
else
    loadjs(['/static/javascript/bootstrap-confirmation.js'], {
        success: ->
            # copy the confirmation plugin to the local instance
            $.fn.confirmation = window.jQuery.fn.confirmation
        error: ->
            console.log('bootstrap-confirmation failed to load')
    });


unless String.prototype.format
    String.prototype.format = (col) ->
        ### Simple string replacement based templating

        Define a format utility on strings to allow simple replacement based
        templating. Template formats and replacement values can either be
        specified positionally

            "{0} {1} {{not a variable}} {0}".format("!", "I am")

        or by name with an Object argument

            "sna{sna}".format({sna: "fu"})

        ###

        col = if _.isObject col then col else Array.prototype.slice.call(arguments, 0)

        this.replace /\{\{|\}\}|\{(\w+)\}/g, (m, n) ->
            switch m
                when "{{" then "{"
                when "}}" then "}"
                else col[n]


# Functions and utitilites for initializing the NextGen environment etc.
setup =
    update_metadata: (metadata) ->
        ### Create or update NextGen.metadata

        Given an Object of system metadata, update the system metadata
        registry at `window.NextGen.metadata` with the supplied values.

        ###

        metadata_setup.update_metadata metadata

    initialize_bootstrap: ->
        $('<link rel="stylesheet" href="/static/css/bl_branding_color.css" />')
            .appendTo "head"

    register_extra_handlers: ->
        extra_handlers.register()

    start_router: (options) ->
        Router = Backbone.Router.extend
            routes:
                # these routes should be the top-level constructs available
                # in the NextGen app, e.g.
                #
                # "app/zoom/:zoom_type": "dynamic_zoom"  # render dynamic zoom
                # "app/nav/:nav_type": "dynamic_nav"  # render dynamic nav

                "app/": "landing_page"
                "app/tables/:table_type/*args": "dynamic_table"
                "app/review_packets/:review_packet_id": "review_packet"
                "app/dashboard(/)(:page)(/)(*args)": "dashboard"
                "app/system/merge(?)(user_one=*user_one)(&user_two=*user_two)": "merge"
                "app/credential_type/(:credential_id)/editor": "credential_type_editor"
                "app/providers/users/(:user_id)/manager": "provider_user_manager"

            execute: (callback, args, name) ->
                ### Ensure site structure exists before invoking route handlers

                Callback wrapper that establishes the site structure, if
                necessary, before forwarding route requests to the appropriate
                handler.

                If the user has been logged out, unilaterally redirect them
                to the login page.

                ###

                if not NextGen.metadata.user.id
                    window.location = "/login"

                content_only = false

                if 'content_only=true' in args
                    content_only = true
                    args = args.filter (e) ->
                        e != 'content_only=true'

                this._ensure_structure content_only
                .then () ->
                    if callback
                        callback.apply this, args

            _ensure_structure: (content_only) ->
                if this._structure_rendered
                    return

                portal.render
                    show_header: !content_only
                    show_top_nav: !content_only

                promise = jQuery.Deferred()
                has_bootstrap = () ->
                    if window.bootstrap
                        loadjs(['/static/javascript/bootstrap-confirmation.js'], {
                            success: ->
                                this._structure_rendered = true
                                promise.resolve()
                            error: ->
                                console.log('bootstrap-confirmation failed to load')
                        });
                    else
                        setTimeout(has_bootstrap, 20)

                has_bootstrap(promise)
                return promise


            landing_page: ->
                portal.render_landing_page
                    render_target: "#app-content"

            dashboard: ->
                Backbone.history.navigate "/app/", {trigger: true, replace: true}

            dynamic_table: (table_type, args) ->
                dynamic_table.render
                    table_type: table_type
                    args: args
                    render_target: "#app-content"

            review_packet: (review_packet_id) ->
                review_packet.render
                    id: review_packet_id
                    render_target: "#app-content"

            merge: (user_one, user_two) ->
                merge.render
                    user_one: user_one
                    user_two: user_two
                    render_target: "#app-content"

            credential_type_editor: (id) ->
                credential_type_editor.render
                    render_target: "#app-content"
                    credential_type: id

            provider_user_manager: (id) ->
                provider_user_manager.render
                    render_target: "#app-content"
                    user_id: id

        router = new Router()
        Backbone.history.start
            pushState: true

        ### Enable SPA client-side routing

        Apparently the Router does _not_ automatically prevent hard page
        reloads when navigating via anchor tags; the app must prevent
        the browser default behavior and forward navigation requests to
        Backbone. For Clarus NextGen, we intercept and forward all browser
        navigation changes unless

        * the user is pressing a modifier key, e.g. to open the link in
          a new tab

        * the a tag has a `data-bypass` attribute defined to indicate
          that we wish to _force_ a hard reload

        This recipe draws from several sources

        * http://artsy.github.io/blog/2012/06/25/replacing-hashbang-routes-with-pushstate/#Internal.Links
        * https://stackoverflow.com/questions/9328513/backbone-js-and-pushstate/9331734#9331734
        * https://stackoverflow.com/questions/7640362/preventing-full-page-reload-on-backbone-pushstate
        * https://github.com/tbranyen/backbone-boilerplate.git (although this
          project has since switchted to an alternate mechanism using almond (?))

        Since we're in hipster JavaScript land, there are only about a
        billion different ways to accomplish this task (with, of course,
        no consensus):

            https://www.npmjs.com/browse/keyword/pushstate

        This recipe gets the job done for us for now. Moving on.

        ###

        $(document).on "click", "a[href]:not([data-bypass])", (e) ->
            # Allow shift+click for new tabs, etc.
            if e.altKey or e.ctrlKey or e.metaKey or e.shiftKey
                return
            # Don't mess with links that have already been handled
            else if e.isDefaultPrevented()
                return

            e.preventDefault()
            Backbone.history.navigate $(this).attr("href"), {trigger: true}


module.exports =
    setup: setup
    review_packet: review_packet
    components: components
    credentials: credentials
    portal: portal
    aws_api_gateway_client: api_gateway_client
    system: system
