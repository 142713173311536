var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"uuid") || (depth0 != null ? lookupProperty(depth0,"uuid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"uuid","hash":{},"data":data,"loc":{"start":{"line":16,"column":47},"end":{"line":16,"column":55}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":16,"column":57},"end":{"line":16,"column":65}}}) : helper)))
    + "</option>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "disabled=\"disabled\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n    <div class=\"add-credential-modal modal fade\" id=\"myModal\" tabindex=\"-1\" aria-labelledby=\"myModalLabel\">\n        <div class=\"modal-dialog\">\n            <div class=\"modal-content\">\n                <form class=\"form\" autocomplete=\"off\">\n                    <div class=\"modal-header\">\n                       <h4 class=\"modal-title\" id=\"myModalLabel\">Grant Credential</h4>\n                       <button type=\"button\" class=\"btn-close\" data-bs-dismiss=\"modal\" aria-label=\"Close\"></button>\n                    </div>\n                    <div class=\"modal-body\">\n                        <div class=\"mb-3\">\n                            <label for=\"credential_type_id\" class=\"form-label\">Credential Type</label>\n                            <select name=\"credential_type_id\" style=\"width: 100%\" class=\"form-select\" title=\"Select a Credential to grant\" required=\"required\">\n                                <option></option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"available_types") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":32},"end":{"line":17,"column":41}}})) != null ? stack1 : "")
    + "                            </select>\n                        </div>\n\n                        <div class=\"mb-3\">\n                            <label for=\"issued_on\" class=\"form-label\">Issued on <small class=\"text-muted\">(optional)</small></label>\n                            <input name=\"issued_on\" class=\"form-control\" placeholder=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"today") || (depth0 != null ? lookupProperty(depth0,"today") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"today","hash":{},"data":data,"loc":{"start":{"line":23,"column":86},"end":{"line":23,"column":95}}}) : helper)))
    + "\" data-provide=\"datepicker\" data-date-format=\"yyyy-mm-dd\" data-date-autoclose=\"true\" data-date-today-highlight=\"true\">\n                        </div>\n\n                        <a class=\"text-decoration-none\" data-bs-toggle=\"collapse\" href=\"#advanced\" role=\"button\" aria-expanded=\"false\" aria-controls=\"advanced\">\n                            <i class=\"fa-solid fa-chevron-down me-1\"></i> Advanced Options\n                        </a>\n\n                        <div class=\"collapse mt-3\" id=\"advanced\">\n                            <div class=\"mb-3\">\n                                <label for=\"effective_renewal_date\" class=\"form-label\">Effective renewal date <small class=\"text-muted\">(optional)</small></label>\n                                <input name=\"effective_renewal_date\" class=\"form-control\" placeholder=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"today") || (depth0 != null ? lookupProperty(depth0,"today") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"today","hash":{},"data":data,"loc":{"start":{"line":33,"column":103},"end":{"line":33,"column":112}}}) : helper)))
    + "\" data-provide=\"datepicker\" data-date-format=\"yyyy-mm-dd\" data-date-autoclose=\"true\" data-date-today-highlight=\"true\">\n                            </div>\n                            <div class=\"mb-3\">\n                                <label for=\"ignore_facts_before\" class=\"form-label\">Ignore facts before <small class=\"text-muted\">(optional)</small></label>\n                                <input name=\"ignore_facts_before\" class=\"form-control\" placeholder=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"today") || (depth0 != null ? lookupProperty(depth0,"today") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"today","hash":{},"data":data,"loc":{"start":{"line":37,"column":100},"end":{"line":37,"column":109}}}) : helper)))
    + "\" data-provide=\"datepicker\" data-date-format=\"yyyy-mm-dd\" data-date-autoclose=\"true\" data-date-today-highlight=\"true\">\n                            </div>\n                        </div>\n                    </div>\n                    <div class=\"modal-footer\">\n                        <button type=\"button\" class=\"btn btn-secondary\" data-bs-dismiss=\"modal\">Close</button>\n                        <button type=\"submit\" class=\"btn btn-primary\">Save changes</button>\n                    </div>\n                </form>\n            </div>\n        </div>\n    </div>\n\n    <button class=\"add-credential-action btn btn-primary\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"available_types") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":58},"end":{"line":50,"column":115}}})) != null ? stack1 : "")
    + ">\n        <i class=\"fa-solid fa-plus me-2\"></i> Add Credential\n    </button>\n</div>\n";
},"useData":true});