_ = require "underscore"
clarity = require "orb/system/clarity_client"
clarus = require "orb/system/clarus_client"
requirement_editor_templ = require("!!raw-loader!./requirements_editor.mustache").default

requirements_library = null
exam_types = null
credential_types = null

type_labels =
        "exam_result": "<span class='badge fs-6 bg-primary'>Result</span>"
        "credential_in_status": "<span class='badge fs-6 bg-info'>Credential</span>"
        "purchase": "<span class='badge fs-6 bg-success'>Purchase</span>"
        "review_packet": "<span class='badge fs-6 bg-secondary'>Review Packet</span>"
        "ce": "<span class='badge fs-6 bg-secondary'>CE</span>"


get_requirements_library = (refresh=false) ->
    promise = $.Deferred()

    if not requirements_library or refresh
        clarity.get_credential_requirements().then (data) ->
            requirements_library = _.indexBy data, "slug"
            promise.resolve requirements_library
    else
        promise.resolve requirements_library


get_exam_types = (refresh=false) ->
    promise = $.Deferred()

    if not exam_types or refresh
        clarus.get_exam_types().then (data) ->
            exam_types = data.exam_types
    else
        promise.resolve exam_types


_library_display_for_requirement = (requirement) ->
    library_display = ''
    library_entry = requirements_library[requirement]
    if library_entry
        display_name = BL.Language.get "RL:#{requirement}", library_entry.name
        if library_entry.hyperlink
            library_display = """<a href="#{library_entry.hyperlink}"
                               class="py-2"
                               data-bs-toggle="tooltip"
                               data-placement="top"
                               title="<h6>#{library_entry.hyperlink}</h6>">
                              #{display_name}
                              </a>"""
        else
            library_display = display_name
    return library_display


_label_for_requirement = (requirement) ->
    type = requirement.split(':')[0]
    return type_labels[type]


get_timeline_button = (preview_data, status) ->
    display_name = preview_data[status].display_name || status
    effective_date = if preview_data[status].date then "<br/>Effective date: #{preview_data[status].date}" else ""
    new_button = $("<button type=\"button\"
                   class=\"btn btn-outline-secondary btn-sm align-top\" data-key=#{status}>
                    #{status}<div>Display Name: #{display_name}
                    #{effective_date}</div></button>")
    return new_button


_requirement_not_exist = (requirement) ->
    exists = true
    if requirement.startsWith 'exam_result:'
        exists = _.find exam_types, (exam_type) ->
            exam_type.slug == requirement.split(':')[1]
    else if requirement.startsWith 'credential_in_status:'
        exists = _.find credential_types, (credential_type) ->
            credential_type.slug == requirement.split(':')[1]

    if not exists
        return true


get_invalid_requirements = (requirements) ->
    _.filter requirements, (requirement) ->
        _requirement_not_exist(requirement)


populate_requirement_row = (row, requirement) ->
    row.insertCell(0).innerHTML = _label_for_requirement requirement
    row.insertCell(1).appendChild document.createTextNode requirement
    row.insertCell(2).innerHTML = _library_display_for_requirement requirement

    requirement_entry = requirements_library[requirement]
    if requirement_entry
        row.insertCell(3).innerHTML = """
            <span data-rel="popover" data-bs-toggle="popover">
                <button class="btn btn-link requirement-edit-btn"
                data-slug="#{requirement}" data-uuid="#{requirement_entry.uuid}"
                data-name="#{requirement_entry.name}" data-hyperlink="#{requirement_entry.hyperlink}"
                data-bs-toggle="tooltip" title="Update requirement <b>#{requirement}</b>">
                    <span class="fa-solid fa-pencil text-primary "></span>
                </button>
            </span>
        """
    else
        row.insertCell(3).innerHTML = """
            <span data-rel="popover" data-bs-toggle="popover">
                <button class="btn btn-link requirement-edit-btn"
                data-slug="#{requirement}"
                data-bs-toggle="tooltip"
                title="Add requirement <b>#{requirement}</b>">
                    <span class="fa-solid fa-plus text-primary "></span>
                </button>
            </span>
        """

    if _requirement_not_exist requirement
        $(row).addClass 'table-danger'

        if requirement.startsWith 'exam_result:'
            slug = requirement.replace 'exam_result:', ''
            cell = row.cells[1]
            cell.innerHTML = """
                #{cell.innerText} <button class="btn btn-link add-exam-type-btn"
                data-slug="#{slug}" data-bs-toggle="tooltip" title="Add exam type">
                <span class="fa-solid fa-circle-plus text-primary"></span>
                </button>
            """
            row.cells[3].innerHTML = ''
    _setup_requirement_editor_row row

_setup_requirement_editor_row = (row) ->
    tooltips = $(row).find('[data-bs-toggle="tooltip"]')

    tooltipList = [...tooltips].map((element) ->
        new bootstrap.Tooltip(element, {
            html: true
            trigger: 'hover'
        }))

    popover_trigger = $(row).find('[data-rel="popover"]')

    popover_trigger.popover
        container: popover_trigger
        placement: 'left'
        trigger: 'focus'
        content: requirement_editor_templ
        html: true
        sanitize: false
    .on 'shown.bs.popover', (e) ->
        trigger_btn = $(e.target).find('button')[0]
        popoverElement = bootstrap.Popover.getInstance(e.target).tip
        $popoverElement = $(popoverElement)

        $popoverElement.find('[name=name]').val(trigger_btn.dataset.name)
        $popoverElement.find('[name=slug]').val(trigger_btn.dataset.slug)
        $popoverElement.find('[name=hyperlink]').val(trigger_btn.dataset.hyperlink)
        $popoverElement.find('[name=uuid]').val(trigger_btn.dataset.uuid)

        # Prevent clicks on popover body from causing the popover to close
        $('.popover').on 'mousedown', (e) ->
            if $(e.target).closest('.popover').length and
                not $(e.target).is('input, button, select, textarea')
                    e.preventDefault()
                    e.stopPropagation()

        $(row).find('.popover-close').on 'click', (e) ->
            popover_trigger.popover('hide');

        $(row).find('[data-rel="popover"]').find('form').on 'submit', (e) ->
            e.preventDefault()
            popover_trigger.popover('hide');

            $(row).trigger {
                type: 'requirement-form-submit'
                uuid: $(this).find('[name=uuid]').val()
                name: $(this).find('[name=name]').val()
                hyperlink: $(this).find('[name=hyperlink]').val()
                slug: $(this).find('[name=slug]').val()
            }


_get_exam_requirement_completer = (exam_types) ->
    examCompleter = getCompletions: (editor, session, pos, prefix, callback) ->
        result = []
        result.push.apply(result, exam_types.map((exam_type) ->
            {
                caption: 'Exam Result: ' + exam_type.abbr
                value: 'exam_result:' + exam_type.slug
                meta: 'requirement'
                score: 1000
            })
        )
        result.push.apply(result, exam_types.map((exam_type) ->
            {
                caption: 'exam_result:' + exam_type.slug
                value: 'exam_result:' + exam_type.slug
                meta: 'requirement'
                score: 1000
            })
        )
        callback null, result
    return examCompleter


_get_credential_requirement_completer = (credential_types) ->
    credentialCompleter = getCompletions: (editor, session, pos, prefix, callback) ->
        result = []
        result.push.apply(result, credential_types.map((credential_type) ->
            {
                caption: 'Credential: ' + credential_type.abbr
                value: 'credential_in_status:' + credential_type.slug
                meta: 'requirement'
                score: 1000
            })
        )
        result.push.apply(result, credential_types.map((credential_type) ->
            {
                caption: 'credential_in_status:' + credential_type.slug
                value: 'credential_in_status:' + credential_type.slug
                meta: 'requirement'
                score: 1000
            })
        )
        callback null, result
    return credentialCompleter


get_ace_completers = () ->
    promise = $.Deferred()

    $.when(
        clarus.get_exam_types(),
        clarity.get_credential_types()
    ).then (exam_type_data, credential_type_data) ->
        exam_types = exam_type_data[0].exam_types
        exam_completer = _get_exam_requirement_completer(exam_types)
        credential_types = credential_type_data[0]
        credential_completer = _get_credential_requirement_completer(credential_types)

        promise.resolve([exam_completer, credential_completer])


get_user_info = (user_uuid) ->
    promise = $.Deferred()
    $.get("/api/v2/users/uuid=#{user_uuid}")
        .then (data) ->
            promise.resolve data
        .fail (data) ->
            promise.resolve {}
    promise


module.exports =
    "get_requirements_library": get_requirements_library
    "get_exam_types": get_exam_types
    "get_timeline_button": get_timeline_button
    "populate_requirement_row": populate_requirement_row
    "get_ace_completers": get_ace_completers
    "get_invalid_requirements": get_invalid_requirements
    "get_user_info": get_user_info
