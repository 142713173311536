var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<table class=\"table table-striped\" id=\"rp-notes-table\">\n    <thead>\n        <tr>\n            <th class=\"col-sm-3 col-md-2 small\">"
    + alias2(__default(require("../../handlebars_helpers/lang.coffee")).call(alias1,"L001356","Timestamp",{"name":"lang","hash":{},"data":data,"loc":{"start":{"line":4,"column":48},"end":{"line":4,"column":78}}}))
    + "</th>\n            <th class=\"col-4 col-md-2 small\">"
    + alias2(__default(require("../../handlebars_helpers/lang.coffee")).call(alias1,"L001357","User",{"name":"lang","hash":{},"data":data,"loc":{"start":{"line":5,"column":45},"end":{"line":5,"column":70}}}))
    + "</th>\n            <th class=\"col-6 col-md-4 small\">"
    + alias2(__default(require("../../handlebars_helpers/lang.coffee")).call(alias1,"L001358","Note",{"name":"lang","hash":{},"data":data,"loc":{"start":{"line":6,"column":45},"end":{"line":6,"column":70}}}))
    + "</th>\n        </tr>\n    </thead>\n</table>\n";
},"useData":true});