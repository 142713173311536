# FIXME: this feels like something we could find an implementation of rather
# than building our own.


modal_template = require "./confirm_modal_template.mustache"


no_op = ->
    true


dialog_defaults =
    modal_selector: "span#confirm-modal"
    modal_id: null
    title: "<b>Confirm</b>"
    content: "Are you sure?"
    confirm_btn_class: "btn-primary"
    confirm_btn_label: "OK"
    confirm_custom_attribute: null
    dismiss_btn_class: "btn-default"
    dismiss_btn_label: "Cancel"
    ok_callback: no_op
    cancel_callback: no_op


show_confirmation_dialog = (options) ->
    ### Show a generic 2-button confirmation dialog.

    Accepts an options parameter with these arguments (values shown are
    defaults):

        modal_selector: "span#confirm-modal"
        modal_id: null
        content: "Are you sure?"
        confirm_btn_class: "btn-primary"
        confirm_btn_label: "OK"
        confirm_custom_attribute: null
        dismiss_btn_class: "btn-default"
        dismiss_btn_label: "Cancel"
        ok_callback: no_op
        cancel_callback: no_op

    Returns the modal object (jQuery).

    ###

    options = $.extend({}, dialog_defaults, options or {})

    modal = $(options.modal_selector).html(modal_template options)
                .children().modal 'show'

    modal.find("button.modal-confirm").on "click", options.ok_callback
    modal.find("button.modal-cancel").on "click", options.cancel_callback

    modal


module.exports = show_confirmation_dialog
