require "datatables.net"
require "datatables.net-bs5"
_ = require "underscore"


orb = require "orb"

note_editor_template = require "./note_editor.mustache"
notes_history_template = require "./notes_history.mustache"
single_note_entry_template = require "./single_note_entry.mustache"


note_history_table = null


reload_note_table = ->
    note_history_table?.ajax.reload()


render_notes_history = (review_packet, note_url) ->
    $("#rp-notes-history").html(notes_history_template())

    show_internal = review_packet.has_permission "private_notes_view"

    process_notes = (data) ->
        if show_internal
            data.notes
        else
            (note for note in data.notes when not note.internal)

    note_history_table = $("#rp-notes-table").DataTable({
        ajax:
            url: note_url
            dataSrc: process_notes
        columns: [
            {
                data: "timestamp"
                className: "dt-body-left dt-head-left"
            }
            {data: "user"}
            {
                data: "note",
                render: (note, type, data, meta) ->
                    single_note_entry_template({
                        note: note,
                        id: data.id,
                        internal: data.internal,
                        show_public_label: show_internal and not data.internal
                    })
            }
        ]
        order: [[ 0, "desc" ]]
        paging: false
        searching: false
        info: false
        autoWidth: false
        language: {
            emptyTable: BL.Language.get('L001404', 'There are no notes yet on this review packet.')
            loadingRecords: BL.Language.get("L001308", "Loading...")
        }
    })


render_note_editor = (review_packet, note_url) ->
    if not review_packet.has_permission "notes_add"
        $("#rp-note-editor").remove()
        return

    $("#rp-note-editor").html(note_editor_template({
        can_create_internal_note: review_packet.has_permission "private_notes_view"
        submit_url: note_url
    }))

    search_key = "user[#{NextGen.metadata.user.id}]:review_packet[#{review_packet.id}]:note"
    note_box = $('textarea[name="note"]')

    initial_note = sessionStorage.getItem(search_key)
    if initial_note
        note_box.val(initial_note)

    $("#private-note-toggle").bootstrapToggle({
        onlabel: BL.Language.get("L001359", "Internal"),
        offlabel: BL.Language.get("L001360", "Public"),
        onstyle: "secondary",
        offstyle: "warning",
    })

    save_note = ->
        sessionStorage.setItem(search_key, note_box.val())

    note_box.on('input', _.debounce save_note, 250)

    $('form#rp-note-editor').on 'submit', (event) ->
        event.preventDefault()

        url = $('form#rp-note-editor').prop('action')
        if not url
            orb.flash_error BL.Language.get('L001375', 'An error occurred when trying to update the note.')
            return

        $.ajax
            method: $('form#rp-note-editor').prop('method') or 'POST'
            url: url
            data: $(this).serialize()
            dataType: 'json'
            success: ->
                note_box.val('')
                sessionStorage.removeItem(search_key)
                note_history_table.ajax.reload()
            error: ->
                orb.flash_error BL.Language.get("L001375", "An error occurred when trying to update the note.")


render_notes = (review_packet) ->
    if not review_packet.feature_visible "notes"
        return

    note_url = review_packet.url() + "/notes"

    render_notes_history review_packet, note_url
    render_note_editor(review_packet, note_url)


module.exports =
    render_notes: render_notes
    reload_note_table: reload_note_table
