handlebars = require "handlebars/dist/handlebars"
require "orb/handlebars_helpers"

templates = require "orb/templates"
loading = require "../loading_dots.mustache"
forms = require "./forms"
data_helpers = require "orb/components/data_helpers"
events = require "./events"
csv = require "./csv"


get_token = data_helpers.get_token
request = data_helpers.request

_template = null

_parameters = null
_page_name = null
_require_authentication = null
_data_url = null


_fetch_renderer = (page_name, require_authentication) ->
    promise = $.Deferred()

    if _template
        promise.resolve _template
        return promise

    template_request =
        templates
            .fetch_page_template(page_name, require_authentication)
            .done (src) ->
                _template = handlebars.compile(src)
                promise.resolve _template
            .fail () ->
                promise.reject()
    promise


_fetch_data = (page_name, parameters) ->
    # Try to get the custom data service override, then the default function.
    # If neither succeed, return success anyway to render the template without
    # data to support static content.  If either override or default data
    # source succeed then the next request will go directly to that one

    host = NextGen.metadata.services_host
    client = NextGen.metadata.client_id.toLowerCase()
    url = "https://#{host}.clarus-servic.es/data/#{client}/pages/#{page_name}"
    default_url = "https://#{host}.clarus-servic.es/data/default/pages/#{page_name}"

    promise = $.Deferred()

    data_helpers.get_token().then (data) ->
        if _data_url
            request(data.access_token, _data_url, parameters)
            .then (result) ->
                promise.resolve(result)
        else
            request(data.access_token, url, parameters)
            .then (result) ->
                _data_url = url
                promise.resolve(result)
            .fail () ->
                request(data.access_token, default_url, parameters)
                .then (result) ->
                    _data_url = default_url
                    promise.resolve(result)
                .fail () ->
                    promise.resolve()

    return promise


_setup_page = (page_name, parameters, use_form, require_authentication) ->
    parameters = parameters || {}
    _page_name = page_name
    _require_authentication = require_authentication

    if use_form
        forms.load_form(_page_name, parameters, _refresh_page_content)

    events.register_events _refresh_page_content



_refresh_page_content = (parameters, offset) ->
    if parameters
        _parameters = parameters

    $('#page-content').html(loading)
    $('#options')[0].disabled = true

    if not isNaN offset
        _parameters.offset = offset

    renderer_request = _fetch_renderer _page_name, _require_authentication
    data_request = _fetch_data _page_name, _parameters

    $.when(renderer_request, data_request)
    .then (renderer, data) ->
        try
            if data
                data = data['data']
            else
                data = {}
            data['_metadata'] = NextGen.metadata
            filled_template = renderer(
                data: data || {})
            $('#page-content').fadeOut(() ->
                $(this).html(filled_template).fadeIn(() ->
                    $('#options')[0].disabled = false))
        catch e
            console.log "Error rendering dynamic page content.  #{e}"

render_page = (page_name, parameters, use_form, require_authentication) ->
    _setup_page page_name, parameters, use_form, require_authentication
    _refresh_page_content(parameters)

module.exports =
    render_page: render_page
    initialize_csv_uploader: csv.init