_ = require "underscore"
handlebars = require "handlebars/dist/handlebars"

clarity = require "orb/system/clarity_client"
templates = require "orb/templates"
render_credential_certificate = require("./index").render_credential_certificate
render_my_credentials = require "./my_credentials_default.mustache"


_fetch_renderer = ->
    promise = $.Deferred()

    template_request =
        templates
            .fetch("dynamic_views/portal", "my_credentials")
            .done (src) ->
                promise.resolve handlebars.compile(src)
            .fail ->
                promise.resolve render_my_credentials

    promise


show_my_credentials = (candidate_data, selector) ->
    user = window.NextGen.metadata.user.uuid
    user_credentials_request =
        clarity.get_credentials user, "display_expiration,display_status", true, true, "custom_data"

    renderer_request = _fetch_renderer()

    $.when(user_credentials_request, renderer_request).done (credentials, renderer) ->
        credentials = _.sortBy credentials[0], "credential_type_abbr"
        credentials_map = _.indexBy credentials, "uuid"

        $(selector).html(renderer(
            candidate: candidate_data
            credentials: credentials
            _metadata: NextGen.metadata
        ))

        $('[data-bs-toggle="tooltip"]').each (index, element) ->
            new bootstrap.Tooltip(element)

        $("#credentials").on "click", ".generate-certificate", ->
            button = $(this)
            uuid = button.data("uuid")
            credential = credentials_map[uuid]

            if button.hasClass "disabled"
                return

            button.addClass("disabled")
            $(button).find("i")
                .removeClass("fa-download")
                .addClass("fa-spinner fa-spin")

            render_credential_certificate(
                credential.credential_type_uuid,
                candidate_data,
                credential,
                full_name=NextGen.metadata.user.name,
                credential_date=credential.issued_on,
                credential_number=credential.number,
                credential_type_name=credential.credential_type_name,
                credential_type_abbr=credential.credential_type_abbr
            ).catch( (error) ->
                # Catch any errors to make sure that we re-enable the download button
            ).then( (result) ->
                button.removeClass("disabled").blur()
                $(button).find("i")
                    .removeClass("fa-spinner fa-spin")
                    .addClass("fa-download")
            )

        $("#credentials").on "click", ".download-assets", ->
            button = $(this)
            uuid = button.data("uuid")
            credential = credentials_map[uuid]

            if button.hasClass "disabled"
                return

            button.addClass("disabled")
            old_content = button[0].innerHTML
            button[0].innerHTML = "<i class='fa-spinner fa-spin fa'></i>"

            clarity.get_credential_assets_link(user, uuid
            ).catch( (error) ->
            ).then (data) ->
                  link = document.createElement('a')
                  link.href = data.asset_url
                  link.target = "_blank"  # for documents that will open automatically
                  document.body.appendChild link
                  link.click()
                  document.body.removeChild link
                  button[0].innerHTML = old_content
                  button.removeClass "disabled"
                  bootstrap.Tooltip.getInstance(button).hide()




module.exports = show_my_credentials
