# Review packet functional core

_ = require "underscore"
Backbone = require "backbone"


### The default subfeature permissions for review packets.

This object defines the default client-side permissions for review
packet subfeatures — currently documents, notes, and review.

###

# TODO COREBT-13522: Remove default actions when existing clients have retired
# old renderer.coffee syntax.
default_permissions =
    documents_view:
        available_to: ["system.Everyone"]

    notes_view:
        available_to: ["system.Everyone"]
    private_notes_view:
        available_to: ["group:staff", "group:admin"]
    notes_add:
        available_to: ["group:staff", "group:admin"]

    reviewers_view:
        available_to: ["group:staff", "group:admin"]

    checklist_view:
        available_to: ["group:staff", "group:admin"]


### The default workflow for review packets.

This object defines the default workflow for review packets, specifying
the state machine in terms of the actions that are available at each
workflow state/review packet status.

See render_actions below for the definition of an action.

###


# TODO COREBT-13522: Remove default actions when existing clients have retired
# old renderer.coffee syntax.
default_actions =
    approve:
        name: "approve"
        action: "approved"
        label: "Approve"
        label_token: ""
        confirm_btn_class: "btn-success"
        available_when: "pending_review"
        available_to: ["group:admin", "group:staff"]

    deny:
        name: "deny"
        action: "denied"
        label: "Deny"
        label_token: ""
        confirm_btn_class: "btn-danger"
        available_when: "pending_review"
        available_to: ["group:admin", "group:staff"]

    withdraw:
        name: "withdraw"
        action: "withdrawn"
        label: "Withdraw"
        label_token: ""
        confirm_btn_class: "btn-warning"
        available_when: "pending_review"
        available_to: ["group:admin", "group:staff"]


# Not all browsers support string.startsWith, most importantly PhantomJS
__permission_prefix = /^permission:/
__starts_with_permission = (s) ->
    __permission_prefix.test s


ReviewPacket = Backbone.Model.extend
    ### ReviewPacket functional core.

    A review packet gathers together artifacts related to a review
    process, including notes and supporting documentation, and allows
    users to track the progress of the review using a configurable
    workflow tailored to that process.

    ReviewPacket workflow and behavior can be modified by `options`,
    which are most often read from a client's `renderer.coffee` for
    the review packet type (see `README.rst`).

    The `actions` attribute stores the workflow definition for the
    review packet, which is derived from the `default_actions` and
    the provided `options` `actions` and `additional_actions`
    attributes. This derived attribute is recomputed at initialization
    and whenever the `options` are set (shallow: modifications to the
    object `options` refers to will _not_ trigger an update; when in
    doubt, call `review_packet.set("options", obj)`).

    This class defines the functional bits of the ReviewPacket model,
    split out from the imperative shell bits and housed here to ease
    testing.

    ###

    defaults:
        _default_actions: default_actions
        actions: {}
        meta: {}
        options: {}
        _available_to: {}

    _refresh_actions: ->
        # Update the actions attribute when the options change

        options = this.get("options")

        # TODO COREBT-13522: Only use actions section for actions definition
        this.set "actions", _.extend(
            {},
            options.actions or this.get("_default_actions"),
            options.additional_actions or {}
        )
        this.set "custom_action_functions", options.custom_action_functions or {}

    _refresh_available_to: ->
        # Update the cached available_to map when the options change

        options = this.get("options")

        available_to = _.extend(
            {},
            default_permissions,
            options.documents?.permissions or {},
            options.notes?.permissions or {},
            options.review?.permissions or {},
            options.checklist?.permissions or {}
        )

        actions = this.get("actions")

        for name, action of actions when action
            available_to[name] =
                available_to:
                    action.available_to or ["system.Everyone"]

        this.set("_available_to", available_to)

    initialize: (attributes, backbone_options) ->
        this.on "change:options", this._refresh_actions, this
        this.on "change:options", this._refresh_available_to, this
        this.trigger "change:options"

    urlRoot: ->
        try
            NextGen.metadata.plugin_url "review_packets"
        catch
            console.warn "NextGen metadata not loaded, assuming \
                          '/review_packets' as review packet URL target."

            "/review_packets"

    feature_enabled: (feature) ->
        ### Determine if a review packet (sub)feature is enabled for this
        review packet type.

        This implementation defines two subfeatures: documents and notes.

        By default, review packet features are enabled for all review packet
        types. Implementors can turn features off either directly as a
        Boolean::

            options:
                documents: false

        or via the enabled flag on documents as an object::

            options:
                notes:
                    enabled: false

        ###

        options = this.get("options")

        # this complicated spelling supports the default value of true given the
        # syntax options given above
        if options[feature]?.enabled?
            options[feature].enabled
        else if options[feature]?
            !!options[feature]
        else
            true

    _permission_available_to: (permission) ->
        # Convert from a permission ("notes_view") to an available_to list
        # `['group:staff']` using the _available_to map

        available_to_map = this.get("_available_to")
        available_to = available_to_map[permission]?.available_to

        if not available_to?
            []
        else if not _.isArray available_to
            [available_to]
        else
            available_to

    has_permission: (permission) ->
        ### Determine if the user has a given review packet permission.

        Review packet permissions include workflow actions ("approve",
        "withdraw") and subfeature permissions ("documents_view",
        "notes_add").

        Two modes of permission checking are supported. If no server-side
        access control list is defined, each action or subfeature permission
        axis may supply an available_to list, consisting of static principals
        indicating groups ("group:admin") or Clarus permissions
        ("permission:access_applicant_features"). The user is allowed
        to see the action if they have any of the principal identifiers
        specified in the list.

        The other permission mode is server-side: if there is an access
        control list registered with this review packet type on the server,
        the client-side permissions are ignored in favor of the
        server-defined allowed_actions list.

        ###

        meta = this.get("meta")
        options = this.get("options")

        if not meta["allowed_actions"]
            available_to = this._permission_available_to permission

            principals = meta["principals"]

            has_permission = available_to.indexOf("system.Everyone") >= 0 \
                or _.intersection(available_to, principals).length > 0
        else
            has_permission = meta["allowed_actions"].indexOf(permission) > -1

    feature_visible: (feature) ->
        ### Determine if a given (sub)feature should be shown for a given
        review packet (currently "documents", "notes", and "review").

        Checks the review packet's configuration and the user's permissions:
        the requested subfeature can be shown if it is enabled and the user
        has permission to see it.

        Returns true if the subfeature should be shown, false otherwise.

        ###

        this.feature_enabled(feature) and this.has_permission("#{feature}_view")

    action_visible: (action) ->
        ### Determine if a given action should be shown for a given review packet.

        Checks the review packet's current status against the action's
        available_when list and the user's permissions: if the review packet
        status appears in the action's available_when list and the user
        has permission to see the action, the action can be shown.

        Returns true if the action should be shown, false otherwise.

        ###

        if not action
            return false
        else if _.isString action
            action_name = action
        else
            action_name = action.name

        action = this.get("actions")[action_name]

        available_when = action?.available_when or []

        # TODO: allow available_when to be a function

        if not _.isArray available_when
            available_when = [available_when]

        available_when.indexOf(this.get("status")) > -1 and
            this.has_permission action_name

    any_valid_action: ->
        ### Determine if any available actions are available for this review packet
        to this user.

        Returns true if there is at least one valid action for this user, false
        otherwise.

        ###

        actions = this.get("actions")

        for action in _.values actions
            if this.action_visible action
                return true

        false

    any_actions_available_in_lifecycle: ->
        ### Determine if this user has any available actions in the lifecycle
        of the review packet.

        Returns true if there is at least one action available to the user
        at any configured status, false otherwise.

        ###

        principals = this.get('meta').principals or []

        _.any(this.get('actions'), (action) ->
            _.intersection action and action.available_to or [], principals)

    is_secured: ->
        ### Determine if this review packet has a server-side security model
        defined.

        A review packet type is considered secured if it includes an
        allowed_actions list in its returned metadata, even if this list is
        empty.

        Returns true if a server-side security model has been defined for
        this review packet type, false otherwise.

        ###

        return this.get("meta").allowed_actions?

    has_clarus_permission: (permission_name) ->
        ### Determine if the user who requested this review packet has a
        given Clarus permission.

        Given a Clarus permission name
        ("review_packet:snooze_security_warning"), return true if the user
        who requested this review packet has the permission, false otherwise.

        ###

        if not __starts_with_permission permission_name
            permission_name = "permission:#{permission_name}"

        principals = this.get("meta").principals or []

        permission_name in principals

    production_environment: ->
        ### Determine if the server is running in production mode.
        ###

        this.get("meta").environment == "production"

    show_security_warning: ->
        ### Determine if the review packet security warning should be shown.

        Return a Boolean indicating whether the review packet security
        warning should be shown. The security warning can be hidden if
        the review packet is secured (see above) or the warning has been
        snoozed in a nonproduction environment.

        ###

        hide_security_warning = this.is_secured() or \
            (not this.production_environment() and \
             this.get("meta").security_warning_snoozed)

        not hide_security_warning

    allow_snoozing: ->
        ### Determine if the review packet security warning can be snoozed.

        Return a Boolean indicating whether the review packet security
        warning can be snoozed. The security warning can be snoozed in
        nonproduction environments by users who have the Clarus permission
        `review_packet:snooze_security_warning`.

        ###

        not this.production_environment() and \
            this.has_clarus_permission "review_packet:snooze_security_warning"

    toJSON: (options) ->
        ### Generate a JSON representation of this review packet.

        Override Backbone.Model.toJSON to alter the representation of
        client-side secured review packets based on the user's
        permissions.

        ###

        json = Backbone.Model.prototype.toJSON.call(this, options)

        if not this.has_permission "reviewers_view"
            delete json.meta.reviewers

        json

    find_checked_items: ->
        ### Return list of the checklist item keys which should be checked

        Sorts through the review packet's checklist data to create a new array
        which represents the checklist keys which should be checked.

        Expects the checklist data stored in custom data on a review packet to 
        be an array of objects with 'key' and 'state' properties.

        ###
        
        checklist_data = this.attributes._custom_data.checklist
        
        items = []
        checked_items = []
        if checklist_data
            checklist_data.reverse()
            for item in checklist_data
                if item.key not in items
                    items.push(item.key)
                    if item.state == "true"
                        checked_items.push(item.key)

        return checked_items
    
    can_verify_photo: ->
        ###  Determine if the user who requested this review packet can
        use the photo verification widget.

        If the user is an admin or staff and has the `document_verification_edit`
        permission, return true.

        ###

        principals = this.get("meta").principals or []
        is_staff = "group:staff" in principals
        is_admin = "group:admin" in principals
        can_verify = this.has_clarus_permission("document_verification_edit")

        return (is_staff || is_admin) && can_verify

module.exports =
    default_actions: default_actions
    default_permissions: default_permissions
    ReviewPacket: ReviewPacket
