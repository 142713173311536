available_credential_types = (defined_types, user_credentials=[]) ->
    ### Determine the credentials available to a user

    Given a list of defined credential types and optionally a list of
    credentials held by a user, produce a list of types that are available
    to the user. A credential type is considered available if the user does
    not have a "live" credential of that type.

        defined_types {Object[]}    the credential types defined in the system.
                                    At minimum this must include the credential
                                    type's UUID (`uuid`)

        user_credentials {Object[]} the credentials held by a user; must
                                    include the `terminal` and `valid` flags
                                    and the `credential_type_uuid`

    Returns a list containing the credential types listed in `defined_types`
    for which there is no "live" entry in `user_credentials` with the same
    UUID. If no credential types are available, this list will be empty.

    * A "live" credential here is defined as one which is valid or not terminal.
    A terminal credential *should* never be valid, so the check for "valid"
    is out of an abundance for caution.

    ###

    defined_types ?= []

    live_uuids = (credential.credential_type_uuid \
                    for credential in user_credentials \
                    when (credential?.valid or not credential?.terminal))

    (credential_type \
     for credential_type in defined_types \
     when credential_type?.uuid not in live_uuids)


module.exports =
    available_credential_types: available_credential_types
