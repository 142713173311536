_ = require "underscore"
rowgroup = require "datatables.net-rowgroup-bs5"

orb = require "orb"
pht = require './publish_history_table'
publish_targets = require './publish_targets'

populate_perspective_selectors = () ->
    $.ajax(
        method: 'GET'
        url:'/admin/webtop/perspective/search')
    .then (data) ->
        publishing_perspectives = _.sortBy (_.filter data.perspectives, (perspective) ->
            perspective._custom_data && perspective._custom_data.credential_publishing),
            (perspective) ->
                perspective.name

        for item in [['update_applications', '#existing-applications-select-v2'],
                     ['update_credentials', '#existing-credentials-select-v2']]
            $(item[1])[0].innerHTML = _.reduce (_.filter publishing_perspectives, (perspective) ->
                _.contains perspective._custom_data.credential_publishing, item[0]),
                    (value, perspective) ->
                        value + "<option value=#{perspective.data_link}>#{perspective.name}</option>"
                    , '<option value="">None</option>'


setup = (credential_type) ->
    ### Sets up the publishing table, and wires up all the publishing
    plumbing.
    ###

    populate_perspective_selectors()
    setup_publishing_table credential_type
    setup_publish_actions credential_type
    publish_targets.setup_table()


setup_publish_actions = (credential_type) ->
    $('#publish-modal-v2').on 'shown.bs.modal', () ->
        # Remove any existing flash-alert divs so that the one we place in this modal
        # will be utilized
        $('div#flash-alert').remove()
        $('#publish-modal-v2').find('.modal-content').prepend '<div class="row" id="flash-alert"></div>'

    $('#publish-modal-v2').on 'hidden.bs.modal', () ->
        $(this).find('select, input[type="text"], textarea').val('').trigger('change').prop 'disabled', false
        $('#publish-submit-v2').removeClass('disabled').attr('disabled', false).show()

        $('#promote-toggle-v2').bootstrapToggle 'enable'
        $('#promote-toggle-v2').bootstrapToggle 'on'

        # The selection of cells in the datatable can cause errors. To display
        # those errors, we must replace the pre-existing flash-alert with one
        # inside this modal. Removing this modal's flash-alert allows for
        # the rest of the window to continue using `orb.flash_*`
        $('#flash-alert').remove()

    $('#publish-form-v2').on "submit", (e) ->
        e.preventDefault()
        $('#publish-submit-v2').addClass('disabled').attr('disabled', true)
        $(this).find('select, input[type="text"], textarea').attr 'disabled', true
        $('#promote-toggle-v2').bootstrapToggle 'disable'
        # Prevent the user from making selections in the publish history table
        $('#publish-modal-v2 .publishing-table').DataTable().select.style 'api'

        name = $(this).find('input[name="version_name"]').val()
        reason = $(this).find('textarea[name="reason"]').val()

        NextGen.components.clarity.get_credential_type(credential_type, true).then (draft_data) ->
            NextGen.components.clarity.publish_credential_type(credential_type, draft_data, name, reason)
            .then (publish_version) ->
                promises = []
                if $('#promote-toggle-v2')[0].checked
                    promises.push(NextGen.components.clarity.update_credential_type_by_publish_version(credential_type, publish_version['uuid'])
                        .fail () ->
                            $('.promote-toggle-indicators').find('.fa-times').animate({height: 'show'})
                        .done () ->
                            $('.promote-toggle-indicators').find('.fa-check').animate({height: 'show'})
                    )

                promises.push publish_targets.publish_to_all_targets credential_type, publish_version

                Promise.all(promises).then () ->
                    $('#publish-submit-v2').hide()
                    $('#publish-actions').addClass('d-none')
                    $('#publish-reload-notification').removeClass('d-none')
            .fail (err) ->
                $('#publish-submit-v2').removeClass('disabled').attr('disabled', false)
                $('#publish-form-v2').find('select, input[type="text"], textarea').attr 'disabled', false
                $('#promote-toggle-v2').bootstrapToggle 'enable'
                $('#publish-modal-v2 .publishing-table').DataTable().select.style 'multi'

                console.error 'error while publishing', err
                orb.flash_error "Error publishing: #{err.responseText}"

    $('#refresh-button-v2').on 'click', () ->
        window.location = window.location.href

    $('#promote-toggle-v2').bootstrapToggle
        onlabel: 'Yes'
        offlabel: 'No'
        offstyle: 'danger'
        onstyle: 'success'
        style: 'toggle-btn-md align-top'
        width: '5em'
        height: '2.5em'

    $('#existing-credentials-select-v2, #existing-applications-select-v2').on 'change', () ->
        publish_targets.update credential_type


setup_publishing_table = (credential_type) ->
    table = $('#publish-modal-v2 .publishing-table')

    NextGen.components.clarity.get_credential_type(
        credential_type,
        false,
        {expand: 'publish_history'}
    ).then (credential_type_info) ->
        pht.init_publish_table table, credential_type_info.publish_history, {
            select: true,
            select_target: 'cell',
            select_style: 'multi',
            scrollY: 'max(calc(100vh - 700px), 220px)'
        }
        table.DataTable().on 'select', (e, dt, type, indexes) ->
            publish_targets.update credential_type
        table.DataTable().on 'deselect', (e, dt, type, indexes) ->
            publish_targets.update credential_type

    # https://datatables.net/examples/api/tabs_and_scrolling.html
    # When scrolling is enabled, and the table starts hidden, the headers will
    # be misaligned
    $('#publish-modal-v2').on 'shown.bs.modal', () ->
        table.DataTable().columns.adjust()

    $('#publish-modal-v2').on 'hidden.bs.modal', () ->
        table.DataTable().cells({selected: true}).deselect()


module.exports =
    'setup': setup
