paginate = (items, pagesize=0) ->
    ### Split an array into pagesize chunks.

    Given an array and a page size, split the array into pages of that size.

    Returns an array of pages; each page is an array of one chunk of the
    original items.

    If items is null or empty, returns an array containing a single empty
    array.

    If the page size is larger than the length of the items, returns an
    array containing a single array with all the original items.

    @param {Array} items        the items to paginate

    @param {Integer} pagesize   the number of items per page

    @return {Array[]}           an array of pages; each page is an array of
                                one chunk of the original items

    ###

    if not items or items.length == 0
        return [[]]

    if pagesize < 1
        pagesize = items.length

    (items.slice(i, i+pagesize) for i in [0...items.length] by pagesize)


get_selected_credential_types = (credential_types, application_type_slugs) ->
    ###Filter credential types to the ones user has applied for###

    credential_types.filter (credential_type) ->
        credential_type.slug in application_type_slugs


get_valid_applications = (applications) ->
    ###Filter applications to the ones that are not in withdrawn status###

    applications.filter (x) -> x.status != 'withdrawn'


module.exports =
    paginate: paginate
    get_selected_credential_types: get_selected_credential_types
    get_valid_applications: get_valid_applications
