var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "<div class=\"rp-documents-upload-control mt-4\">\n    <label class=\"form-label fw-bold\">"
    + container.escapeExpression(__default(require("../../handlebars_helpers/lang.coffee")).call(depth0 != null ? depth0 : (container.nullContext || {}),"L001115","Select file",{"name":"lang","hash":{},"data":data,"loc":{"start":{"line":13,"column":38},"end":{"line":13,"column":70}}}))
    + "</label>\n    <input\n        id=\"rp-documents-file\"\n        name=\"document_files\"\n        type=\"file\"\n        multiple=\"multiple\"\n        class=\"form-control file-loading\"\n    />\n        <!-- TODO: allow this to be set in the rp type configuration -->\n        <!-- accept=\".pdf,application/pdf\" -->\n    <div id=\"errorBlock\" class=\"form-text text-danger\"></div>\n</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<table class=\"table table-striped\" id=\"rp-documents-table\" data-rp-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"review_packet_id") : depth0), depth0))
    + "\">\n    <thead>\n        <tr>\n            <th>"
    + alias2(__default(require("../../handlebars_helpers/lang.coffee")).call(alias3,"L001112","Timestamp",{"name":"lang","hash":{},"data":data,"loc":{"start":{"line":4,"column":16},"end":{"line":4,"column":46}}}))
    + "</th>\n            <th>"
    + alias2(__default(require("../../handlebars_helpers/lang.coffee")).call(alias3,"L001113","Filename",{"name":"lang","hash":{},"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":5,"column":45}}}))
    + "</th>\n            <th>"
    + alias2(__default(require("../../handlebars_helpers/lang.coffee")).call(alias3,"L001114","Document type",{"name":"lang","hash":{},"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":6,"column":50}}}))
    + "</th>\n        </tr>\n    </thead>\n</table>\n\n"
    + ((stack1 = container.hooks.blockHelperMissing.call(depth0,alias1((depth0 != null ? lookupProperty(depth0,"show_uploads") : depth0), depth0),{"name":"show_uploads","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":0},"end":{"line":25,"column":17}}})) != null ? stack1 : "");
},"useData":true});