_interopRequireDefault = (obj) ->
  if obj and obj.__esModule then obj else default: obj

'use strict'
Object.defineProperty exports, '__esModule', value: true
_typeof = if typeof Symbol == 'function' and typeof Symbol.iterator == 'symbol' then ((obj) ->
  typeof obj
) else ((obj) ->
  if obj and typeof Symbol == 'function' and obj.constructor == Symbol and obj != Symbol.prototype then 'symbol' else typeof obj
)

###
# Copyright 2010-2016 Amazon.com, Inc. or its affiliates. All Rights Reserved.
#
# Licensed under the Apache License, Version 2.0 (the "License").
# You may not use this file except in compliance with the License.
# A copy of the License is located at
#
#  http://aws.amazon.com/apache2.0
#
# or in the "license" file accompanying this file. This file is distributed
# on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
# express or implied. See the License for the specific language governing
# permissions and limitations under the License.
###

### eslint max-len: ["error", 100]###

_urlTemplate = require('url-template')
_urlTemplate2 = _interopRequireDefault(_urlTemplate)
_apiGatewayClient = require('./apiGatewayCore/apiGatewayClient')
_apiGatewayClient2 = _interopRequireDefault(_apiGatewayClient)
apigClientFactory = {}

removeEmpty = (obj) ->
  Object.keys(obj).forEach (key) ->
    obj[key] and _typeof(obj[key]) == 'object' and removeEmpty(obj[key]) or obj[key] == undefined and delete obj[key]
  obj

apigClientFactory.newClient = ->
  config = if arguments.length > 0 and arguments[0] != undefined then arguments[0] else {}
  apigClient = {}

  config = Object.assign({
    accessKey: ''
    secretKey: ''
    sessionToken: ''
    region: ''
    apiKey: ''
    invokeUrl: ''
    service: 'execute-api'
    defaultContentType: 'application/json'
    defaultAcceptType: 'application/json'
    systemClockOffset: 0
    headers: {}
  }, removeEmpty(config))

  # extract endpoint and path from url
  invokeUrl = config.invokeUrl
  endpoint = /(^https?:\/\/[^\/]+)/g.exec(invokeUrl)[1]
  pathComponent = invokeUrl.substring(endpoint.length)

  sigV4ClientConfig = 
    accessKey: config.accessKey
    secretKey: config.secretKey
    sessionToken: config.sessionToken
    serviceName: config.service
    region: config.region
    endpoint: endpoint
    defaultContentType: config.defaultContentType
    defaultAcceptType: config.defaultAcceptType
    systemClockOffset: config.systemClockOffset
    retries: config.retries
    retryCondition: config.retryCondition

  authType = 'NONE'

  if sigV4ClientConfig.accessKey != undefined and sigV4ClientConfig.accessKey != '' and sigV4ClientConfig.secretKey != undefined and sigV4ClientConfig.secretKey != ''
    authType = 'AWS_IAM'

  simpleHttpClientConfig = 
    endpoint: endpoint
    defaultContentType: config.defaultContentType
    defaultAcceptType: config.defaultAcceptType
    retries: config.retries
    retryCondition: config.retryCondition
    headers: config.headers

  apiGatewayClient = _apiGatewayClient2.default.newClient(simpleHttpClientConfig, sigV4ClientConfig)

  apigClient.invokeApi = (params, pathTemplate, method='GET', additionalParams={}, body='', responseType='application/json') ->
    request = 
      verb: method.toUpperCase()
      path: pathComponent + _urlTemplate2.default.parse(pathTemplate).expand(params)
      headers: additionalParams.headers or {}
      queryParams: additionalParams.queryParams
      body: body
      responseType: responseType
    apiGatewayClient.makeRequest request, authType, additionalParams, config.apiKey

  apigClient

exports.default = apigClientFactory
