register_events = (refresh_page_fn) ->
    $('body').on('click', '.page-button', () ->
        refresh_page_fn null, $(this).data('start')
    )

    $('body').on('click', '.refresh-button', () ->
        refresh_page_fn()
    )

    $('body').on('click', '.claim-course-button', () ->
        uname = NextGen.metadata.user.user_name
        button = $(this)
        button.attr 'disabled', true

        $.ajax(
            method: 'POST'
            contentType: 'application/json'
            dataType: 'json'
            url: "/api/v2/candidates/uname:#{uname}/courses"
            data: JSON.stringify {course_id: button.data('course-id')}
        ).then (data) ->
            window.location.href = '/ce'
        .fail () ->
            button.attr 'disabled', false
    )


module.exports =
    register_events: register_events
