_ = require "underscore"
orb = require "orb"

clarity = require "orb/system/clarity_client"

css = """
    <style>
    iframe {
        height: 50vh;
        min-height: 300px;
        border-radius: 15px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
    label.btn:not(:has(input:checked)) {
        background-color: white;
        border-color: lightgrey;
    }
    .table {
        table-layout: fixed;
    }
    .table-condensed {
        border: 1px solid;
        border-radius: 15px;
    }
    .table-condensed thead {
        background-color: var(--light-blue);
    }
    .table-condensed thead th {
        color: var(--brightlink-blue) !important;
        font-weight: 600;
        font-size: 0.875rem;
        white-space: nowrap;
        border-bottom: 1px solid var(--light-blue) !important;
    }
    #profile-selection {
        margin-top: 6rem; /* BS5 m-* classes don't support large values */
    }
    .user-warning {
        background-color: var(--light-blue);
        border-radius: 15px;
    }
    </style>
"""

user_block = """
    <div class="card h-100 mb-2">
        <div class="card-header bg-light-blue border-0">
            <div class="user-title text-center mb-3">
                <h4 class="display-name d-inline-block mb-0 me-2"></h4>
                <h5 class="client-id text-muted d-inline-block mb-0 me-2"></h5>
            </div>
            <div class="text-center">
                <span class='user-name text-muted'></span>
                <br/>
                <span class='email-address text-muted'></span>
            </div>
        </div>
        <div class="card-body px-2 py-0">
            <iframe class="w-100 mb-3"></iframe>
            <div class="table-responsive">
                <table class="exam-application-table table table-condensed">
                    <thead>
                        <tr>
                            <th>Exam Application</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                    </tbody>
                </table>
            </div>
            <div class="table-responsive">
                <table class="result-table table table-condensed">
                    <thead>
                        <tr>
                            <th>Exam</th>
                            <th>Date</th>
                            <th>Result</th>
                        </tr>
                    </thead>
                    <tbody>
                    </tbody>
                </table>
            </div>
            <div class="table-responsive">
                <table class="credential-application-table table table-condensed">
                    <thead>
                        <tr>
                            <th>Credential Application</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                    </tbody>
                </table>
            </div>
            <div class="table-responsive">
                <table class="credential-table table table-condensed">
                    <thead>
                        <tr>
                            <th>Credential</th>
                            <th>#</th>
                            <th>Issued On</th>
                            <th>Valid</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
"""


selection_block = """
    <div id="user-selection" class="text-center mt-4">
        <h5 class="mb-3">Select user to keep</h5>
        <div class="btn-group" role="group">
            <label class="btn btn-success">
                <input type="radio" class="d-none" name="user_to_keep" value="user_one" autocomplete="off"/>
                <span class="fa-solid fa-check"></span>
            </label>
            <label class="btn btn-success">
                <input type="radio" class="d-none" name="user_to_keep" value="user_two" autocomplete="off"/>
                <span class="fa-solid fa-check"></span>
            </label>
        </div>
    </div>

    <div id="profile-selection" class="text-center">
        <h5 class="mb-3">Select profile to keep</h5>
        <div class="btn-group" role="group">
            <label class="btn btn-success">
                <input type="radio" class="d-none" name="profile_to_use" value="user_one" autocomplete="off"/>
                <span class="fa-solid fa-check"></span>
            </label>
            <label class="btn btn-success">
                <input type="radio" class="d-none" name="profile_to_use" value="user_two" autocomplete="off"/>
                <span class="fa-solid fa-check"></span>
            </label>
        </div>
    </div>

    <div class="user-warning text-center mt-5 p-4">
        <div class="mb-3">
            <i class="fa-solid fa-triangle-exclamation fa-2x text-warning mb-3"></i>
            <p class="mb-2">This operation is <b>IRREVERSIBLE</b></p>
            <p class="mb-0">Click here to verify that you understand and still wish to proceed.</p>
        </div>
        <label class="btn btn-success">
            <input id="agree-proceed" type="checkbox" class="d-none" autocomplete="off"/>
            <span class="fa-solid fa-check"></span>
        </label>
    </div>
    <div class="text-center mt-4">
        <button id="merge-button" class="btn btn-primary disabled">
            <i class="fa-solid fa-code-merge me-2"></i>Merge
        </button>
    </div>
"""

template = """
    #{css}
    <div class="row">
        <div class="col-2">
        </div>
        <div class="col-8 text-center">
            <h3>Candidate Merge</h3>
        </div>
        <div class="col-2">
        </div>
        <div id="first-user" class="col-5">
            #{user_block}
        </div>
        <div id="selection" class="col-2">
            #{selection_block}
        </div>
        <div id="second-user" class="col-5">
            #{user_block}
        </div>
    </div>
"""
exam_types = null


render = (options) ->
    el = $(options.render_target)
        .html template

    _get_exam_types().then () ->
        _process_candidate($('#first-user'), options.user_one)
        _process_candidate($('#second-user'), options.user_two)

    $('body').on 'change', 'input', (event) ->
        $('#merge-button').toggleClass 'disabled', $('input:checked').length != 3

    $('#merge-button').on "click", (event) ->
        user_to_keep = $('input[name="user_to_keep"]:checked').val()
        profile_to_use = $('input[name="profile_to_use"]:checked').val()
        use_merged_profile = false

        if user_to_keep == 'user_one'
            update_user = options.user_one
            merge_user = options.user_two
        else
            update_user = options.user_two
            merge_user = options.user_one

        if user_to_keep != profile_to_use
            use_merged_profile = true
                
        $('#merge-button').addClass 'disabled'
        $.ajax(
            method: 'POST'
            contentType: "application/json; charset=utf-8"
            dataType: "json"
            url: "/api/v2/candidates/#{update_user}/merge"
            data:
                JSON.stringify
                    'use_merged_profile': use_merged_profile,
                    'candidate_to_merge': merge_user
        ).then (response) ->
            orb.flash_success "Merge Successful", null
            window.scrollTo(0, 0)
            if user_to_keep == 'user_one'
                $('#second-user').addClass 'd-none'
                _process_candidate($('#first-user'), options.user_one)
            else
                $('#first-user').addClass 'd-none'
                _process_candidate($('#second-user'), options.user_two)
            $('#selection').addClass 'd-none'
        .fail (response) ->
            if response and response.responseJSON
                orb.flash_error response.responseJSON.message
            else
                orb.flash_error "Unknown Error Encountered"
            $('#merge-button').removeClass 'disabled'


_get_exam_types = () ->
    $.ajax(
        method: 'GET'
        url: '/api/v2/exam_types'
    ).then (data) ->
        exam_types = data.exam_types


_get_exam_type_by_id = (exam_type_id) ->
    _.find exam_types, (exam_type) ->
        exam_type.id == exam_type_id


_process_candidate = ($root_element, candidate_id) ->
    $.when(
        _get_candidate_user_data candidate_id
        _get_candidate_exam_applications candidate_id
        _get_candidate_results candidate_id
    ).then (user_data, applications, results) ->
        candidate = user_data[0]
        # Extend candidate object with address fields at the top level as that
        # is where they are commonly referenced in dynamic forms
        Object.assign candidate, candidate.address 
        candidate.exam_applications = applications[0].applications
        candidate.results = results[0].exam_results
        $.when(
            clarity.get_applications(candidate.uuid)
            clarity.get_credentials(candidate.uuid,  null, true, true)
            ).then (credential_applications, credentials) ->
                candidate.credential_applications = credential_applications[0] or []
                candidate.credentials = credentials[0] or []
                _update_ui_for_candidate $root_element, candidate


_update_ui_for_candidate = ($root_element, candidate) ->
    display_name = candidate.first_name + ' ' + candidate.last_name
    $root_element.find('.display-name')[0].innerText = display_name
    user_id = candidate.client_id or candidate.id
    $root_element.find('.client-id')[0].innerText = "(#{user_id})"
    if candidate.user_name != candidate.email_address
        $root_element.find('.user-name')[0].innerText = "username: \"#{candidate.user_name}\""
        $root_element.find('.email-address')[0].innerText = "email: \"#{candidate.email_address}\""
    else
        $root_element.find('.user-name')[0].innerText = "username/email: \"#{candidate.user_name}\""

    $.ajax(
        method: 'POST'
        url: '/dynamic_form/read_only'
        data:
            form_name: 'profile'
            entity_data: JSON.stringify(candidate)
    ).then (content) ->
        profile_window = $root_element.find('iframe')[0].contentWindow
        profile_window.document.open()
        profile_window.document.write content
        profile_window.document.close()

    exam_application_table_body = $root_element.find('.exam-application-table tbody')[0]
    exam_application_table_body.innerHTML = ""

    if !candidate.exam_applications.length
        exam_application_table_body.innerHTML = """
            <tr>
                <td colspan="2" class="text-center text-muted">
                    <i class="fa-solid fa-folder-open me-2"></i>No Records
                </td>
            </tr>"""
    else
        candidate.exam_applications.forEach (exam_application) ->
            if exam_application.status != 'finalized'
                new_row = exam_application_table_body.insertRow -1
                new_row.className = "align-middle"
                new_row.insertCell(0).appendChild document.createTextNode(exam_application.exam_type_name)
                new_row.insertCell(1).appendChild document.createTextNode(exam_application.status)

    result_table_body = $root_element.find('.result-table tbody')[0]
    result_table_body.innerHTML = ""
    if !candidate.results.length
        result_table_body.innerHTML = """
            <tr>
                <td colspan="3" class="text-center text-muted">
                    <i class="fa-solid fa-folder-open me-2"></i>No Records
                </td>
            </tr>"""
    else
        candidate.results.forEach (result) ->
            exam_type = _get_exam_type_by_id result.type_id
            new_row = result_table_body.insertRow -1
            new_row.className = "align-middle"
            new_row.insertCell(0).appendChild document.createTextNode(exam_type.abbr)
            new_row.insertCell(1).appendChild document.createTextNode(result.exam_date)
            new_row.insertCell(2).appendChild document.createTextNode(result.result)

    credential_table_body = $root_element.find('.credential-table tbody')[0]
    credential_table_body.innerHTML = ""
    if !candidate.credentials.length
        credential_table_body.innerHTML = """
            <tr>
                <td colspan="5" class="text-center text-muted">
                    <i class="fa-solid fa-folder-open me-2"></i>No Records
                </td>
            </tr>"""
    else
        candidate.credentials.forEach (credential) ->
            new_row = credential_table_body.insertRow -1
            new_row.className = "align-middle"
            new_row.insertCell(0).appendChild document.createTextNode(credential.credential_type_slug)
            new_row.insertCell(1).appendChild document.createTextNode(credential.number)
            new_row.insertCell(2).appendChild document.createTextNode(credential.issued_on)
            valid_cell = new_row.insertCell(3)
            valid_cell.appendChild document.createTextNode(credential.valid)
            valid_cell.className = if credential.valid then "text-success" else "text-danger"
            new_row.insertCell(4).appendChild document.createTextNode(credential.status)

    credential_application_table_body = $root_element.find('.credential-application-table tbody')[0]
    credential_applications = Array.from(candidate.credential_applications)
    credential_applications = (app for app in credential_applications when app.status != 'withdrawn')
    if !credential_applications.length
        credential_application_table_body.innerHTML = """
            <tr>
                <td colspan="2" class="text-center text-muted">
                    <i class="fa-solid fa-folder-open me-2"></i>No Records
                </td>
            </tr>"""
    credential_applications.forEach (credential_application) ->
        new_row = credential_application_table_body.insertRow -1
        new_row.className = "align-middle"
        new_row.insertCell(0).appendChild document.createTextNode(credential_application.credential_type_slug)
        new_row.insertCell(1).appendChild document.createTextNode(credential_application.status)



_get_candidate_user_data = (candidate_id) ->
    $.ajax(
        method: "get"
        url: "/api/v2/candidates/#{candidate_id}"
        traditional: true
        headers:
            "Accept": "application/json" 
            "Content-Type": "application/json"
    )

_get_candidate_exam_applications = (candidate_id) ->
    $.ajax(
        method: "get"
        url: "/api/candidates/#{candidate_id}/applications"
        traditional: true
        headers:
            "Accept": "application/json" 
            "Content-Type": "application/json"
    )

_get_candidate_results = (candidate_id) ->
    $.ajax(
        method: "get"
        url: "/api/candidates/#{candidate_id}/exam_results"
        traditional: true
        headers:
            "Accept": "application/json" 
            "Content-Type": "application/json"
    )


module.exports =
    render: render