# FIXME BS5
#require "bootstrap/js/tooltip"
#require "bootstrap/js/popover"
#require "bootstrap-confirmation2"

orb_components = require "orb/components"
dashboard_template = require "./dashboard.mustache"
base_template = require "./provider_user_manager.mustache"

user_id = null
user_data = null


get_user_data = (refresh=false) ->
    promise = $.Deferred()

    if not user_data or refresh
        $.get(
            url: NextGen.metadata.plugin_url('providers', "/api/users/#{user_id}")
        ).then (data) ->
            user_data = data
    else
        promise.resolve user_data


_render_dashboard = () ->
    $("#provider-user-manager-content  #dashboard").html dashboard_template(user_data)

    $("#provider-user-manager-content .password-reset").confirmation(
        placement:'bottom',
        onConfirm: ->
            $.ajax(
                url: NextGen.metadata.plugin_url("providers", "/users/#{user_id}/reset_password")
                method: 'GET'
                success: (data) ->
                    orb_components.flash_success("Password has been reset successfully.")
                error: ->
                    orb_components.flash_error("An error occurred when trying to reset user's password.")
            )
    )

    $("#provider-user-manager-content .change-status a").click((event) ->
        status = $(this).data('status')
        $.ajax({
            url: NextGen.metadata.plugin_url('providers', "/api/users/#{user_id}")
            method: "PATCH"
            contentType: "application/json"
            data: JSON.stringify
                status: status
            success: (response) ->
                user_data.status = status
                _render_dashboard()
                orb_components.flash_success("Updated user's status to #{status} successfully.")
            error: ->
                orb_components.flash_error("An error occurred when trying to update status.")
        })
    )

    $('#provider-user-manager-content .login-as-button').click( ->
        $.ajax({
            url: '/authentication/get_auto_login_url'
            data:
                id: user_id
            success: (response) ->
                if response.url
                    $('.modal-body').html('<p>Click <a href="' + response.url + '" target="_blank">here</a> to login as the user.</p>')
                    $('.login-as-modal').modal('show')
                else
                    $('.modal-body').html('<p>An error occurred while processing this request</p>')
                    $('.login-as-modal').modal('show')
        })
    )

_render_profile_form = ->
    ###Render provider user profile DF into profile iframe ###

    promise = $.Deferred()

    $.ajax(
        method: 'GET'
        url: NextGen.metadata.plugin_url('providers', "/users/#{user_id}?compact_view=true")
    ).then (data) ->
        profile_iframe = $('#provider-user-profile-iframe')[0]
        profile_iframe.contentWindow.document.open()
        profile_iframe.contentWindow.document.write data
        profile_iframe.contentWindow.document.close()

        $('#bl-spinner').fadeOut 1000, () ->
            $(this).remove()
        promise.resolve()


_add_form_listeners = () ->
    # These events are triggered by the dynamic forms in the iframes to let us
    # know when the data is stable and ready to be previewed
    $('body').on 'save-success', (evt, source) ->
        if source.frameElement.id == 'provider-user-profile-iframe'
            get_user_data(refresh=true).done ->
                _render_dashboard()


render = (options) ->
    ###Provider User Manager Renderer###

    user_id = options.user_id

    el = $(options.render_target).html base_template

    # Hang jquery on the window so that embedded iframe can raise events
    if not window.$
        window.$ = $

    get_user_data().done ->
        _render_dashboard()
        _render_profile_form()
        _add_form_listeners()


module.exports =
    "render": render
