_ = require "underscore"

confirm = require "orb/confirm_modal"
orb = require "orb"
special_accommodations_template = require "./special_accommodations_template.mustache"


get_enabled_special_accommodations = (callback, agency_id, error=-> false) ->
    ### Fetch the list of available special accommodation types.

    Determine the first enabled third party agency and fetch the list of
    available special accommodation types defined for that agency. On
    success, calls the provided `callback` with an object

        {
            special_accommodations: [
                {
                    name: "ADDITIONAL BREAK"
                    ...
                },
                {
                    name: "DOUBLE TIME"
                    ...
                },
            ]
        }

    If there is no enabled third party agency or that agency has no defined
    special accommodation types, the provided `error` callback is invoked with
    a single argument of an appropriate error message.

    ###

    enabled_agency_id = null
    special_accommodation_data = null

    verify_enabled_agency = $.getJSON "/third_party_agency/search", (data) ->
        enabled_agency = _.find data.third_party_agencies, (agency) ->
            agency.enabled && agency.id == agency_id
        if not enabled_agency
            error BL.Language.get("L001388", "No enabled third party agency could be found.")
        else
            enabled_agency_id = enabled_agency.id

    verify_enabled_agency.done ->
        if enabled_agency_id
            $.getJSON "/third_party_agency/special_accommodations?id=#{enabled_agency_id}&enabled_only=True",
                (data) ->
                    if not data.special_accommodations?.length
                        error BL.Language.get("L001387", "No special accommodation types defined.")
                    else
                        callback data


approve_accommodations = (review_packet) ->
    ### Review and approve special accommodation requests after confirmation.

    Show the user a dialog of available special accommodation types, and allow
    them to specify which types have been approved for this application.

    ###
    agency_id = review_packet.get("application").third_party_agency.id
    select = null

    textarea = null

    modal = confirm
        title: BL.Language.get("L001384", "Approve candidate request for special accommodations")
        content: "<em>{0}</em>".format(BL.Language.get("L001386", "Loading available special accommodations…"))
        confirm_btn_label: BL.Language.get("L001389", "OK")
        dismiss_btn_label: BL.Language.get("L001390", "Cancel")
        ok_callback: ->
            form = document.getElementById("special-accommodations-form")
            if form.checkValidity()
                accommodations = select.val()
                details = textarea.val()
                _do_approve_accommodations(review_packet, accommodations, details)
            else
                _show_special_accommodations_form_validation(form)

    show_error = (message) ->
        modal.find(".modal-body p").addClass("text-warning").html message

    show_accomodations = (special_accommodations) ->
        content = special_accommodations_template special_accommodations
        modal.find(".modal-body p").html content
        textarea = modal.find("textarea")

        select = modal.find("select").on "change", ->
            details_required = $(this).children("option:selected[data-requires-details='true']").length >= 1
            textarea.prop "required", details_required

    get_enabled_special_accommodations(show_accomodations, agency_id, show_error)


_show_special_accommodations_form_validation = (form) ->
    ### Show validation on any required field that is missing a value ###

    $(form).find("select, textarea").each( () ->
        if this.validity.valueMissing
            $(this).parent().addClass("has-error")
        else
            $(this).parent().removeClass("has-error")
    )
    form.reportValidity()


_add_accommodation_details_note = (review_packet, details) ->
    ### Add given accommodation details to review packet as a note ###

    if details == ""
        return false

    note = "Special accommodations details: {0}".format(details)
    review_packet.add_note review_packet, note, is_internal=false


transform_accommodation_data = (application_id, accommodations, details) ->
    accommodation_data = {
        application_id: application_id
        special_accommodations: accommodations
    }

    for accommodation in accommodations
        detail = "additional_information_{0}".format(accommodation)
        accommodation_data[detail] = details

    return accommodation_data


_do_approve_accommodations = (review_packet, accommodations, details) ->
    ### Approve the given special accommodation requests.

    Given a list of special accommodation IDs and details, mark this
    review packet's application as approved for those special accommodations.

    Presents a flash message to the user on success or failure.

    ###
 
    application_id = review_packet.get("application").id
    accommodation_data = \
        transform_accommodation_data(application_id, accommodations, details)

    $.ajax
        url: "/special_accommodations/approve"
        method: "post"
        data: accommodation_data
        traditional: true
        success: ->
            orb.flash_success BL.Language.get("L001391", "Special Accommodations request approved.")
            review_packet.fetch({cache: false}).done ->
                review_packet.update_status "approved"
            _add_accommodation_details_note(review_packet, details)
        error: (response) ->
            if response.responseJSON
                error_msg = response.responseJSON.errors._schema[0]
                orb.flash_error error_msg
            else
                orb.flash_error BL.Language.get("L001392", "There was a problem approving the special accommodations request.")

deny_accommodations = (review_packet) ->
    confirm
        title: BL.Language.get("L001393", "Confirm")
        content: BL.Language.get("L001394", "Are you sure you want to deny the candidate's special accommodation request?")
        confirm_btn_class: "btn-warning"
        confirm_btn_label: BL.Language.get("L001395", "Deny special accommodation request")
        dismiss_btn_label: BL.Language.get("L001396", "Cancel")
        ok_callback: ->
            review_packet.update_status "pending_review"


module.exports =
    approve_accommodations: approve_accommodations
    deny_accommodations: deny_accommodations
