var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<img class=\"verification-photo\" src=\"/applicant_document/get_document?id="
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"document_id") : depth0), depth0))
    + "\"\n    class=\"img-thumbnail\" alt=\""
    + alias2(__default(require("../../handlebars_helpers/lang.coffee")).call(alias3,"L001398","Candidate verification photo",{"name":"lang","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":80}}}))
    + "\">\n<div class=\"verification-photo-status\">\n    <b>"
    + alias2(__default(require("../../handlebars_helpers/lang.coffee")).call(alias3,"L001213","Photo Status:",{"name":"lang","hash":{},"data":data,"loc":{"start":{"line":4,"column":7},"end":{"line":4,"column":41}}}))
    + "</b> "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"status") : depth0), depth0))
    + "\n</div>\n<button type=\"button\" class=\"btn btn-success col-sm-6 accept-photo-btn\">"
    + alias2(__default(require("../../handlebars_helpers/lang.coffee")).call(alias3,"L001214","Accept",{"name":"lang","hash":{},"data":data,"loc":{"start":{"line":6,"column":72},"end":{"line":6,"column":99}}}))
    + "</button>\n<button type=\"button\" class=\"btn btn-danger col-sm-6 reject-photo-btn\">"
    + alias2(__default(require("../../handlebars_helpers/lang.coffee")).call(alias3,"L001215","Reject",{"name":"lang","hash":{},"data":data,"loc":{"start":{"line":7,"column":71},"end":{"line":7,"column":98}}}))
    + "</button>\n<span class=\"verification-photo-error\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"error_message") : depth0), depth0))
    + "</span>\n";
},"useData":true});