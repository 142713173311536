# This is a place for registering event handlers and running functions that
# are used on Genshi pages (that just happen to load NextGen) today, but
# that will also need to be readily available on actual dynamic pages in
# the future.

register = () ->
    $('body').on('submit', '.external-score-report-form', () ->
        buttons = $('.external-score-report-btn')

        buttons.prop 'disabled', true
        buttons.css 'cursor', 'wait'

        callback = () ->
            buttons.prop 'disabled', false
            buttons.css 'cursor', ''
        setTimeout callback, 10000
    )

    $('.external-score-report-btn').prop 'disabled', false


    $('body').on('click', '.show-credential-type-modal', () ->
        type_uuid = $(this).data('uuid')
        if not type_uuid
            console.warn 'Cannot open modal: No credential type uuid specified in data.'
            return
        NextGen.credentials.show_single_credential_card(type_uuid)
    )

    # Further handlers can be installed here. 
    # ...


module.exports = { register: register }
