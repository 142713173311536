var Handlebars = require("./node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal fade "
    + alias4(((helper = (helper = lookupProperty(helpers,"modal_class") || (depth0 != null ? lookupProperty(depth0,"modal_class") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"modal_class","hash":{},"data":data,"loc":{"start":{"line":1,"column":23},"end":{"line":1,"column":38}}}) : helper)))
    + "\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"modal_id") || (depth0 != null ? lookupProperty(depth0,"modal_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"modal_id","hash":{},"data":data,"loc":{"start":{"line":1,"column":44},"end":{"line":1,"column":56}}}) : helper)))
    + "\" tabindex=\"-1\" aria-labelledby=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"modal_id") || (depth0 != null ? lookupProperty(depth0,"modal_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"modal_id","hash":{},"data":data,"loc":{"start":{"line":1,"column":89},"end":{"line":1,"column":101}}}) : helper)))
    + "-label\">\n    <div class=\"modal-dialog "
    + alias4(((helper = (helper = lookupProperty(helpers,"modal_dialog_class") || (depth0 != null ? lookupProperty(depth0,"modal_dialog_class") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"modal_dialog_class","hash":{},"data":data,"loc":{"start":{"line":2,"column":29},"end":{"line":2,"column":51}}}) : helper)))
    + "\" role=\"document\">\n        <div class=\"modal-content\">\n            <div class=\"modal-header\">\n                <h5 class=\"modal-title\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"modal_id") || (depth0 != null ? lookupProperty(depth0,"modal_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"modal_id","hash":{},"data":data,"loc":{"start":{"line":5,"column":44},"end":{"line":5,"column":56}}}) : helper)))
    + "-label\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":5,"column":64},"end":{"line":5,"column":75}}}) : helper))) != null ? stack1 : "")
    + "</h5>\n                <button type=\"button\" class=\"btn-close\" data-bs-dismiss=\"modal\" aria-label=\"Close\"></button>\n            </div>\n            <div class=\"modal-body\">\n                <p>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"content") || (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"content","hash":{},"data":data,"loc":{"start":{"line":9,"column":19},"end":{"line":9,"column":32}}}) : helper))) != null ? stack1 : "")
    + "</p>\n            </div>\n            <div class=\"modal-footer\">\n                <button type=\"button\" class=\"btn modal-confirm "
    + alias4(((helper = (helper = lookupProperty(helpers,"confirm_btn_class") || (depth0 != null ? lookupProperty(depth0,"confirm_btn_class") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"confirm_btn_class","hash":{},"data":data,"loc":{"start":{"line":12,"column":63},"end":{"line":12,"column":84}}}) : helper)))
    + "\"\n                        data-bs-dismiss=\"modal\" "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"confirm_custom_attribute") || (depth0 != null ? lookupProperty(depth0,"confirm_custom_attribute") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"confirm_custom_attribute","hash":{},"data":data,"loc":{"start":{"line":13,"column":48},"end":{"line":13,"column":78}}}) : helper))) != null ? stack1 : "")
    + ">\n                    "
    + alias4(((helper = (helper = lookupProperty(helpers,"confirm_btn_label") || (depth0 != null ? lookupProperty(depth0,"confirm_btn_label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"confirm_btn_label","hash":{},"data":data,"loc":{"start":{"line":14,"column":20},"end":{"line":14,"column":41}}}) : helper)))
    + "\n                </button>\n                <button type=\"button\" class=\"btn modal-cancel "
    + alias4(((helper = (helper = lookupProperty(helpers,"dismiss_btn_class") || (depth0 != null ? lookupProperty(depth0,"dismiss_btn_class") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"dismiss_btn_class","hash":{},"data":data,"loc":{"start":{"line":16,"column":62},"end":{"line":16,"column":83}}}) : helper)))
    + "\"\n                        data-bs-dismiss=\"modal\">\n                    "
    + alias4(((helper = (helper = lookupProperty(helpers,"dismiss_btn_label") || (depth0 != null ? lookupProperty(depth0,"dismiss_btn_label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"dismiss_btn_label","hash":{},"data":data,"loc":{"start":{"line":18,"column":20},"end":{"line":18,"column":41}}}) : helper)))
    + "\n                </button>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});