require "./ace.css"

_update_editor_fields = ->
    new Promise((resolve) ->
        try
            dom = ace.require('ace/lib/dom')
            $('textarea[data-editor]:visible').not('.ace-enabled').each ->
                textarea = $(this)
                textarea.addClass 'ace-enabled'
                editor_div = $('<div>',
                    position: 'absolute'
                    width: '100%'
                    height: textarea.height()
                    id: 'ace-' + textarea.attr('name')
                    'class': textarea.attr('class')).insertBefore(textarea)
                textarea.css 'display', 'none'
                editor = ace.edit(editor_div[0])
                editor.getSession().setValue textarea.val()
                editor.setOptions
                    highlightGutterLine: true
                    hightlightActiveLine: true
                    scrollPastEnd: 1
                    enableBasicAutocompletion: true
                    enableLiveAutocompletion: true
                    wrap: 'free'
                syntax = textarea.data('editor') or 'yaml'
                editor.getSession().setMode 'ace/mode/' + syntax
                editor.setTheme 'ace/theme/chrome'
                editor.commands.addCommand
                    name: 'Toggle Fullscreen'
                    bindKey: 'F11'
                    exec: (editor) ->
                        fullScreen = dom.toggleCssClass(document.body, 'fullScreen')
                        dom.setCssClass editor.container, 'fullScreen', fullScreen
                        modal = editor.container.closest('.modal-dialog')
                        if modal
                            dom.toggleCssClass modal, 'fullScreen'
                        editor.setAutoScrollEditorIntoView !fullScreen
                        editor.resize()
                        return
                editor.getSession().on 'change', ->
                    textarea.val(editor.getSession().getValue()).change()
                    return
                return
            resolve true
        catch _error
            error = _error
            console.log 'No ace editor loaded, continuing with plain text areas'
            resolve false
        return
    )

enable_ace = () ->
    #
    # Replace text areas with 'data-editor' attribute with Ace Editor instances
    #
    # Retrieves ace scripts if not yet loaded and enables the editor
    # for all visible "data-editor" text areas on the page
    #
    #

    if typeof ace == 'undefined'
        $.ajax(
            url: 'https://cdnjs.cloudflare.com/ajax/libs/ace/1.4.6/ace.js'
            dataType: 'script'
            cache: true).then ->
            $.ajax(
                url: 'https://cdnjs.cloudflare.com/ajax/libs/ace/1.4.6/ext-language_tools.js'
                dataType: 'script'
                cache: true).then ->
                    _update_editor_fields()
    else
        _update_editor_fields()


module.exports =
    enable_ace: enable_ace