handlebars = require "handlebars/dist/handlebars"

require "orb/handlebars_helpers"
templates = require "orb/templates"
render_actions = require("../actions/actions").render

photo_verification_partial = require "orb/components/photo_verification/photo_verification_container.mustache"
photo_verification = require "orb/components/photo_verification/photo_verification"
render_photo_verification_widget = photo_verification.render_photo_verification_widget

handlebars.registerPartial "photo_verification", photo_verification_partial

# See `orb/README.rst` for a description of the `options` available when
# rendering review packets

_do_section_render = (review_packet, template_name="overview",
                      target_selector="#rp-overview > .rp-section-container",
                      render_callback_name="overview_renderer") ->

    options = review_packet.get("options")
    review_packet_type = review_packet.get("type") or "default"

    templates.fetch(review_packet_type, template_name).done (template) ->
        template = handlebars.compile(template)

        $target = $(target_selector)

        if options[render_callback_name]
            options[render_callback_name] review_packet, template, $target
        else
            $target.html(
                template review_packet.toJSON()
            )


render_overview = (review_packet) ->
    _do_section_render(review_packet, "overview",
                       "#rp-overview > .rp-section-container",
                       "overview_renderer")
        .done ->
            render_photo_verification_widget review_packet


render_details = (review_packet) ->
    _do_section_render(review_packet, "details",
                       "#rp-artifacts > .rp-section-container",
                       "details_renderer")

available_to_block_helper = ->
    ### Handlebars block helper for reserving content to users with certain principals

    Expects to receive any number of required principals, for example:

    {{#available_to "group:admin" "group:staff" "permission:applicant_view"}}
        < content reserved for admin, staff or those who can view applicants >
    {{/available_to}}

    The block's content will render if at least one of the given required principals
    matches an item in the review packet's principals.

    Handlebars block helpers always receive an options object as the last
    argument.

    `this` refers to the review packet's data.

    ###

    options = arguments[arguments.length - 1]
    effective_principals = this.meta.principals or []
    required_principals = Array.prototype.slice.call arguments, 0, -1

    has_at_least_one_required_principal = false
    for required_principal in required_principals
        if required_principal in effective_principals
            has_at_least_one_required_principal = true
            break

    if has_at_least_one_required_principal
        return options.fn(this)
    else
        return options.inverse()

handlebars.registerHelper "available_to", available_to_block_helper

module.exports =
    render_actions: render_actions
    render_overview: render_overview
    render_details: render_details
